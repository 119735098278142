import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import FilledInput from '@mui/material/FilledInput';
import type { Block } from '../../../common/interfaces';
import type { BlockIdSelection } from '../containers/BlockSelectors/DriverBlockSelector';

interface BlockSelectorProps {
    blocks?: Block[];
    blockId: BlockIdSelection;
    onChange: (value: BlockIdSelection) => void;
    inputLabel: React.ReactNode;
}

const BlockSelector: React.FunctionComponent<BlockSelectorProps> = (props) => {
    const { blocks, blockId, onChange, inputLabel, children } = props;

    const handleChange = (event: SelectChangeEvent<BlockIdSelection>) => {
        onChange(event.target.value as BlockIdSelection);
    };

    const sortedBlocks = blocks ? [...blocks].sort((a, b) => parseInt(a.blockId, 10) - parseInt(b.blockId, 10)) : [];

    return (
        <FormControl variant="filled">
            <InputLabel>{inputLabel}</InputLabel>
            <Select variant="filled" input={<FilledInput />} value={blockId} onChange={handleChange}>
                {children}
                {sortedBlocks.map(block => (
                    <MenuItem key={block.blockKey} value={block.blockId}>{block.blockId}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default BlockSelector;
