import type { QueryResult } from '@apollo/client';
import Refresher from '@realcity/web-frame/lib/components/Refresher';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

interface Props<TData, TVariables> {
    interval?: number;
    queryResult: QueryResult<TData, TVariables>;
}

export const REFRESHABLE_QUERY_OPTIONS = {
    notifyOnNetworkStatusChange: true,
};

// TODO: the date may be incorrect as the data could be coming from the cache for example
// TODO: there's a bug if refetch fails
//       see https://github.com/apollographql/react-apollo/issues/3571
class GraphqlRefresher<TData, TVariables> extends React.PureComponent<Props<TData, TVariables>> {
    private loaded?: Date;

    UNSAFE_componentWillReceiveProps({ queryResult: newQueryResult }: Props<TData, TVariables>) {
        const { queryResult } = this.props;
        if (newQueryResult.data !== queryResult.data && !isEmpty(newQueryResult.data)) {
            this.loaded = new Date();
        }
    }

    refetch = () => {
        // The refetch method on the component may receive additional arguments,
        // but queryResult.refetch() should only be called without any arguments,
        // that's why we need this method.
        const { queryResult } = this.props;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryResult.refetch();
    };

    render() {
        const { interval, queryResult } = this.props;

        return (
            <Refresher
                interval={interval}
                refresh={this.refetch}
                error={!!queryResult.error}
                loading={queryResult.loading}
                lastRefresh={this.loaded}
            />
        );
    }
}

export default GraphqlRefresher;
