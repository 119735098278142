import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    root: {
        margin: '16px 0',
    },
});

const MapCardSectionHeader: React.FC = ({ children }) => {
    const classes = useStyles();

    return (
        <Typography gutterBottom variant="h5" component="h2" className={classes.root}>
            {children}
        </Typography>
    );
};

export default MapCardSectionHeader;
