import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import React from 'react';
import type { BlockActivity, ScheduleEntry, TripKey } from '../../../../common/interfaces';
import { GET_SCHEDULE } from '../../graphql/rest';
import TripStopSkippingModificationDialogContent from './TripStopSkippingModificationDialogContent';

interface Props {
    blockActivity: BlockActivity;
    tripKey: TripKey;
    activityModificationCompleted: () => void;
}

const TripStopSkippingModificationDialog: React.FunctionComponent<Props> = ({ activityModificationCompleted, blockActivity, tripKey }) => {
    const { data, loading, error } = useQuery<{ schedule?: ScheduleEntry[] }>(GET_SCHEDULE, {
        variables: {
            tripKey,
            blockActivityKey: blockActivity.activityKey,
        },
    });

    if (loading) {
        return (
            <div style={{ width: 500 }}>
                <CircularProgress />
            </div>
        );
    }

    if (error || !data || !data.schedule) {
        return <RequestErrorMessage />;
    }

    return (
        <TripStopSkippingModificationDialogContent
            blockActivity={blockActivity}
            tripKey={tripKey}
            schedule={data.schedule}
            activityModificationCompleted={activityModificationCompleted}
        />
    );
};

export default TripStopSkippingModificationDialog;
