import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ArrowForward from '@mui/icons-material/ArrowForward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import DropDownMenu from '@realcity/web-frame/lib/components/DropDownMenu';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { stringify } from 'query-string';
import type { ResolvedCoreRealtimeVehicle } from '../../../../common/interfaces';
import type { MessageUrlParams } from '../Messages/SendMessageForm';
import Delay from '../../components/Delay';
import RouteBox from '../../components/RouteBox';
import { Roles } from '../../constants';
import MapLink from '../../components/MapLink';

const ARROW_STYLE = {
    // TODO
    fontSize: 16,
    marginBottom: -4,
};

interface Props {
    vehicle: ResolvedCoreRealtimeVehicle;
    time: number;
    logoutDriverOnClick: (vehicleId: string) => void;
}

const FilteredVehicleRow: React.FC<Props> = ({ logoutDriverOnClick, vehicle, time }) => {
    const handleLogout = () => {
        logoutDriverOnClick(vehicle.vehicleId);
    };

    return (
        <TableRow key={vehicle.licensePlate}>
            <TableCell>{vehicle.licensePlate}</TableCell>
            <TableCell>{vehicle.vehicleBlockId}</TableCell>
            <TableCell>
                {vehicle.trip?.route && (
                    <>
                        <RouteBox route={vehicle.trip.route} />
                        <ArrowForward style={ARROW_STYLE} />
                        {vehicle.tripHeadsign}
                    </>
                )}
            </TableCell>
            <TableCell>{vehicle.stopName}</TableCell>
            <TableCell>
                <Delay delay={vehicle.delay} delayStatus={vehicle.delayStatus} />
            </TableCell>
            <TableCell>{vehicle.model}</TableCell>
            <TableCell>{vehicle.driverName}</TableCell>
            <TableCell>
                {vehicle.phoneNumber}
                {vehicle.deviceName && ` (${vehicle.deviceName})`}
            </TableCell>
            <TableCell>
                {vehicle.lastRefresh && (
                    <FormattedMessage id="vehicles.seconds-ago" values={{ seconds: (time - vehicle.lastRefresh) / 1000 }} />
                )}
            </TableCell>
            <TableCell>
                <div style={{ display: 'flex' }}>
                    <MapLink trip={vehicle.trip} vehicle={vehicle}>
                        <FormattedMessage id="vehicles.show-map" />
                    </MapLink>
                    <IfRole role={Roles.Write}>
                        <DropDownMenu buttonContent={<MoreVertIcon />}>
                            <MenuItem component={Link} {...{ to: getMessagesUrl([vehicle.vehicleId]) }}>
                                <FormattedMessage id="vehicles.send-message" />
                            </MenuItem>
                            {vehicle.driverName && (
                                <MenuItem onClick={handleLogout}>
                                    <FormattedMessage id="vehicles.logout-driver" />
                                </MenuItem>
                            )}
                        </DropDownMenu>
                    </IfRole>
                </div>
            </TableCell>
        </TableRow>
    );
};

function getMessagesUrl(recipient: string[]): string {
    const params: MessageUrlParams = { recipient };
    return `/messages?${stringify(params)}`;
}

export default FilteredVehicleRow;
