import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import React from 'react';

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
        marginLeft: -10,
    },
    placeholder: {
        width: 24,
    },
});

interface Props extends ButtonProps {
    loading: boolean;
}

const AsyncActionButton: React.FunctionComponent<Props> = ({ loading, disabled, children, ...rest }) => {
    const classes = useStyles();

    return (
        <Button disabled={disabled || loading} {...rest}>
            {loading ? (
                <CircularProgress size={24} className={classes.progress} />
            ) : (
                <div className={classes.placeholder} />
            )}
            {children}
            <div className={classes.placeholder} />
        </Button>
    );
};

export default AsyncActionButton;
