import Popover, { popoverClasses } from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

const RoundedPopover = styled(Popover)({
    [popoverClasses.paper]: {
        borderRadius: 10,
    },
});

export default RoundedPopover;
