import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import type { RecipientItemFragment } from '../../graphql/graphql-typings';

interface Props {
    recipient: RecipientItemFragment;
}

const FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

const RecipientTimeDetails: React.FunctionComponent<Props> = (props) => {
    const { recipient } = props;

    return (
        <Typography variant="body2">
            <FormattedMessage id="dispatcher-messages.messages.shown-time" />
            {': '}
            {recipient.shownTime ? <FormattedTime value={recipient.shownTime} {...FORMAT_OPTIONS} /> : '-'}
            <br />
            <FormattedMessage id="dispatcher-messages.messages.acknowledged-time" />
            {': '}
            {recipient.acknowledgeTime ? <FormattedTime value={recipient.acknowledgeTime} {...FORMAT_OPTIONS} /> : '-'}
        </Typography>
    );
};

export default RecipientTimeDetails;
