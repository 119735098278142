export type UrlParam = string[] | string | null;

export type UrlParams<T extends string> = {
    [P in T]: UrlParam;
};

export function asList(param: UrlParam): string[] {
    if (!param) {
        return [];
    }

    return typeof param === 'string' ? [param] : param;
}

export function asSingleValue(param: UrlParam): string | undefined {
    if (Array.isArray(param) || param === null) {
        return undefined;
    }

    return param;
}

export function asNumber(param: UrlParam): number | undefined {
    const valueNumber = parseInt(asSingleValue(param) ?? '', 10);

    if (Number.isNaN(valueNumber)) {
        return undefined;
    }

    return valueNumber;
}
