import { getConfig } from '@realcity/web-frame/lib/config';
import type { FitBounds } from 'react-mapbox-gl/lib/map';

interface AppConfig {
    MAP_URL: string;
    INTERNAL_MAP_ENABLED: string;
    MAP_STYLE: string;
    MAP_BOUNDS: string;
    DRT_ENABLED: string;
    MESSAGES_ENABLED: string;
    IS_LARGE_NETWORK: string;
    AUTHENTICATION_DISABLED: string;
}

export const APP_CONFIG = getConfig<AppConfig>();

// TODO: Flavor config
export const FLAVOR_CONFIG = {};

// Global config
const CONFIG: AppConfig = { ...APP_CONFIG, ...FLAVOR_CONFIG };

export default CONFIG;

export function isDrtEnabled() {
    return APP_CONFIG.DRT_ENABLED === 'true';
}

export function isInternalMapEnabled() {
    return APP_CONFIG.INTERNAL_MAP_ENABLED === 'true';
}

export function isMessagesEnabled() {
    return APP_CONFIG.MESSAGES_ENABLED === 'true';
}

export function isLargeNetwork() {
    return APP_CONFIG.IS_LARGE_NETWORK === 'true';
}

export function isAuthenticationDisabled() {
    return APP_CONFIG.AUTHENTICATION_DISABLED === 'true';
}

export function parseMapboxConfigBounds(): FitBounds {
    const coordinates = APP_CONFIG.MAP_BOUNDS.split(',');
    return [
        [+coordinates[0], +coordinates[1]],
        [+coordinates[2], +coordinates[3]],
    ];
}
