import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AutoRenew from '@mui/icons-material/Autorenew';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React, { useRef, useState } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import type { BlockActivity, IsoDateString, ResolvedCoreRealtimeVehicle, Trip, TripTimeDescriptor } from '../../../../common/interfaces';
import { PopupButton, PopupRow } from '../../components/BlockOverviewPopup';
import type { ClosablePopperProps } from '../../components/Closable';
import { ClosablePopper } from '../../components/Closable';
import OriginalAndModified from '../../components/OriginalAndModified';
import { Roles } from '../../constants';
import { isDrtEnabled } from '../../config';
import BlockActivityActions from '../../containers/BlockActivityActions';
import { getBeginTime, getDirectionText, getVehicleBlockId } from '../../utils';
import MapLink from '../../components/MapLink';

function beginOrEndTime(descriptor: TripTimeDescriptor | null | undefined, begin: boolean): IsoDateString | null {
    if (!descriptor) {
        return null;
    }

    return begin ? descriptor.firstStop.departureTime : descriptor.lastStop.arrivalTime;
}

function firstOrLastStop(descriptor: TripTimeDescriptor | null | undefined, first: boolean): string | null {
    if (!descriptor) {
        return null;
    }

    return first ? descriptor.firstStop.stop.name : descriptor.lastStop.stop.name;
}

function calculateDistance(descriptor: TripTimeDescriptor | null | undefined) {
    if (!descriptor) {
        return null;
    }
    return descriptor.lastStop.shapeDistTravelled - descriptor.firstStop.shapeDistTravelled;
}

const TripBeginEndTime: React.FunctionComponent<{ trip: Trip; begin: boolean }> = ({ trip, begin }) => {
    const originalTime = beginOrEndTime(trip.originalTimes, begin);
    const modifiedTime = beginOrEndTime(trip.modifiedTimes, begin);

    return <OriginalAndModified original={originalTime} modified={modifiedTime} component={FormattedTime} />;
};

const LabelComponent: React.FunctionComponent<{ value?: string }> = ({ value }) => <>{value}</>;

const OriginalAndModifiedStop: React.FunctionComponent<{ trip: Trip; first: boolean }> = ({ trip, first }) => {
    const originalStopName = firstOrLastStop(trip.originalTimes, first);
    const modifiedStopName = firstOrLastStop(trip.modifiedTimes, first);

    return <OriginalAndModified original={originalStopName} modified={modifiedStopName} component={LabelComponent} />;
};

const DistanceFormatter: React.FunctionComponent<{ value: number }> = ({ value }) => (
    <FormattedMessage id="blocks-overview.block-activity.distance-format" values={{ distance: value }} />
);

const OriginalAndModifiedDistance: React.FunctionComponent<{ trip: Trip }> = ({ trip }) => {
    const originalDistance = calculateDistance(trip.originalTimes);
    const modifiedDistance = calculateDistance(trip.modifiedTimes);

    return <OriginalAndModified original={originalDistance} modified={modifiedDistance} component={DistanceFormatter} />;
};

const PredictedTimeFormatter: React.FunctionComponent<{ time: IsoDateString }> = ({ time }) => (
    <>
        <FormattedTime value={time} />
        <AutoRenew className="popup__icon-predicted" />
    </>
);

interface Props extends Omit<ClosablePopperProps, 'children' | 'dialogTitle'> {
    activity: BlockActivity;
    vehicle?: ResolvedCoreRealtimeVehicle;
}

interface ContentProps {
    activity: BlockActivity;
    vehicle?: ResolvedCoreRealtimeVehicle;
    pathName: string;
    onClose: () => void;
}

const BlockActivityPopoverContent: React.FunctionComponent<ContentProps> = ({ activity, onClose, vehicle, pathName }) => {
    const { trip } = activity;

    const popupRef = useRef(null);

    const [actionsOpen, setActionsOpen] = useState(false);

    const actionPopupOpen = () => {
        setActionsOpen(true);
    };

    const actionPopupClose = () => {
        setActionsOpen(false);
    };

    const activityModificationCompleted = () => {
        actionPopupClose();
        onClose();
    };

    return (
        <>
            <DialogContent ref={popupRef}>
                <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.vehicle" />}>
                    {vehicle ? `${vehicle.licensePlate} ${vehicle.model}` : '−'}
                </PopupRow>
                <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.driver" />}>{vehicle?.driverName || '−'}</PopupRow>
                <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.block" />}>
                    <OriginalAndModified
                        original={activity.originalVehicleBlockId || '-'}
                        modified={activity.modifiedVehicleBlockId}
                        component={LabelComponent}
                    />
                    {' / '}
                    <OriginalAndModified
                        original={activity.originalDriverBlockId || '-'}
                        modified={activity.modifiedDriverBlockId}
                        component={LabelComponent}
                    />
                </PopupRow>
                {trip ? (
                    <>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.trip" />}>{pathName}</PopupRow>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.distance" />}>
                            <OriginalAndModifiedDistance trip={trip} />
                        </PopupRow>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.trip-begin" />}>
                            <OriginalAndModifiedStop trip={trip} first />
                            <br />
                            <TripBeginEndTime trip={trip} begin />
                            <br />
                            {trip.predictedTimes && <PredictedTimeFormatter time={trip.predictedTimes.firstStop.departureTime} />}
                        </PopupRow>

                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.trip-end" />}>
                            <OriginalAndModifiedStop trip={trip} first={false} />
                            <br />
                            <TripBeginEndTime trip={trip} begin={false} />
                            <br />
                            {trip.predictedTimes && <PredictedTimeFormatter time={trip.predictedTimes.lastStop.arrivalTime} />}
                        </PopupRow>
                    </>
                ) : (
                    <>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.non-driving-activity" />}>
                            {pathName}
                        </PopupRow>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.non-driving-activity-begin" />}>
                            <OriginalAndModified
                                original={activity.originalBeginTime}
                                modified={activity.modifiedBeginTime}
                                component={FormattedTime}
                            />
                        </PopupRow>
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.non-driving-activity-end" />}>
                            <OriginalAndModified
                                original={activity.originalEndTime}
                                modified={activity.modifiedEndTime}
                                component={FormattedTime}
                            />
                        </PopupRow>
                    </>
                )}

                {activity.added && (
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.added" />}>
                        <FormattedMessage id="blocks-overview.block-activity.added-yes" />
                    </PopupRow>
                )}

                {!activity.valid && (
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.validity" />}>
                        <FormattedMessage id="blocks-overview.block-activity.validity-no" />
                    </PopupRow>
                )}

                {activity.modified && (
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.modification" />}>
                        <FormattedTime value={activity.modified} />
                    </PopupRow>
                )}
            </DialogContent>
            <DialogActions>
                {trip && (
                    <>
                        {isDrtEnabled() && (
                            <Button component={Link} to={`/bookings?tripKey=${trip.tripKey}`} color="primary">
                                <FormattedMessage id="blocks-overview.block-activity.bookings" />
                            </Button>
                        )}
                        <MapLink trip={trip}>
                            <FormattedMessage id="blocks-overview.block-activity.map" />
                        </MapLink>
                    </>
                )}
                <IfRole role={Roles.Write}>
                    <PopupButton align="right" onClick={actionPopupOpen} color="primary">
                        <FormattedMessage id="blocks-overview.block-activity-actions.title" />
                    </PopupButton>
                </IfRole>
            </DialogActions>
            <BlockActivityActions
                open={actionsOpen}
                anchorEl={popupRef.current}
                onClose={actionPopupClose}
                activityModificationCompleted={activityModificationCompleted}
                blockActivity={activity}
                vehicle={vehicle}
            />
        </>
    );
};

const BlockActivityPopover: React.FunctionComponent<Props> = ({ activity, onClose, vehicle, ...rest }) => {
    const { trip } = activity;
    const beginTime = getBeginTime(activity);
    const pathName = trip ? trip.route.shortName + getDirectionText(trip.directionId) : activity.activityType.name;

    const dialogTitle = (
        <>
            <FormattedMessage id="block.id" values={{ blockId: getVehicleBlockId(activity) }} />
            {' / '}
            {pathName}
            {' '}
            <FormattedTime value={beginTime} />
        </>
    );

    return (
        <ClosablePopper dialogTitle={dialogTitle} placement="right" onClose={onClose} {...rest}>
            <BlockActivityPopoverContent activity={activity} vehicle={vehicle} pathName={pathName} onClose={onClose} />
        </ClosablePopper>
    );
};

export default BlockActivityPopover;
