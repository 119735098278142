import type { ReactElement } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { closeActivityModificationNotification as closeActivityModificationNotificationAction } from '../../redux/actions';
import type { CustomSnackbarProps } from '../../components/CustomSnackbar';
import CustomSnackbar from '../../components/CustomSnackbar';
import type { ActivityModificationNotification, ActivityModificationNotificationType } from '../../redux/state';

interface Props {
    notification: ActivityModificationNotification;
    closeActivityModificationNotification: typeof closeActivityModificationNotificationAction;
}

const ActivityModificationNotificationSnackbar: React.FunctionComponent<Props> = (props) => {
    const { notification, closeActivityModificationNotification } = props;

    const closeNotification = () => {
        closeActivityModificationNotification(notification.id);
    };

    const details = getSnackBarDetails(notification.type);

    if (details === null) {
        return null;
    }

    const [variant, content] = details;

    return (
        <CustomSnackbar variant={variant} open onClose={closeNotification} autoHideDuration={5000}>
            {content}
        </CustomSnackbar>
    );
};

function getSnackBarDetails(type: ActivityModificationNotificationType): [CustomSnackbarProps['variant'], ReactElement] | null {
    switch (type) {
        case 'success':
            return ['success', <FormattedMessage id="blocks-overview.operation-state.success" />];
        case 'lock-error':
            return ['warning', <FormattedMessage id="blocks-overview.operation-state.lock-error" />];
        case 'other-error':
            return ['error', <FormattedMessage id="blocks-overview.operation-state.other-error" />];
        default:
            return null;
    }
}

export default connect(null, { closeActivityModificationNotification: closeActivityModificationNotificationAction })(
    ActivityModificationNotificationSnackbar,
);
