import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import TripLayer from './TripLayer';
import TripDetailsTable from './TripDetailsTable';
import TripStopsTable from './TripStopsTable';
import type { DisplayedTripFragment } from '../../graphql/graphql-typings';
import MapCardSectionHeader from '../MapCardSectionHeader';

const useStyles = makeStyles({
    tripStopsTableWrapper: {
        overflow: 'auto',
    },
});

interface Props {
    trip: DisplayedTripFragment;
}

const TripDetailsContent: React.FunctionComponent<Props> = ({ trip }) => {
    const classes = useStyles();

    return (
        <>
            <MapCardSectionHeader>
                <FormattedMessage id="trip-details-content.title" />
            </MapCardSectionHeader>

            <TripDetailsTable trip={trip} />

            <MapCardSectionHeader>
                <FormattedMessage id="trip-details-content.stops" />
            </MapCardSectionHeader>

            <div className={classes.tripStopsTableWrapper}>
                <TripStopsTable stops={trip.actualSchedule} />
            </div>

            <TripLayer trip={trip} />
        </>
    );
};

export default TripDetailsContent;
