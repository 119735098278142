import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import type { PopoverProps } from '@mui/material/Popover';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactNode } from 'react';
import React from 'react';
import RoundedPopover from '../RoundedPopover';
import type { Props as RoundedPopperProps } from '../RoundedPopper';
import RoundedPopper from '../RoundedPopper';

const useStyles = makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 16,
    },
    titleText: {
        fontSize: 22,
        fontWeight: 600,
    },
    button: {
        margin: -4,
    },
});

type Props<T> = T & {
    component: React.ComponentType<T>;
    dialogTitle: ReactNode;
    titleProps?: TypographyProps;
    onClose: () => void;
    open: boolean;
    children?: ReactNode;
};

const Closable = <T extends {}>({ dialogTitle, onClose, children, component: Component, titleProps, ...rest }: Props<T>) => {
    const classes = useStyles();

    return (
        <Component onClose={onClose} {...(rest as any)}>
            <DialogTitle className={classes.title}>
                <Typography variant="inherit" {...titleProps} className={classes.titleText}>
                    {dialogTitle}
                </Typography>
                <IconButton onClick={onClose} className={classes.button} size="large">
                    <Close />
                </IconButton>
            </DialogTitle>
            {children}
        </Component>
    );
};

export type ClosableDialogProps = Omit<Props<DialogProps>, 'component'>;

export const ClosableDialog: React.FunctionComponent<ClosableDialogProps> = ({ children, ...rest }) => (
    <Closable component={Dialog} {...(rest as any)}>
        {children}
    </Closable>
);

export type ClosablePopoverProps = Omit<Props<PopoverProps>, 'component'>;

export const ClosablePopover: React.FunctionComponent<ClosablePopoverProps> = ({ children, ...rest }) => (
    <Closable component={RoundedPopover} {...(rest as any)}>
        {children}
    </Closable>
);

export type ClosablePopperProps = Omit<Props<RoundedPopperProps>, 'component'>;

export const ClosablePopper: React.FunctionComponent<ClosablePopperProps> = ({ children, ...rest }) => (
    <Closable component={RoundedPopper} {...(rest as any)}>
        {children}
    </Closable>
);
