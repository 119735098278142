import React from 'react';
import type { BlockActivityAction } from '.';
import { PopupButton } from '../../components/BlockOverviewPopup';

export default class BlockActivityActionButton extends React.PureComponent<{
    blockActivityAction: BlockActivityAction;
    onClick: (blockActivityAction: BlockActivityAction) => void;
    label: React.ReactNode;
}> {
    private readonly onClick = () => {
        const { blockActivityAction, onClick } = this.props;
        onClick(blockActivityAction);
    };

    render() {
        const { label } = this.props;
        return (
            <PopupButton align="right" onClick={this.onClick} color="primary">
                {label}
            </PopupButton>
        );
    }
}
