import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { BlockActivity, ScheduleEntry, StopSkippingTripModification, TripKey } from '../../../../common/interfaces';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import StopSelector from '../../components/StopSelector';
import SaveButton from './SaveButton';
import { SHORTEN_TRIP } from '../../graphql/rest';

interface Props {
    blockActivity: BlockActivity;
    tripKey: TripKey;
    schedule: ScheduleEntry[];
    activityModificationCompleted: () => void;
}

const TripStopSkippingModificationDialogContent: React.FunctionComponent<Props> = (props) => {
    const { activityModificationCompleted, blockActivity, tripKey, schedule } = props;

    const notSkipped = (s: ScheduleEntry) => !s.skipped;
    const [firstEntry, setFirstEntry] = useState(schedule.find(notSkipped)!);
    const [lastEntry, setLastEntry] = useState(schedule.slice().reverse().find(notSkipped)!);
    const isValid = firstEntry.stopSequence <= lastEntry.stopSequence;

    const input: StopSkippingTripModification = {
        type: 'skip-stops',
        stopTimes: schedule.map(({ stopId, stopSequence }) => ({
            stopId,
            stopSequence,
            skipped: stopSequence < firstEntry.stopSequence || stopSequence > lastEntry.stopSequence,
        })),
    };
    const hookOptions = { variables: { tripKey, input } };

    return (
        <>
            <DialogContent>
                <StopSelector
                    label={<FormattedMessage id="blocks-overview.stop-skipping.first-stop" />}
                    schedule={schedule}
                    selected={firstEntry}
                    onChange={setFirstEntry}
                />
                <StopSelector
                    label={<FormattedMessage id="blocks-overview.stop-skipping.last-stop" />}
                    schedule={schedule}
                    selected={lastEntry}
                    onChange={setLastEntry}
                />
            </DialogContent>
            <ContainedDialogActions>
                <SaveButton
                    blockActivity={blockActivity}
                    disabled={!isValid}
                    mutationOptions={hookOptions}
                    graphQlQuery={SHORTEN_TRIP}
                    activityModificationCompleted={activityModificationCompleted}
                />
            </ContainedDialogActions>
        </>
    );
};
export default TripStopSkippingModificationDialogContent;
