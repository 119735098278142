import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import React from 'react';
import FormatArrivalAndDeparture from './FormatArrivalAndDeparture';
import type { DisplayedStopFragment } from '../../graphql/graphql-typings';

interface Props {
    stops: DisplayedStopFragment[];
}

const TripStopsTable: React.FC<Props> = ({ stops }) => (
    <Table size="small">
        <TableBody>
            {stops.map(stopTime => (
                <TableRow key={stopTime.stopSequence}>
                    <TableCell>
                        <FormatArrivalAndDeparture arrival={stopTime.arrivalTime} departure={stopTime.departureTime} />
                    </TableCell>
                    <TableCell>{stopTime.stop.name}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default TripStopsTable;
