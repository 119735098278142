import * as React from 'react';
import RouteBoxRouteSelector from './RouteBoxRouteSelector';

interface Props {
    onChange: (routeIds: string[]) => void;
    selectedRouteIds?: string[];
}

export default class StatefulRouteSelector extends React.PureComponent<Props> {
    private readonly onRouteSelect = (routeId: string) => {
        const { selectedRouteIds, onChange } = this.props;

        const newData = [...selectedRouteIds || []];

        const selectedRouteIndex = newData.indexOf(routeId);
        if (selectedRouteIndex !== -1) {
            newData.splice(selectedRouteIndex, 1);
        } else {
            newData.push(routeId);
        }
        onChange(newData);
    };

    render() {
        const { selectedRouteIds } = this.props;

        return <RouteBoxRouteSelector onSelect={this.onRouteSelect} selectedRouteIds={selectedRouteIds} />;
    }
}
