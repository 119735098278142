import type GeoJSON from 'geojson';
import { LngLatBounds } from 'mapbox-gl';
import type { FitBounds } from 'react-mapbox-gl/lib/map';

export function lineString(coordinates: GeoJSON.Position[]): GeoJSON.LineString {
    return {
        type: 'LineString',
        coordinates,
    };
}

export function multiPoint(coordinates: GeoJSON.Position[]): GeoJSON.MultiPoint {
    return {
        type: 'MultiPoint',
        coordinates,
    };
}

export function point(lon: number, lat: number): GeoJSON.Point {
    return {
        type: 'Point',
        coordinates: [lon, lat],
    };
}

export function getBounds(coordinates: GeoJSON.Position[]): FitBounds {
    const bounds = new LngLatBounds();
    for (const position of coordinates) {
        bounds.extend(position as [number, number]);
    }
    return bounds.toArray() as [[number, number], [number, number]];
}

export function feature<T extends GeoJSON.Geometry>(geometry: T, properties: object): GeoJSON.Feature<T> {
    return {
        type: 'Feature',
        geometry,
        properties,
    };
}

export function featureCollection<T extends GeoJSON.Geometry>(features: GeoJSON.Feature<T>[]): GeoJSON.FeatureCollection<T> {
    return {
        type: 'FeatureCollection',
        features,
    };
}
