import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import * as React from 'react';

interface Props<T> {
    label: React.ReactNode;
    value: T | null;
    onChange: (value: T | null) => void;
    values?: T[];
}

const NO_VALUE = {};

class SelectWithEmptyValue<T> extends React.PureComponent<Props<T>> {
    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props<T>>) {
        const { value, values, onChange } = nextProps;

        if (value === null && values && values.length) {
            onChange(values[0]);
        }
    }

    private readonly onChange = (event: SelectChangeEvent<T | {}>) => {
        const { value } = event.target;
        const { onChange } = this.props;

        onChange(value === NO_VALUE ? null : (value as T));
    };

    render() {
        const { value, label, values, children } = this.props;

        const noValue = !values || values.length === 0;
        return (
            <FormControl variant="filled">
                <InputLabel>{label}</InputLabel>
                <Select
                    variant="filled"
                    value={value === null ? NO_VALUE : value}
                    onChange={this.onChange}
                    disabled={noValue}
                    input={<FilledInput />}
                >
                    {noValue && <MenuItem value={NO_VALUE as any} />}
                    {children}
                </Select>
            </FormControl>
        );
    }
}

export default SelectWithEmptyValue;
