import React, { useState } from 'react';
import { GeoJSONLayer, Layer } from 'react-mapbox-gl';
import type { SymbolLayout } from 'mapbox-gl';
import type { ImageDefinitionWithOptions } from 'react-mapbox-gl/lib/layer';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { image } from '../../utils/map';
import busSrc from '../../images/map/bus.png';
import { feature, featureCollection } from '../../utils/geojson';
import VehiclePopup from './VehiclePopup';
import { getVehiclesWithPosition } from './VehicleListPanel';
import type { DisplayedVehicleFragment, OnlineVehicleFragment } from '../../graphql/graphql-typings';
import { useOnlineVehiclesPositionQueryQuery } from '../../graphql/graphql-typings';

const VEHICLE_LAYOUT: SymbolLayout = {
    'icon-image': 'vehicle',
    'icon-allow-overlap': true,
    'icon-size': 0.5,
    'icon-pitch-alignment': 'map',
};

const IMAGES: ImageDefinitionWithOptions[] = [['vehicle', image(busSrc), { pixelRatio: 2 }]];

const AllVehiclesLayer: React.FunctionComponent<RouteComponentProps> = ({ location, history }) => {
    const [vehicleForPopup, setVehicleForPopup] = useState<OnlineVehicleFragment>();

    const queryResult = useOnlineVehiclesPositionQueryQuery({
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    const onVehicleClick = (event: any) => {
        const { vehicleId } = event.features[0].properties;
        history.push({ pathname: location.pathname, search: `?vehicle-id=${vehicleId as string}` });
    };

    const onVehicleHoverEnter = (event: any) => {
        const { vehicleId } = event.features[0].properties;

        if (queryResult.data?.vehiclesOnline) {
            const vehicle = queryResult.data.vehiclesOnline.find(v => v.id === vehicleId);
            setVehicleForPopup(vehicle);
        }
    };

    const hideVehiclePopup = () => {
        if (vehicleForPopup) {
            setVehicleForPopup(undefined);
        }
    };

    const displayedVehicles = queryResult.data?.vehiclesOnline && getVehiclesWithPosition(queryResult.data.vehiclesOnline);

    return (
        <>
            {displayedVehicles && (
                <>
                    <Layer images={IMAGES} />
                    <GeoJSONLayer
                        data={featureCollection(displayedVehicles.map(vehicleFeature))}
                        type="symbol"
                        symbolLayout={VEHICLE_LAYOUT}
                        symbolOnClick={onVehicleClick}
                        symbolOnMouseEnter={onVehicleHoverEnter}
                        symbolOnMouseLeave={hideVehiclePopup}
                    />
                    {vehicleForPopup && <VehiclePopup vehicle={vehicleForPopup} onMouseLeave={hideVehiclePopup} />}
                </>
            )}
        </>
    );
};

function vehicleFeature(vehicle: DisplayedVehicleFragment): GeoJSON.Feature {
    return feature(vehicle.position!, { vehicleId: vehicle.id });
}

export default withRouter(AllVehiclesLayer);
