import type { UrlParams } from '../utils/query-params-util';
import { asNumber } from '../utils/query-params-util';
import useQueryParamWriter from './useQueryParamWriter';
import useQueryParamReader from './useQueryParamReader';

type PaginationQueryParams = 'limit' | 'offset';

const usePagination = (defaultOffset: number, defaultLimit: number, limitOptions: number[]) => {
    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    const getQueryParams = () => {
        const { offset: offsetParam, limit: limitParam } = parsePaginationQueryParams(readQueryParam<PaginationQueryParams>());

        const offset = offsetParam ?? defaultOffset;

        let limit = defaultLimit;

        if (limitParam && limitOptions.includes(limitParam)) {
            limit = limitParam;
        } else {
            writeQueryParams({ limit: defaultLimit.toString() });
        }

        return { offset, limit };
    };

    const { offset, limit } = getQueryParams();

    const handleChangeRowsPerPage = (newValue: number) => {
        writeQueryParams({ limit: newValue.toString(), offset: '0' });
    };

    const handlePageChange = (newValue: number) => {
        writeQueryParams({ offset: (newValue * limit).toString() });
    };

    return {
        offset,
        handlePageChange,
        limit,
        handleChangeRowsPerPage,
        page: offset / limit,
    };
};

function parsePaginationQueryParams(urlParams: UrlParams<PaginationQueryParams>) {
    return {
        offset: asNumber(urlParams.offset),
        limit: asNumber(urlParams.limit),
    };
}

export default usePagination;
