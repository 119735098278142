import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { RoutesForCreateActivityQuery } from '../../graphql/graphql-typings';
import { useRoutesForCreateActivityQuery } from '../../graphql/graphql-typings';
import SelectWithEmptyValue from './SelectWithEmptyValue';

export type RouteSelectorRoute = RoutesForCreateActivityQuery['routes'][0];

interface Props {
    route: RouteSelectorRoute | null;
    onChange: (route: RouteSelectorRoute | null) => void;
}

const RouteSelector: React.FunctionComponent<Props> = ({ route, onChange }) => {
    const { data } = useRoutesForCreateActivityQuery();

    return (
        <SelectWithEmptyValue
            value={route}
            onChange={onChange}
            values={data?.routes}
            label={<FormattedMessage id="create-activity-dialog.route" />}
        >
            {data?.routes.map(r => (
                <MenuItem key={r.id} value={r as any}>
                    {r.shortName}
                </MenuItem>
            ))}
        </SelectWithEmptyValue>
    );
};

export default RouteSelector;
