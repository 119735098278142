import * as React from 'react';
import { connect } from 'react-redux';
import type { ResolvedCoreRealtimeVehicle } from '../../../../common/interfaces';
import RouteBoxRouteSelector from '../../containers/RouteSelector/RouteBoxRouteSelector';
import type { AppState } from '../../redux/state';

export interface Props {
    onVehiclesSelected: (vehicles: ResolvedCoreRealtimeVehicle[]) => void;
}

interface InternalProps extends Props {
    vehicles: ResolvedCoreRealtimeVehicle[];
    vehiclesLoaded: boolean;
}

class RouteVehiclesSelector extends React.PureComponent<InternalProps> {
    private readonly onRouteSelected = (routeId: string) => {
        const { vehicles, onVehiclesSelected } = this.props;

        const selectedVehicles = vehicles.filter(v => v.routeId === routeId);

        onVehiclesSelected(selectedVehicles);
    };

    render() {
        const { vehiclesLoaded } = this.props;

        return vehiclesLoaded ? <RouteBoxRouteSelector onSelect={this.onRouteSelected} /> : null;
    }
}

export default connect(({ vehicles }: AppState) => ({ vehicles: vehicles.list.vehicles, vehiclesLoaded: !!vehicles.list.time }))(
    RouteVehiclesSelector,
);
