import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import ReactQuill from 'react-quill';
import { useController, useFormContext } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles/createTheme';
import classNames from 'classnames';
import type { TransitAlertFormValue } from './TransitAlertFormValue';

const QUILL_MODULES = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: ['', 'center', 'right', 'justify'] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& .ql-container': {
            height: 'initial',
            flex: 1,
        },
        '& .ql-editor': { minHeight: 300 },
    },
    descriptionError: {
        border: '1px solid',
        borderColor: theme.palette.error.main,
    },
}));

interface Props {
    language: 'en' | 'hu';
}

const AlertMessageInput: React.FC<Props> = ({ language }) => {
    const classes = useStyles();

    const quillRef = useRef<ReactQuill | null>(null);

    const { register, control, formState: { errors } } = useFormContext<TransitAlertFormValue>();
    const descriptionFieldName = `${language}.description` as const;

    const { field: descriptionField } = useController<TransitAlertFormValue, typeof descriptionFieldName>({
        name: descriptionFieldName,
        rules: { validate: () => validateDescription(quillRef.current!) },
        control,
    });

    return (
        <>
            <Typography variant="h3" sx={{ fontSize: 34, textTransform: 'capitalize', marginBottom: 1 }}>
                <FormattedDisplayName value={language} type="language" />
            </Typography>

            <TextField
                required
                fullWidth
                margin="normal"
                label={<FormattedMessage id="alert.summary" />}
                error={Boolean(errors[language]?.summary)}
                variant="standard"
                sx={{ marginBottom: 4 }}
                {...register(`${language}.summary`, { required: true })}
            />

            <FormLabel>
                <FormattedMessage id="alert.description" />
            </FormLabel>
            <ReactQuill
                theme="snow"
                modules={QUILL_MODULES}
                className={classNames(classes.description, errors[language]?.description && classes.descriptionError)}
                {...descriptionField}
                ref={quillRef}
            />
        </>
    );
};

function validateDescription(quill: ReactQuill): boolean {
    const editor = quill.getEditor();
    return editor.getText().trim() !== ''
        || editor.root.querySelector('img') !== null;
}

export default AlertMessageInput;
