import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { BookingDirection } from '../../../../common/interfaces';

interface Props {
    direction: BookingDirection;
}

const BookingDirectionMessage: React.FunctionComponent<Props> = ({ direction }) => {
    switch (direction) {
        case 'INBOUND':
            return <FormattedMessage id="bookings.direction.inbound" />;
        case 'OUTBOUND':
            return <FormattedMessage id="bookings.direction.outbound" />;
    }
};

export default BookingDirectionMessage;
