import type { BlockActivity, BlockKey } from '../../../../common/interfaces';
import type { AppState } from '../../redux/state';
import { getVehicleBlockKey } from '../../utils';

export interface LockVehicleBlockVersions {
    [blockKey: string]: number;
}

export const getLockVehicleBlockVersions = ({ vehicleBlocks: { lockVehicleBlockVersions } }: AppState) => ({ lockVehicleBlockVersions });

export function getBlockVersionsGraphQLContext(
    lockVehicleBlockVersions: LockVehicleBlockVersions,
    blockActivity: BlockActivity | null,
    vehicleBlockKey?: BlockKey,
) {
    const versions = new Map<string, number>();

    function addVersion(blockKey: BlockKey) {
        versions.set(blockKey, lockVehicleBlockVersions[blockKey] || 0);
    }

    if (blockActivity) {
        addVersion(getVehicleBlockKey(blockActivity));
    }
    if (vehicleBlockKey) {
        addVersion(vehicleBlockKey);
    }

    const blockVersionsHeader = Array.from(versions.entries())
        .map(([blockKey, blockVersion]) => `${blockKey}=${blockVersion}`)
        .join(',');
    return {
        headers: {
            'X-Block-Versions': blockVersionsHeader,
        },
    };
}
