import * as React from 'react';
import { connect } from 'react-redux';
import FilteredVehicleRow from './FilteredVehicleRow';
import type { ResolvedCoreRealtimeVehicle } from '../../../../common/interfaces';
import type { AppState, VehiclesData } from '../../redux/state';

interface Props {
    filter: string;
    routeIds: string[];
    vehiclesData: VehiclesData;
    logoutDriverOnClick: (vehicleId: string) => void;
    delayStatusFilter: string[];
}

class RawFilteredVehicleList extends React.PureComponent<Props> {
    private getFilteredVehicles(): ResolvedCoreRealtimeVehicle[] {
        const {
            filter,
            routeIds,
            vehiclesData: { vehicles },
            delayStatusFilter,
        } = this.props;

        if (filter === '' && routeIds.length === 0 && delayStatusFilter.length === 0) {
            return vehicles;
        }

        return vehicles.filter((vehicle) => {
            if (delayStatusFilter.length !== 0) {
                if (!vehicle.delayStatus || !delayStatusFilter.includes(vehicle.delayStatus)) {
                    return false;
                }
            }
            if (routeIds.length > 0) {
                if (!vehicle.routeId || !routeIds.includes(vehicle.routeId)) {
                    return false;
                }
            }
            if (filter === '') {
                return true;
            }

            for (const key of Object.keys(vehicle)) {
                const value = vehicle[key as keyof typeof vehicle];
                if (typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }

    render() {
        const { vehiclesData, logoutDriverOnClick } = this.props;
        const filteredVehicles = this.getFilteredVehicles();

        return filteredVehicles.map(vehicle => (
            <FilteredVehicleRow
                key={vehicle.licensePlate}
                vehicle={vehicle}
                time={vehiclesData.time}
                logoutDriverOnClick={logoutDriverOnClick}
            />
        ));
    }
}

export default connect(({ vehicles }: AppState) => ({
    vehiclesData: vehicles.list,
}))(RawFilteredVehicleList);
