import CircularProgress from '@mui/material/CircularProgress';
import Message from '@realcity/web-frame/lib/components/Message';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { VehicleId } from '../../../../common/interfaces';
import { isInternalMapEnabled } from '../../config';
import { useVehiclePositionForMapRedirectQuery } from '../../graphql/graphql-typings';
import { getInternalMapUrl, getMapUrl } from '../../utils';

type Props = RouteComponentProps<{ vehicleId: VehicleId }>;

const MapRedirectPage: React.FunctionComponent<Props> = ({
    match: {
        params: { vehicleId },
    },
    history,
}) => {
    const { data, loading, error } = useVehiclePositionForMapRedirectQuery({
        variables: {
            vehicleId,
        },
    });

    if (loading) {
        return <CircularProgress />;
    }
    if (data?.vehicle) {
        const { vehicle } = data;
        if (isInternalMapEnabled()) {
            history.replace(getInternalMapUrl(vehicle.trip, vehicle));
        } else {
            window.location.replace(getMapUrl(vehicle.trip, vehicle));
        }
        return <CircularProgress />;
    }
    if (error) {
        return (
            <div>
                <Message severity="error" messageTitle={<FormattedMessage id="map-redirect.error.title" />}>
                    <FormattedMessage id="map-redirect.error.message" />
                </Message>
            </div>
        );
    }
    return (
        <div>
            <Message severity="warning" messageTitle={<FormattedMessage id="map-redirect.not-found.title" />}>
                <FormattedMessage id="map-redirect.not-found.message" />
            </Message>
        </div>
    );
};

export default withRouter(MapRedirectPage);
