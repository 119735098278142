import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ValueType } from 'react-select/lib/types';
import type { AutocompleteOption, BlockId, ResolvedAssignmentDetails, ResourceId, ServiceDate } from '../../../../common/interfaces';
import AutoComplete from '../../components/AutoComplete';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import { Roles } from '../../constants';

interface AssignmentDialogProps {
    options: AutocompleteOption[];
    title: React.ReactNode;
    blockId: BlockId;
    serviceDate: ServiceDate;
    defaultValue: AutocompleteOption;
    sendAssignment: (details: ResolvedAssignmentDetails) => void;
}

const AssignmentDialog: React.FunctionComponent<AssignmentDialogProps> = ({
    title,
    options,
    blockId,
    serviceDate,
    defaultValue,
    sendAssignment,
}) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue);

    const handleClickOpen = () => {
        setOpen(true);
        setInputValue(defaultValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOkClick = () => {
        const details: ResolvedAssignmentDetails = {
            blockId,
            date: serviceDate,
            resourceId: inputValue.value as ResourceId,
        };

        sendAssignment(details);
        setOpen(false);
    };

    const onAutoCompleteChange = (newValue: ValueType<AutocompleteOption>) => {
        setInputValue(newValue as AutocompleteOption);
    };

    return (
        <>
            <IfRole role={Roles.Write}>
                <IconButton onClick={handleClickOpen} size="large">
                    <Edit style={{ fontSize: 15 }} />
                </IconButton>
            </IfRole>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent className="dialog__content">
                    <AutoComplete value={inputValue} onChange={onAutoCompleteChange} options={options} />
                </DialogContent>
                <ContainedDialogActions>
                    <Button variant="contained" color="primary" onClick={handleOkClick} disabled={!inputValue.value.length}>
                        <FormattedMessage id="blocks-overview.block-info.assignment-dialog.confirm" />
                    </Button>
                </ContainedDialogActions>
            </Dialog>
        </>
    );
};

export default AssignmentDialog;
