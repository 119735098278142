import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { calculateLeft } from './functions';
import './styles.css';

export interface Props {
    begin: Date;
    end: Date;
    zoom: number;
}

export default class DateFrameLabels extends React.PureComponent<Props> {
    render() {
        const { begin, end, zoom } = this.props;

        const date = new Date(begin);

        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // Leave a padding of at least 10 minutes
        if (begin.getTime() + 10 * 60 * 1000 > date.getTime()) {
            // Start only from the next hour
            date.setHours(date.getHours() + 1);
        }

        const hourLabels = [];

        while (date <= end) {
            const timestamp = date.getTime();
            hourLabels.push(
                <div className="date-frame__hour-label" style={{ left: calculateLeft(begin, date, zoom) }} key={timestamp}>
                    <Typography variant="subtitle2">
                        {date.getHours() === 0 && (
                            <>
                                <FormattedDate value={timestamp} />
                                &nbsp;
                            </>
                        )}
                        <FormattedTime value={timestamp} />
                    </Typography>
                </div>,
            );
            date.setHours(date.getHours() + 1);
        }

        return <div className="date-frame__label-row">{hourLabels}</div>;
    }
}
