import type { SymbolLayout } from 'mapbox-gl';
import React from 'react';
import { GeoJSONLayer, Layer } from 'react-mapbox-gl';
import type { ImageDefinitionWithOptions } from 'react-mapbox-gl/lib/layer';
import busSrc from '../../images/map/bus.png';
import { image } from '../../utils/map';
import { VEHICLE_MAP_LAYER } from '../../pages/Map';

const VEHICLE_LAYOUT: SymbolLayout = {
    'icon-image': 'vehicle',
    'icon-allow-overlap': true,
    'icon-size': 0.5,
    'icon-pitch-alignment': 'map',
};

interface Props {
    vehiclePosition: GeoJSON.Point;
}

const IMAGES: ImageDefinitionWithOptions[] = [
    ['vehicle', image(busSrc), { pixelRatio: 2 }],
];

const VehicleLayer: React.FunctionComponent<Props> = ({ vehiclePosition }) => (
    <>
        <Layer images={IMAGES} />
        <GeoJSONLayer data={vehiclePosition} type="symbol" symbolLayout={VEHICLE_LAYOUT} before={VEHICLE_MAP_LAYER} />
    </>
);

export default VehicleLayer;
