import type { AuthAction } from '@realcity/web-frame/lib/components/Auth/actions';
import type { ApolloError } from '@apollo/client';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import type { Action, Dispatch } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type {
    BlockActivitiesResponse,
    ClientNotificationAcknowledgement,
    GtfsDriver,
    Notification,
    ResolvedAssignment,
    ResolvedAssignmentDetails,
    ResolvedCoreRealtimeVehicle,
    Route,
    ServiceDate,
    StaticVehicle,
    TripActivity,
} from '../../../common/interfaces';
import { isAuthenticationDisabled } from '../config';
import type { ActivityModificationNotificationType, AppState, VehicleBlockSortingCondition, VehiclesData } from './state';

export type AppDispatch = ThunkDispatch<AppState, void, AppAction>;

export interface Loadable<T> {
    list: T;
    loading: boolean;
    error?: any;
    loadedTime?: number;
}

export interface OptionalLoadable<T> extends Omit<Loadable<T>, 'list'> {
    list?: T;
}

export interface BaseFetchableAction<T extends ACTION_TYPES, P extends any[] = unknown[]> extends Action {
    type: T;
    data: OptionalLoadable<ACTION_VALUES[T]>;
    params: P;
}

export type ACTION_TYPES = keyof ACTION_VALUES;

/* eslint-disable @typescript-eslint/no-invalid-void-type */
export interface ACTION_VALUES {
    ROUTES: Route[];
    VEHICLES: VehiclesData;
    TRIPS: TripActivity[];
    ACTIVITIES: BlockActivitiesResponse | undefined;
    NOTIFICATIONS: Notification[];
    LOGOUT_DRIVER: void;
    ACKNOWLEDGE_NOTIFICATION: Notification | undefined;
    VEHICLE_ASSIGNMENTS: ResolvedAssignment[];
    DRIVER_ASSIGNMENTS: ResolvedAssignment[];
    GTFS_VEHICLES: StaticVehicle[];
    GTFS_DRIVERS: GtfsDriver[];
    SEND_VEHICLE_ASSIGNMENT: void;
    SEND_DRIVER_ASSIGNMENT: void;
}
/* eslint-enable @typescript-eslint/no-invalid-void-type */

type FetchableAction =
    | BaseFetchableAction<'ACKNOWLEDGE_NOTIFICATION'>
    | BaseFetchableAction<'ACTIVITIES', [BlockActivitiesRequestParams]>
    | BaseFetchableAction<'DRIVER_ASSIGNMENTS'>
    | BaseFetchableAction<'GTFS_DRIVERS'>
    | BaseFetchableAction<'GTFS_VEHICLES'>
    | BaseFetchableAction<'LOGOUT_DRIVER'>
    | BaseFetchableAction<'NOTIFICATIONS'>
    | BaseFetchableAction<'ROUTES'>
    | BaseFetchableAction<'TRIPS'>
    | BaseFetchableAction<'VEHICLE_ASSIGNMENTS'>
    | BaseFetchableAction<'VEHICLES'>;

interface ToggleNotificationsAction extends Action {
    type: 'TOGGLE_NOTIFICATIONS';
}

interface CloseActivityModificationNotificationAction extends Action {
    type: 'CLOSE_ACTIVITY_MODIFICATION_NOTIFICATION';
    id: number;
}

interface AddActivityModificationNotificationAction extends Action {
    type: 'ADD_ACTIVITY_MODIFICATION_NOTIFICATION';
    notificationType: ActivityModificationNotificationType;
}

interface StartBlockActivityEditAction extends Action {
    type: 'START_BLOCK_ACTIVITY_EDIT';
}

interface VehicleBlockSortingConditionChangeAction extends Action {
    type: 'VEHICLE_BLOCK_SORTING_CONDITION_CHANGE';
    sortingCondition: VehicleBlockSortingCondition;
}

interface ChangeServiceDateAction extends Action {
    type: 'CHANGE_SERVICE_DATE';
    serviceDate: ServiceDate;
}

interface ChangeRoutes extends Action {
    type: 'CHANGE_ROUTES';
    routes: string[] | null;
}

function fetchSuccess<T extends ACTION_TYPES, P extends any[]>(type: T, params: P, list: ACTION_VALUES[T]): BaseFetchableAction<T, P> {
    return { type, params, data: { list, loading: false, error: null, loadedTime: new Date().getTime() } };
}

function fetchStart<T extends ACTION_TYPES, P extends any[]>(type: T, params: P): BaseFetchableAction<T, P> {
    return { type, params, data: { loading: true, error: null } };
}

function fetchFailure<T extends ACTION_TYPES, P extends any[]>(type: T, params: P, error: any): BaseFetchableAction<T, P> {
    return { type, params, data: { loading: false, error } };
}

type PromiseProvider<T, P extends any[]> = (
    axiosConfig: AxiosRequestConfig,
    dispatch: Dispatch<AppAction>,
    getState: () => AppState,
    ...params: P
) => Promise<T>;

function createFetchActionCreator<T extends ACTION_TYPES, P extends any[]>(type: T, promiseProvider: PromiseProvider<ACTION_VALUES[T], P>) {
    // We're actually returning a function that is going to be executed by redux-thunk,
    // but the return type needs to remain Action because typings would break otherwise.
    return function fetch(...params: P): Action {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (async (dispatch: Dispatch<AppAction>, getState: () => AppState) => {
            dispatch(fetchStart(type, params) as FetchableAction);

            let axiosConfig: AxiosRequestConfig = {};
            if (!isAuthenticationDisabled()) {
                const keycloak = getState().auth.keycloak!;
                await keycloak.updateToken(30);

                axiosConfig = {
                    headers: {
                        Authorization: `Bearer ${keycloak.token!}`,
                    },
                };
            }

            // eslint-disable-next-line @typescript-eslint/init-declarations
            let data: ACTION_VALUES[T];

            try {
                data = await promiseProvider(axiosConfig, dispatch, getState, ...params);
            } catch (exception) {
                dispatch(fetchFailure(type, params, exception) as FetchableAction);
                throw exception;
            }

            dispatch(fetchSuccess(type, params, data) as FetchableAction);
        }) as any;
    };
}

export const fetchRoutes = createFetchActionCreator('ROUTES', async axiosConfig => axios
    .get<Route[]>('/api/routes', axiosConfig).then(getData));
export const fetchVehicles = createFetchActionCreator('VEHICLES', async axiosConfig => axios
    .get('/api/vehicles', axiosConfig).then(convertVehicles));
export const fetchTrips = createFetchActionCreator('TRIPS', async (axiosConfig, dispatch, getState, serviceDate: ServiceDate) => axios
    .get<TripActivity[]>('/api/trips', { ...axiosConfig, params: { serviceDate } }).then(getData));
export const fetchActivities = createFetchActionCreator(
    'ACTIVITIES',
    async (axiosConfig, dispatch, getState, requestParams: BlockActivitiesRequestParams) => {
        const { serviceDate, routes, cursor } = requestParams;
        const params: any = { serviceDate };
        if (routes !== null) {
            if (routes.length > 0) {
                params.vehiclesOnRoutes = routes.join(',');
            } else {
                return;
            }
        }
        if (cursor) {
            params.cursor = cursor;
        }
        const response = await axios.get<BlockActivitiesResponse>('/api/block-activities', { ...axiosConfig, params });
        return getData(response);
    },
);
export const fetchVehicleAssignments = createFetchActionCreator(
    'VEHICLE_ASSIGNMENTS',
    async (axiosConfig, dispatch, getState, serviceDate: string) => axios
        .get<ResolvedAssignment[]>('/api/vehicle-assignments', { ...axiosConfig, params: { serviceDate } }).then(getData),
);
export const sendVehicleAssignment = createFetchActionCreator(
    'SEND_VEHICLE_ASSIGNMENT', async (axiosConfig, dispatch, getState, assignmentDetails: ResolvedAssignmentDetails) => {
        await axios.post('api/vehicle-assignments', assignmentDetails, axiosConfig);
        dispatch(fetchVehicleAssignments(assignmentDetails.date) as any);
    },
);
export const fetchDriverAssignments = createFetchActionCreator(
    'DRIVER_ASSIGNMENTS',
    async (axiosConfig, dispatch, getState, serviceDate: string) => axios
        .get<ResolvedAssignment[]>('/api/driver-assignments', { ...axiosConfig, params: { serviceDate } }).then(getData),
);
export const sendDriverAssignment = createFetchActionCreator(
    'SEND_DRIVER_ASSIGNMENT', async (axiosConfig, dispatch, getState, assignmentDetails: ResolvedAssignmentDetails) => {
        await axios.post('api/driver-assignments', assignmentDetails, axiosConfig);
        dispatch(fetchDriverAssignments(assignmentDetails.date) as any);
    },
);
export const fetchGtfsVehicles = createFetchActionCreator('GTFS_VEHICLES', async axiosConfig => axios
    .get<StaticVehicle[]>('/api/gtfs/vehicles', axiosConfig).then(getData));
export const fetchGtfsDrivers = createFetchActionCreator('GTFS_DRIVERS', async axiosConfig => axios
    .get<GtfsDriver[]>('/api/gtfs/drivers', axiosConfig).then(getData));

export const logoutDriver = createFetchActionCreator(
    'LOGOUT_DRIVER', async (axiosConfig, dispatch, getState, vehicleId: string) => {
        await axios.post('api/logout-driver', { vehicleId }, axiosConfig);
    },
);

export const acknowledgeNotification = createFetchActionCreator(
    'ACKNOWLEDGE_NOTIFICATION',
    async (axiosConfig, dispatch, getState, message: ClientNotificationAcknowledgement) => axios
        .post<Notification>('api/notifications/acknowledge', message, axiosConfig).then(getData),
);

function convertVehicles(response: AxiosResponse<ResolvedCoreRealtimeVehicle[]>): VehiclesData {
    const { data, headers } = response;

    data.sort((v1, v2) => v1.licensePlate.localeCompare(v2.licensePlate));
    return {
        vehicles: data,
        time: new Date(headers.date).getTime(),
    };
}

export const fetchNotifications = createFetchActionCreator('NOTIFICATIONS', async axiosConfig => axios
    .get<Notification[]>('/api/notifications', axiosConfig).then(getData));

function getData<T>(response: AxiosResponse<T>): T {
    return response.data;
}

export const toggleNotifications = (): ToggleNotificationsAction => ({
    type: 'TOGGLE_NOTIFICATIONS',
});

export const resetCurrentNotificationAcknowledgement = (): BaseFetchableAction<'ACKNOWLEDGE_NOTIFICATION'> => ({
    type: 'ACKNOWLEDGE_NOTIFICATION',
    data: { list: undefined, loading: false, error: null },
    params: [],
});

export const closeActivityModificationNotification = (id: number): CloseActivityModificationNotificationAction => ({
    type: 'CLOSE_ACTIVITY_MODIFICATION_NOTIFICATION',
    id,
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const refreshBlockActivities = (): Action => ((dispatch: Dispatch<AppAction>, getState: () => AppState) => {
    const params = getCurrentBlockActivitiesRequestParams(getState, true);
    dispatch(fetchActivities(params));
}) as any;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const activityModificationSuccess = (): Action => ((dispatch: AppDispatch) => {
    dispatch({ type: 'ADD_ACTIVITY_MODIFICATION_NOTIFICATION', notificationType: 'success' });
    dispatch(refreshBlockActivities());
}) as any;

export const activityModificationError = (error: ApolloError): AddActivityModificationNotificationAction => {
    let notificationType: ActivityModificationNotificationType = 'other-error';

    if (error.networkError) {
        const { result, statusCode } = error.networkError as any;
        if (statusCode === 400 && result === 'OUTDATED_BLOCK_VERSION') {
            notificationType = 'lock-error';
        }
    }

    return {
        type: 'ADD_ACTIVITY_MODIFICATION_NOTIFICATION',
        notificationType,
    };
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const startBlockActivityEdit = (): Action => ((dispatch: Dispatch<AppAction>) => {
    dispatch({
        type: 'START_BLOCK_ACTIVITY_EDIT',
    } as StartBlockActivityEditAction);
}) as any;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const setVehicleBlockSortingCondition = (sortingCondition: VehicleBlockSortingCondition): Action => ((dispatch: AppDispatch) => {
    dispatch({ type: 'VEHICLE_BLOCK_SORTING_CONDITION_CHANGE', sortingCondition });
    // TODO: refreshing all the activities should not be needed to trigger a reorder
    dispatch(refreshBlockActivities());
}) as any;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const changeServiceDate = (serviceDate: ServiceDate): Action => ((dispatch: AppDispatch, getState: () => AppState) => {
    dispatch({ type: 'CHANGE_SERVICE_DATE', serviceDate });
    dispatch(
        fetchActivities({
            ...getCurrentBlockActivitiesRequestParams(getState),
            serviceDate,
        }),
    );
    dispatch(fetchVehicleAssignments(serviceDate));
    dispatch(fetchDriverAssignments(serviceDate));
}) as any;

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const changeRoutes = (routes: string[] | null) => ((dispatch: AppDispatch, getState: () => AppState) => {
    dispatch({ type: 'CHANGE_ROUTES', routes });
    dispatch(
        fetchActivities({
            ...getCurrentBlockActivitiesRequestParams(getState),
            routes,
        }),
    );
}) as any;

export interface BlockActivitiesRequestParams {
    serviceDate: string;
    routes: string[] | null;
    cursor?: string;
}

function getCurrentBlockActivitiesRequestParams(getState: () => AppState, withCursor = false): BlockActivitiesRequestParams {
    const { vehicleBlocks: prevState } = getState();
    const { serviceDate, routes, cursor } = prevState;
    const params: BlockActivitiesRequestParams = { serviceDate, routes };
    if (withCursor && cursor) {
        params.cursor = cursor;
    }
    return params;
}

export type AppAction =
    | AddActivityModificationNotificationAction
    | AuthAction
    | ChangeRoutes
    | ChangeServiceDateAction
    | CloseActivityModificationNotificationAction
    | FetchableAction
    | StartBlockActivityEditAction
    | ToggleNotificationsAction
    | VehicleBlockSortingConditionChangeAction;
