import { useQuery } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Block, BlockId, ServiceDate } from '../../../../common/interfaces';
import BlockSelector from '../../components/BlockSelector';
import { GET_DRIVER_BLOCKS } from '../../graphql/rest';

export const AUTOMATIC = {};

export type BlockIdSelection = BlockId | typeof AUTOMATIC;

interface Props {
    driverBlockId: BlockIdSelection;
    serviceDate: ServiceDate;
    onChange: (value: BlockIdSelection) => void;
}

const DriverBlockSelector: React.FunctionComponent<Props> = ({ driverBlockId, serviceDate, onChange }) => {
    const { data } = useQuery<{ driverBlocks?: Block[] }>(GET_DRIVER_BLOCKS, {
        variables: {
            serviceDate,
        },
    });

    return (
        <BlockSelector
            blocks={data?.driverBlocks}
            blockId={driverBlockId}
            onChange={onChange}
            inputLabel={<FormattedMessage id="blocks-overview.block-activity-actions.block-change.driver-block-id" />}
        >
            <MenuItem value={AUTOMATIC as any}>
                <FormattedMessage id="block-selector.automatic" />
            </MenuItem>
        </BlockSelector>
    );
};

export default DriverBlockSelector;
