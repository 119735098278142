import * as React from 'react';
import classNames from 'classnames';
import type { Theme } from '@mui/material/styles';
import { emphasize } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Cancel from '@mui/icons-material/Cancel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Select from 'react-select';
import type { MultiValueProps } from 'react-select/lib/components/MultiValue';
import type { Props as SelectProps } from 'react-select/lib/Select';
import type { MenuProps, NoticeProps } from 'react-select/lib/components/Menu';
import type { OptionProps } from 'react-select/lib/components/Option';
import type { PlaceholderProps } from 'react-select/lib/components/Placeholder';
import type { ValueContainerProps } from 'react-select/lib/components/containers';
import type { SingleValueProps } from 'react-select/lib/components/SingleValue';
import type { ControlProps } from 'react-select/lib/components/Control';

const useControlStyles = makeStyles({
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
});

const useValueContainerStyles = makeStyles({
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: 40,
        padding: 6,
    },
});

const useMultiValueStyles = makeStyles((theme: Theme) => ({
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
    },
}));

const useNoOptionsMessageStyles = makeStyles((theme: Theme) => ({
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
}));

const useSingleValueStyles = makeStyles({
    singleValue: {
        fontSize: 16,
    },
});

const usePlaceholderStyles = makeStyles((theme: Theme) => ({
    placeholder: {
        left: 2,
        fontSize: 16,
        padding: theme.spacing(1),
    },
}));

const useMenuStyles = makeStyles((theme: Theme) => ({
    paper: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
}));

const useOptionStyles = makeStyles({
    selectedMenuItem: {
        fontWeight: 500,
    },
    unselectedMenuItem: {
        fontWeight: 400,
    },
});

const Control: React.FunctionComponent<ControlProps<any>> = ({ innerRef, children, innerProps, selectProps }) => {
    const classes = useControlStyles();

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent: 'div',
                inputProps: {
                    className: classes.input,
                    children,
                    ...innerProps,
                },
                inputRef: innerRef,
            }}
            {...selectProps.textFieldProps}
        />
    );
};

const NoOptionsMessage: React.FunctionComponent<NoticeProps<any>> = ({ innerProps, children }) => {
    const classes = useNoOptionsMessageStyles();

    return (
        <Typography color="textSecondary" className={classes.noOptionsMessage} {...innerProps}>
            {children}
        </Typography>
    );
};

const MultiValue: React.FunctionComponent<MultiValueProps<any>> = ({ selectProps, data, isFocused, children, removeProps }) => {
    const classes = useMultiValueStyles();

    const { renderAvatar } = selectProps;

    return (
        <Chip
            avatar={renderAvatar?.(data)}
            tabIndex={-1}
            label={children}
            className={classNames(classes.chip, isFocused && classes.chipFocused)}
            onDelete={removeProps.onClick}
            deleteIcon={<Cancel {...removeProps} />}
        />
    );
};

const SingleValue: React.FunctionComponent<SingleValueProps<any>> = ({ innerProps, children }) => {
    const classes = useSingleValueStyles();

    return (
        <Typography className={classes.singleValue} {...innerProps}>
            {children}
        </Typography>
    );
};

const Option: React.FunctionComponent<OptionProps<any>> = ({ isSelected, innerRef, isFocused, innerProps, children }) => {
    const classes = useOptionStyles();
    const className = isSelected ? classes.selectedMenuItem : classes.unselectedMenuItem;

    return (
        <MenuItem
            ref={innerRef}
            selected={isFocused}
            component="div"
            className={className}
            {...(innerProps as any)}
        >
            {children}
        </MenuItem>
    );
};

const Placeholder: React.FunctionComponent<PlaceholderProps<any>> = ({ innerProps, children }) => {
    const classes = usePlaceholderStyles();

    return (
        <Typography color="textSecondary" className={classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
};

const ValueContainer: React.FunctionComponent<ValueContainerProps<any>> = ({ children }) => {
    const classes = useValueContainerStyles();

    return <div className={classes.valueContainer}>{children}</div>;
};

const Menu: React.FunctionComponent<MenuProps<any>> = ({ innerProps, children }) => {
    const classes = useMenuStyles();

    return (
        <Paper square className={classes.paper} {...innerProps}>
            {children}
        </Paper>
    );
};

interface Props<T> extends SelectProps<T> {
    renderAvatar?: (option: T) => React.ReactElement | undefined;
}

class AutoComplete<P> extends React.PureComponent<Props<P>> {
    render() {
        return (
            <Select
                {...this.props}
                components={{ Control, Menu, MultiValue, NoOptionsMessage, Option, Placeholder, SingleValue, ValueContainer }}
            />
        );
    }
}

export default AutoComplete;
