import { useQuery } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { BlockActivityType } from '../../../../common/interfaces';
import { GET_ACTIVITY_TYPES } from '../../graphql/rest';
import { ActivityCategory } from './index';
import SelectWithEmptyValue from './SelectWithEmptyValue';

interface Props {
    category: ActivityCategory;
    activityType: BlockActivityType | null;
    onChange: (activityType: BlockActivityType | null) => void;
}

const ActivityTypeSelector: React.FunctionComponent<Props> = ({ category, activityType, onChange }) => {
    const { data } = useQuery<{ activityTypes?: BlockActivityType[] }>(GET_ACTIVITY_TYPES);

    const typesForCategory = data?.activityTypes && activityTypesInCategory(data.activityTypes, category);
    return (
        <SelectWithEmptyValue
            value={activityType}
            onChange={onChange}
            values={typesForCategory}
            label={<FormattedMessage id="create-activity-dialog.activity-type" />}
        >
            {typesForCategory?.map(type => (
                <MenuItem key={type.id} value={type as any}>
                    {type.name}
                </MenuItem>
            ))}
        </SelectWithEmptyValue>
    );
};

function activityTypesInCategory(activityTypes: BlockActivityType[], category: ActivityCategory): BlockActivityType[] {
    return activityTypes.filter((type) => {
        const activityTypeCategory = type.type === 'DRIVING' ? ActivityCategory.TRIP : ActivityCategory.OTHER;

        return activityTypeCategory === category;
    });
}

export default ActivityTypeSelector;
