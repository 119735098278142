import React from 'react';
import { FormattedDate } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    tooltip: {
        marginBottom: 0,
    },
});

export interface Props extends Intl.DateTimeFormatOptions {
    value: string;
}

const TimeTooltip: React.FC<Props> = ({ children, value, ...formatOptions }) => {
    const classes = useStyles();

    const title = (
        <Typography variant="body2">
            <FormattedDate value={value} {...formatOptions} />
        </Typography>
    );

    return (
        <Tooltip placement="top" title={title} classes={{ tooltip: classes.tooltip }}>
            <span>{children}</span>
        </Tooltip>
    );
};

export default TimeTooltip;
