import type { ThemeOptions } from '@mui/material/styles/createTheme';
import createTheme from '@mui/material/styles/createTheme';
import { configureStore } from '@realcity/web-frame';
import { AuthWrapper, RequireAuth } from '@realcity/web-frame/lib/components/Auth';
import IfRoleOrUnauthorizedApplicationMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedApplicationMessage';
import { Base } from '@realcity/web-frame/lib/components/Base';
import CustomTheme from '@realcity/web-frame/lib/theme/CustomTheme';
import IntlProvider from '@realcity/web-frame/lib/components/Intl/Provider';
import StyledErrorBoundary from '@realcity/web-frame/lib/components/StyledErrorBoundary';
import PageFrame from '@realcity/web-frame/lib/components/PageFrame';
import RealCity from '@realcity/web-frame/lib/themes/RealCity';
import 'moment/locale/hu';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { isAuthenticationDisabled } from './config';
import { Roles } from './constants';
import ClosableNotificationsSidebar from './containers/NotificationsSidebar';
import ToggleNotificationsButton from './containers/NotificationsSidebar/ToggleNotificationsButton';
import MainMenu from './MainMenu';
import GraphqlWrapper from './providers/GraphqlWrapper';
import LocalizedMuiPickersUtilsProvider from './providers/LocalizedMuiPickersUtilsProvider';
import reducers from './redux/reducers';
import Routes from './Routes';
import './styles.css';
import messages from './translations';

const store = configureStore(reducers);

const authOptions = {};

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontSize: 34,
                        fontWeight: 500,
                    },
                },
                {
                    props: { variant: 'h2' },
                    style: {
                        fontSize: 20,
                        fontWeight: 500,
                    },
                },
            ],
        },
    },
};

const theme = createTheme(themeOptions, RealCity);

const App: React.FunctionComponent = () => (
    <Provider store={store}>
        <IntlProvider supportedLocales={['en', 'hu']} messages={messages}>
            <CustomTheme theme={theme}>
                <StyledErrorBoundary>
                    <LocalizedMuiPickersUtilsProvider>
                        <AuthWrapper options={authOptions} authenticationDisabled={isAuthenticationDisabled()}>
                            <RequireAuth>
                                <IfRoleOrUnauthorizedApplicationMessage role={Roles.Read}>
                                    <GraphqlWrapper>
                                        <BrowserRouter>
                                            <Base
                                                appName="dispatch"
                                                appVersion={process.env.REACT_APP_VERSION!}
                                                useFeedback={false}
                                                menu={<MainMenu />}
                                                actions={<ToggleNotificationsButton />}
                                                privateApp
                                            >
                                                <PageFrame>
                                                    <Routes />
                                                </PageFrame>
                                                <ClosableNotificationsSidebar />
                                            </Base>
                                        </BrowserRouter>
                                    </GraphqlWrapper>
                                </IfRoleOrUnauthorizedApplicationMessage>
                            </RequireAuth>
                        </AuthWrapper>
                    </LocalizedMuiPickersUtilsProvider>
                </StyledErrorBoundary>
            </CustomTheme>
        </IntlProvider>
    </Provider>
);

export default App;
