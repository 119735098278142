import React, { useContext, useEffect, useState } from 'react';
import ReactMapboxGl, { MapContext } from 'react-mapbox-gl';
import type { Props as MapProps } from 'react-mapbox-gl/lib/map';
import type { Events as MapEvents } from 'react-mapbox-gl/lib/map-events';
import makeStyles from '@mui/styles/makeStyles';
import APP_CONFIG, { parseMapboxConfigBounds } from '../../config';

const MapResizeListener: React.FC = () => {
    const mapContext = useContext(MapContext);

    useEffect(() => {
        let lastWidth = 0;
        // eslint-disable-next-line @typescript-eslint/init-declarations
        let animationFrameId: number;

        const resizeMapCallback = () => {
            if (mapContext) {
                const width = mapContext.getContainer().offsetWidth;
                if (width !== lastWidth) {
                    lastWidth = width;
                    mapContext.resize();
                }
            }
            animationFrameId = requestAnimationFrame(resizeMapCallback);
        };

        animationFrameId = requestAnimationFrame(resizeMapCallback);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [mapContext]);

    return null;
};

const Map = ReactMapboxGl({
    accessToken: '',
});

const useStyles = makeStyles({
    root: {
        flex: 1,
    },
});

interface Props extends Omit<MapProps, 'style'>, MapEvents {}

const StyledMap: React.FunctionComponent<Props> = ({ children, ...rest }) => {
    const classes = useStyles();

    const [bounds] = useState(parseMapboxConfigBounds);

    return (
        <Map fitBounds={bounds} style={APP_CONFIG.MAP_STYLE} className={classes.root} {...rest}>
            <>
                <MapResizeListener />
                {children}
            </>
        </Map>
    );
};

export default StyledMap;
