import { useMutation } from '@apollo/client';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { CreateActivityCommand } from '../../../../common/interfaces';
import AsyncActionButton from '../../components/AsyncActionButton';
import { CREATE_ACTIVITY } from '../../graphql/rest';
import {
    activityModificationError as activityModificationErrorAction,
    activityModificationSuccess as activityModificationSuccessAction,
} from '../../redux/actions';
import { getBlockKey } from '../../utils';
import type { LockVehicleBlockVersions } from '../BlockActivityActions/locking';
import { getBlockVersionsGraphQLContext, getLockVehicleBlockVersions } from '../BlockActivityActions/locking';

interface Props {
    activityCommand: CreateActivityCommand | null;
    onFinish: () => void;

    lockVehicleBlockVersions: LockVehicleBlockVersions;
    activityModificationSuccess: typeof activityModificationSuccessAction;
    activityModificationError: typeof activityModificationErrorAction;
}

const CreateActivityButton: React.FunctionComponent<Props> = (props) => {
    const { activityCommand, onFinish, lockVehicleBlockVersions, activityModificationSuccess, activityModificationError } = props;

    const [createActivity, { loading }] = useMutation(CREATE_ACTIVITY, {
        onCompleted: () => {
            onFinish();
            activityModificationSuccess();
        },
        onError: activityModificationError,
    });

    const onAdd = () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        createActivity({
            variables: { command: activityCommand! },
            context: getBlockVersionsGraphQLContext(
                lockVehicleBlockVersions,
                null,
                getBlockKey('VEHICLE', activityCommand!.vehicleBlockId, activityCommand!.serviceDate),
            ),
        });
    };

    return (
        <AsyncActionButton variant="contained" color="primary" disabled={activityCommand === null} loading={loading} onClick={onAdd}>
            <FormattedMessage id="create-activity-dialog.add" />
        </AsyncActionButton>
    );
};

export default connect(getLockVehicleBlockVersions, {
    activityModificationError: activityModificationErrorAction,
    activityModificationSuccess: activityModificationSuccessAction,
})(CreateActivityButton);
