import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import React, { Fragment } from 'react';
import { useRoutesForBlocksOverviewFilterQuery } from '../../graphql/graphql-typings';
import { routeIdsToRoutes } from '../../utils';

const NUMBER_OF_ROUTES_SHOWN = 5;

interface Props {
    routeIds: string[] | null;
}

const FilteredRoutesList = ({ routeIds }: Props) => {
    const { data } = useRoutesForBlocksOverviewFilterQuery();

    const routes = routeIdsToRoutes(routeIds ?? [], data?.routes ?? []);
    const sortedRoutes = routes.sort((a, b) => a.sortOrder - b.sortOrder);
    const shownRoutes = sortedRoutes.slice(0, NUMBER_OF_ROUTES_SHOWN);
    const remainingRoutes = sortedRoutes.length > NUMBER_OF_ROUTES_SHOWN
        ? sortedRoutes.slice(NUMBER_OF_ROUTES_SHOWN, sortedRoutes.length) : null;

    return (
        <>
            {shownRoutes.map(route => (
                <Tooltip key={route.id} title={route.label}>
                    <Chip key={route.id} label={route.shortName} />
                </Tooltip>
            ))}
            {remainingRoutes && (
                <Tooltip
                    title={(
                        <>
                            {remainingRoutes.map(route => (
                                <Fragment key={route.id}>
                                    {route.shortName}
                                    {' - '}
                                    {route.label}
                                    <br />
                                </Fragment>
                            ))}
                        </>
                    )}
                >
                    <Chip variant="outlined" label={`+${remainingRoutes.length}`} />
                </Tooltip>
            )}
        </>
    );
};

export default React.memo(FilteredRoutesList);
