import Icon from '@mui/material/Icon';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import type GeoJSON from 'geojson';
import React from 'react';

interface WalkingPathProps {
    walkingPath: GeoJSON.LineString;
}

const WalkingPathIcon: React.FunctionComponent<WalkingPathProps> = ({ walkingPath }) => (
    <div title={walkingPath.coordinates.join('; ')}>
        <Icon component={DirectionsWalk} />
    </div>
);

export default WalkingPathIcon;
