import React from 'react';
import IconButton from '@mui/material/IconButton';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';

const ZOOM_LEVELS = [60, 90, 120, 180, 240, 300, 360];

export interface Props {
    zoom: number;
    onChange: (zoom: number) => void;
}

export default class ZoomControl extends React.Component<Props> {
    private getZoomIndex() {
        const { zoom } = this.props;
        return ZOOM_LEVELS.indexOf(zoom);
    }

    private readonly onZoomIn = () => {
        const { onChange } = this.props;
        onChange(ZOOM_LEVELS[this.getZoomIndex() + 1]);
    };

    private readonly onZoomOut = () => {
        const { onChange } = this.props;
        onChange(ZOOM_LEVELS[this.getZoomIndex() - 1]);
    };

    render() {
        const zoomIndex = this.getZoomIndex();

        return (
            <>
                <IconButton onClick={this.onZoomIn} disabled={zoomIndex === ZOOM_LEVELS.length - 1} size="large">
                    <ZoomIn />
                </IconButton>
                <IconButton onClick={this.onZoomOut} disabled={zoomIndex === 0} size="large">
                    <ZoomOut />
                </IconButton>
            </>
        );
    }
}
