import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React from 'react';
import { useIntl } from 'react-intl';

const LocalizedMuiPickersUtilsProvider: React.FC = ({ children }) => {
    const intl = useIntl();

    return (
        <LocalizationProvider locale={intl.locale} dateAdapter={AdapterMoment}>
            {children}
        </LocalizationProvider>
    );
};

export default LocalizedMuiPickersUtilsProvider;
