import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import type { CSSProperties } from '@mui/styles/withStyles';
import * as React from 'react';
import classNames from 'classnames';
import type { RouteBoxFragment } from '../../graphql/graphql-typings';
import { routeBackgroundColor, routeTextColor } from '../../utils';

const useStyles = makeStyles({
    routeBox: {
        minWidth: 50,
        textAlign: 'center',
        margin: 3,
        outline: 'none',
        border: 'none',
        borderRadius: 5,
        height: 25,
        padding: '0 2px',
    },
    routeBoxText: {
        lineHeight: '26px',
        fontSize: 15,
        fontWeight: 500,
    },
});

interface Props {
    route: RouteBoxFragment;
    inactive?: boolean;
    onClick?: (routeId: string) => void;
    className?: string;
}

const RouteBox: React.FC<Props> = ({ onClick, route, inactive, className }) => {
    const classes = useStyles();

    const buttonStyle: CSSProperties = {
        ...routeBackgroundColor(route),
        opacity: inactive ? 0.14 : 1,
    };

    const handleClick = () => {
        onClick?.(route.id);
    };

    return (
        <button type="button" className={classNames(classes.routeBox, className)} style={buttonStyle} onClick={handleClick}>
            <Typography variant="body1" className={classes.routeBoxText} style={routeTextColor(route)}>
                {route.shortName}
            </Typography>
        </button>
    );
};

export default RouteBox;
