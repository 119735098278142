import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import React from 'react';
import type { ScheduleEntry } from '../../../common/interfaces';

interface Props {
    label: React.ReactNode;
    schedule: ScheduleEntry[];
    selected: ScheduleEntry;
    onChange: (entry: ScheduleEntry) => void;
}

const StopSelector: React.FunctionComponent<Props> = ({ label, schedule, selected, onChange }) => {
    const onSelectChange = (event: SelectChangeEvent<ScheduleEntry>) => {
        onChange(event.target.value as ScheduleEntry);
    };

    return (
        <FormControl variant="filled" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select variant="filled" value={selected} onChange={onSelectChange} input={<FilledInput />}>
                {schedule.map(entry => (
                    <MenuItem key={entry.stopSequence} value={entry as any}>
                        {entry.stopSequence}
                        {' '}
                        {entry.stop.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default StopSelector;
