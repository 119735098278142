import React from 'react';
import type { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import type { DialogContentClasses } from '@mui/material/DialogContent';
import DialogContent from '@mui/material/DialogContent';

interface Props {
    handleOk: (value: any) => void;
    handleCancel: () => void;
    open: boolean;
    content?: ReactNode;
    title?: ReactNode;
    contentClasses?: Partial<DialogContentClasses>;
}

export default class ConfirmDialog extends React.PureComponent<Props> {
    render() {
        const { handleCancel, handleOk, open, content, title, contentClasses } = this.props;
        return (
            <Dialog open={open} onClose={handleCancel}>
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent classes={contentClasses}>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary">
                        <FormattedMessage id="confirm-dialog.btn.ok" />
                    </Button>
                    <Button onClick={handleCancel} color="primary">
                        <FormattedMessage id="confirm-dialog.btn.cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
