import type { DateType } from '@date-io/type';
import DialogContent from '@mui/material/DialogContent';
import type { StyleRules, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import type { unitOfTime } from 'moment';
import moment from 'moment';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { BlockActivity } from '../../../../common/interfaces';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import DateTimePicker from '../../components/DateTimePicker';
import { CHANGE_BEGIN_DATE_FOR_TRIP } from '../../graphql/rest';
import { getBeginTime, minMaxMomentAroundServiceDate } from '../../utils';
import SaveButton from './SaveButton';

type ClassKeys = 'flexColumn' | 'flexRow' | 'modifyButtonWrap' | 'textInput';

const styles: StyleRules<{}, ClassKeys> = {
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    textInput: {
        marginRight: 15,
    },
    modifyButtonWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
};

interface Props {
    blockActivity: BlockActivity;
    activityModificationCompleted: () => void;
}

interface State {
    modifiedBeginTime: DateType | null;
}

class TripBeginTimeModificationDialog extends React.PureComponent<Props & WithStyles<ClassKeys> & WrappedComponentProps, State> {
    constructor(props: Props & WithStyles<ClassKeys> & WrappedComponentProps) {
        super(props);
        this.state = { modifiedBeginTime: moment(getBeginTime(props.blockActivity)) };
    }

    private readonly handleModifiedBeginTimeChange = (value: DateType | null) => {
        this.setState({ modifiedBeginTime: value });
    };

    private getBeginTimeOffset(unitOfTime: unitOfTime.Diff) {
        const { blockActivity } = this.props;
        const { modifiedBeginTime } = this.state;

        if (modifiedBeginTime && blockActivity.trip !== null) {
            const { trip } = blockActivity;
            const originalTimes = trip.originalTimes || trip.modifiedTimes;

            if (originalTimes) {
                return moment(modifiedBeginTime).diff(moment(originalTimes.firstStop.departureTime), unitOfTime);
            }
        }

        return 0;
    }

    render() {
        const { classes, intl, blockActivity, activityModificationCompleted } = this.props;
        const { serviceDate } = blockActivity;
        const beginTime = getBeginTime(blockActivity);
        const { modifiedBeginTime } = this.state;
        const offset = this.getBeginTimeOffset('minutes');
        const mutationOptions = {
            variables: {
                tripKey: blockActivity.trip!.tripKey,
                input: { type: 'relative-offset', offset: this.getBeginTimeOffset('seconds') },
            },
        };

        return (
            <>
                <DialogContent>
                    <div className={classes.flexRow}>
                        <div className={classes.flexColumn}>
                            <DateTimePicker
                                className={classes.textInput}
                                disabled
                                fullWidth
                                value={moment(beginTime)}
                                label={<FormattedMessage id="blocks-overview.block-activity-actions.original-begin-time.label" />}
                            />
                        </div>
                        <div className={classes.flexColumn}>
                            <DateTimePicker
                                fullWidth
                                {...minMaxMomentAroundServiceDate(serviceDate)}
                                value={modifiedBeginTime}
                                onChange={this.handleModifiedBeginTimeChange}
                                label={<FormattedMessage id="blocks-overview.block-activity-actions.modified-begin-time.label" />}
                            />
                        </div>
                    </div>
                    <div className={classes.flexRow}>
                        <div className={classes.flexColumn}>
                            <TextField
                                label={<FormattedMessage id="blocks-overview.block-activity-actions.offset.label" />}
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={intl.formatMessage(
                                    { id: 'blocks-overview.block-activity-actions.offset.value' },
                                    { prefix: offset > 0 ? '+' : '', offset },
                                )}
                            />
                        </div>
                    </div>
                </DialogContent>
                <ContainedDialogActions>
                    <SaveButton
                        disabled={modifiedBeginTime === null || !modifiedBeginTime.isValid()}
                        blockActivity={blockActivity}
                        graphQlQuery={CHANGE_BEGIN_DATE_FOR_TRIP}
                        mutationOptions={mutationOptions}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                </ContainedDialogActions>
            </>
        );
    }
}

export default withStyles(styles)(injectIntl(TripBeginTimeModificationDialog));
