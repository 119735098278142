import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { useState } from 'react';
import type { BlockActivity } from '../../../../common/interfaces';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import { CHANGE_BLOCK } from '../../graphql/rest';
import { convertBlockIdSelection, getBlockKey, getDriverBlockId, getVehicleBlockId } from '../../utils';
import CompoundBlockSelector from '../BlockSelectors/CompoundBlockSelector';
import type { BlockIdSelection } from '../BlockSelectors/DriverBlockSelector';
import { AUTOMATIC } from '../BlockSelectors/DriverBlockSelector';
import SaveButton from './SaveButton';

interface Props {
    blockActivity: BlockActivity;
    activityModificationCompleted: () => void;
}

const BlockActivityBlockChangeDialog: React.FunctionComponent<Props> = (props) => {
    const { blockActivity, activityModificationCompleted } = props;

    const driverBlockIdInitialState = getDriverBlockId(blockActivity) ?? AUTOMATIC;
    const [driverBlockId, setDriverBlockId] = useState<BlockIdSelection>(driverBlockIdInitialState);
    const [vehicleBlockId, setVehicleBlockId] = useState(getVehicleBlockId(blockActivity));

    const mutationOptions = {
        variables: {
            blockActivityKey: blockActivity.activityKey,
            input: { type: 'change-block', driverBlockId: convertBlockIdSelection(driverBlockId), vehicleBlockId },
        },
    };

    const additionalBlockKey = getBlockKey('VEHICLE', vehicleBlockId, blockActivity.serviceDate);

    return (
        <>
            <DialogContent>
                <CompoundBlockSelector
                    vehicleBlockId={vehicleBlockId}
                    driverBlockId={driverBlockId}
                    serviceDate={blockActivity.serviceDate}
                    onVehicleBlockIdChange={setVehicleBlockId}
                    onDriverBlockIdChange={setDriverBlockId}
                />
            </DialogContent>
            <ContainedDialogActions>
                <SaveButton
                    blockActivity={blockActivity}
                    graphQlQuery={CHANGE_BLOCK}
                    mutationOptions={mutationOptions}
                    activityModificationCompleted={activityModificationCompleted}
                    additionalBlockKey={additionalBlockKey}
                />
            </ContainedDialogActions>
        </>
    );
};
export default BlockActivityBlockChangeDialog;
