import Typography from '@mui/material/Typography';
import type { SystemProps } from '@mui/system/Box';
import React from 'react';

const FormSectionTitle: React.FC<SystemProps> = ({ children, ...props }) => (
    <Typography component="h2" sx={{ fontSize: 18, fontWeight: 400, letterSpacing: 1, textTransform: 'uppercase' }} {...props}>
        {children}
    </Typography>
);

export default FormSectionTitle;
