import Button from '@mui/material/Button';
import lightGreen from '@mui/material/colors/lightGreen';
import type { Theme } from '@mui/material/styles';
import type { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import DetailedItem from '@realcity/web-frame/lib/components/DetailedItem';
import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, FormattedTime, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import type { BlockKey, Notification, ResolvedCoreRealtimeVehicle } from '../../../../common/interfaces';
import Delay from '../../components/Delay';
import EditAcknowledgementDialog from './EditAcknowledgementDialog';
import NotificationAcknowledgeTooltip from './NotificationAcknowledgeTooltip';
import { getNotificationTitle } from './utils';

const renderVehicleLink = (vehicle: ResolvedCoreRealtimeVehicle) => (
    <Link to={`/map/vehicles/${vehicle.vehicleId}`}>{vehicle.licensePlate}</Link>
);

const renderBlockLink = (vehicleBlockId: ReactNode, vehicleBlockKey: BlockKey) => (
    <HashLink to={`/blocks#${vehicleBlockKey}`}>{vehicleBlockId}</HashLink>
);

const NotificationItemBody: React.FunctionComponent<{ notification: Notification }> = ({
    notification: { vehicle, vehicleBlockId, vehicleBlockKey },
}) => (
    <>
        {vehicle ? renderVehicleLink(vehicle) : '-'}
        &nbsp;/&nbsp;
        {defaultString(vehicle?.driverName)}
        &nbsp;/&nbsp;
        {vehicleBlockKey && vehicleBlockId
            ? renderBlockLink(<FormattedMessage id="block.id" values={{ blockId: vehicleBlockId }} />, vehicleBlockKey)
            : '-'}
    </>
);

export interface Props {
    notification: Notification;
}

interface State {
    editingAcknowledgeAnchorEl: HTMLElement | null;
}

type ClassKeys = 'acknowledgeButton' | 'acknowledged' | 'checkbox' | 'nonAcknowledged';

const styles: StyleRulesCallback<Theme, {}, ClassKeys> = theme => ({
    checkbox: {
        marginTop: -8,
        marginRight: -14,
    },
    acknowledgeButton: {
        height: 20,
        margin: 6,
        paddingTop: 0,
        paddingBottom: 0,
        color: theme.palette.primary.contrastText,
    },
    acknowledged: {
        backgroundColor: lightGreen[600],
    },
    nonAcknowledged: {
        backgroundColor: theme.palette.error.dark,
    },
});

class NotificationItem extends React.PureComponent<Props & WithStyles<ClassKeys> & WrappedComponentProps, State> {
    state: State = {
        editingAcknowledgeAnchorEl: null,
    };

    private readonly editAcknowledge = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ editingAcknowledgeAnchorEl: e.currentTarget });
    };

    private readonly stopEditingAcknowledge = () => {
        this.setState({ editingAcknowledgeAnchorEl: null });
    };

    render() {
        const { classes, notification, intl } = this.props;
        const { id, vehicle, created, acknowledged, acknowledgeMessage } = notification;
        const { editingAcknowledgeAnchorEl } = this.state;

        const buttonClassName = classNames(classes.acknowledgeButton, acknowledged ? classes.acknowledged : classes.nonAcknowledged);
        const topNotes = (
            <>
                <EditAcknowledgementDialog
                    id={id}
                    defaultMessage={acknowledgeMessage || ''}
                    open={editingAcknowledgeAnchorEl !== null}
                    anchorEl={editingAcknowledgeAnchorEl}
                    onClose={this.stopEditingAcknowledge}
                />

                <FormattedTime value={created} />
                <NotificationAcknowledgeTooltip message={acknowledgeMessage} date={acknowledged}>
                    <Button size="small" onClick={this.editAcknowledge} className={buttonClassName}>
                        {acknowledged ? <FormattedMessage id="notifications.acknowledged" /> : <FormattedMessage id="notifications.new" />}
                    </Button>
                </NotificationAcknowledgeTooltip>
            </>
        );

        const bottomNotes = vehicle && <Delay delay={vehicle.delay} delayStatus={vehicle.delayStatus} />;

        return (
            <DetailedItem
                title={getNotificationTitle(intl, notification)}
                topNotes={topNotes}
                bottomNotes={bottomNotes}
                selected={editingAcknowledgeAnchorEl !== null}
            >
                <NotificationItemBody notification={notification} />
            </DetailedItem>
        );
    }
}

function defaultString(text?: string | null) {
    return text || '-';
}

export default withStyles(styles)(injectIntl(NotificationItem));
