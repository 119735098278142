import React, { useState } from 'react';
import StaticDateTimePicker from '@mui/lab/StaticDateTimePicker';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { DateType } from '@date-io/type';
import type { DateTimePickerProps } from '@mui/lab/DateTimePicker';
import type { Moment } from 'moment';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        width: 320,
        padding: 0,
        maxHeight: 474,
        overflowY: 'hidden',
        '& > div > div > div:last-child': {
            minHeight: 358,
        },
        '& .PrivateDateTimePickerToolbar-penIcon': {
            display: 'none',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.secondary.main,
        },
        '& .MuiPickersDay-root': {
            lineHeight: '32px',
        },
        '& .MuiPickersDay-today:not(.Mui-selected)': {
            border: 0,
            '&:not(.Mui-disabled)': {
                color: theme.palette.primary.main,
            },
        },
        '& .PrivatePickersToolbar-root, & .MuiTabs-root': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiTypography-root, & .MuiTab-textColorPrimary': {
                color: 'rgba(255, 255, 255, 0.4)',
                '&.Mui-selected': {
                    color: '#fff',
                },
            },
        },
    },
}));

export interface Props extends Omit<DateTimePickerProps<Moment>, 'onChange' | 'open'> {
    open: boolean;
    onDialogClose: (value: DateType | null) => void;
}

const DateTimePickerDialog: React.FC<Props> = ({ open, onDialogClose, value, ...rest }) => {
    const classes = useStyles();
    const [currentValue, setCurrentValue] = useState<DateType | null>(null);

    const onChange = (date: DateType | null) => {
        setCurrentValue(date);
    };

    const handleOk = () => {
        onDialogClose(currentValue);
    };

    const handleCancel = () => {
        onDialogClose(null);
    };

    return (
        <ConfirmDialog
            open={open}
            handleOk={handleOk}
            handleCancel={handleCancel}
            content={(
                <StaticDateTimePicker
                    displayStaticWrapperAs="mobile"
                    toolbarTitle={null}
                    onChange={onChange}
                    value={value}
                    openTo="hours"
                    {...rest}
                />
            )}
            contentClasses={{
                root: classes.dialogContent,
            }}
        />
    );
};

export default DateTimePickerDialog;
