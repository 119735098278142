import * as React from 'react';
import Search from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
import type { InputProps } from '@mui/material/Input';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export interface Props extends Omit<InputProps, 'onChange' | 'ref'> {
    onChange: (text: string) => void;
}

class SearchInput extends React.PureComponent<Props> {
    private readonly onChange = (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { onChange } = this.props;

        onChange(e.currentTarget.value);
    };

    private readonly clear = () => {
        const { onChange } = this.props;

        onChange('');
    };

    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                startAdornment={(
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                )}
                endAdornment={(
                    <InputAdornment position="end">
                        <IconButton onClick={this.clear}>
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                )}
            />
        );
    }
}

export default SearchInput;
