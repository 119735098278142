import type { ComponentProps } from 'react';
import React from 'react';
import { FormattedTime } from 'react-intl';

type Props = ComponentProps<typeof FormattedTime>;

const FormattedDateTime: React.FunctionComponent<Props> = ({ children, ...rest }) => (
    <FormattedTime year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" {...rest}>
        {children}
    </FormattedTime>
);

export default FormattedDateTime;
