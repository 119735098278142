import classNames from 'classnames';
import React from 'react';
import { calculateWidth } from './functions';

interface Props {
    begin: Date;
    end: Date;
    zoom: number;
}

const STRIPE_FREQUENCY = 5 * 60 * 1000; // 5 minutes

const DateFrameStripes: React.FunctionComponent<Props> = ({ begin, end, zoom }) => {
    const initialLength = STRIPE_FREQUENCY - begin.valueOf() % STRIPE_FREQUENCY;

    const stripes = [];
    let from = new Date(begin);
    let to = new Date(begin.valueOf() + initialLength);
    while (to < end) {
        const classes = ['date-frame__stripe', `date-frame__stripe--${from.getHours() % 2 === 0 ? 'odd' : 'even'}`];
        if (to.getMinutes() === 0) {
            classes.push('date-frame__stripe--hour');
        } else if (to.getMinutes() % 15 === 0) {
            classes.push('date-frame__stripe--quarter');
        }
        stripes.push(<div className={classNames(classes)} style={{ width: calculateWidth(begin, from, to, zoom) }} key={from.valueOf()} />);

        from = to;
        to = new Date(to.valueOf() + STRIPE_FREQUENCY);
    }

    // Show the last one
    stripes.push(
        <div
            className="date-frame__stripe date-frame__stripe--no-border"
            style={{ width: calculateWidth(begin, from, end, zoom) }}
            key={from.valueOf()}
        />,
    );

    return <div className="date-frame__stripes">{stripes}</div>;
};

export default React.memo(DateFrameStripes);
