import type MapboxGL from 'mapbox-gl';
import React from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import { lineString, multiPoint } from '../../utils/geojson';
import type { DisplayedTripFragment } from '../../graphql/graphql-typings';
import { STOPS_MAP_LAYER, TRIP_MAP_LAYER } from '../../pages/Map';

const LINE_PAINT: MapboxGL.LinePaint = {
    'line-width': 3,
    'line-color': '#0090ff',
};

const STOPS_PAINT: MapboxGL.CirclePaint = {
    'circle-radius': 6,
    'circle-color': '#0090ff',
};

interface Props {
    trip: DisplayedTripFragment;
}

const TripLayer: React.FunctionComponent<Props> = ({ trip }) => (
    <>
        {trip.shape && (
            <GeoJSONLayer
                data={lineString(trip.shape.map(shapePoint => shapePoint.location.coordinates))}
                linePaint={LINE_PAINT}
                before={TRIP_MAP_LAYER}
            />
        )}
        <GeoJSONLayer
            data={multiPoint(trip.actualSchedule.map(stopTime => stopTime.stop.location.coordinates))}
            circlePaint={STOPS_PAINT}
            before={STOPS_MAP_LAYER}
        />
    </>
);

export default TripLayer;
