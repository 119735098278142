import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitAlertStatus } from '../../graphql/graphql-typings';

interface NonActiveStatus {
    type: TransitAlertStatus.Inactive | TransitAlertStatus.Stale;
}

interface ActiveStatus {
    type: TransitAlertStatus.Active;
    timing: 'FUTURE' | 'PAST' | 'PRESENT';
}

export type TransitAlertStatusTextType = ActiveStatus | NonActiveStatus;

export interface Props {
    value: TransitAlertStatusTextType;
}

const TransitAlertStatusText: React.FC<Props> = ({ value }) => {
    switch (value.type) {
        case TransitAlertStatus.Active:
            return getActiveMessage(value.timing);
        case TransitAlertStatus.Inactive:
            return <FormattedMessage id="alerts.status.inactive" />;
        case TransitAlertStatus.Stale:
            return <FormattedMessage id="alerts.status.stale" />;
    }
};

function getActiveMessage(timing: ActiveStatus['timing']) {
    switch (timing) {
        case 'PAST':
            return <FormattedMessage id="alerts.status.expired" />;
        case 'PRESENT':
            return <FormattedMessage id="alerts.status.active" />;
        case 'FUTURE':
            return <FormattedMessage id="alerts.status.planned" />;
    }
}

export default TransitAlertStatusText;
