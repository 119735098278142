import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { GtfsDriver } from '../../../../common/interfaces';
import { PopupRow } from '../../components/BlockOverviewPopup';
import { sendDriverAssignment as sendDriverAssignmentAction } from '../../redux/actions';
import type { DriverAssignments, DriverBlock, GtfsDrivers } from '../../redux/state';
import type AppState from '../../redux/state';
import { EMPTY_AUTOCOMPLETE_OPTION, getGtfsEntityOption } from '../../utils';
import AssignmentDialog from './AssignmentDialog';

interface Props {
    driverBlock: DriverBlock;
    driverAssignments: DriverAssignments;
    gtfsDrivers: GtfsDrivers;
    sendDriverAssignment: typeof sendDriverAssignmentAction;
}

const getActualDriver = (gtfsDriver: GtfsDriver) => getGtfsEntityOption(gtfsDriver, 'name');

const RawDriverEditor: React.FunctionComponent<Props> = ({
    driverBlock: { blockId, blockServiceDate },
    driverAssignments,
    gtfsDrivers,
    sendDriverAssignment,
}) => {
    const driverAssignment = driverAssignments.list.find(x => x.blockId === blockId);
    const driver = driverAssignment ? gtfsDrivers.list.find(d => d.id === driverAssignment.resourceId) : null;

    return (
        <PopupRow label={<FormattedMessage id="blocks-overview.block-info.planned-driver" />} key={driver ? driver.id : ''}>
            {driver?.name}
            <AssignmentDialog
                options={gtfsDrivers.list.map(getActualDriver).sort((a, b) => a.label.localeCompare(b.label))}
                title={<FormattedMessage id="blocks-overview.block-info.planned-driver.select" />}
                blockId={blockId}
                serviceDate={blockServiceDate}
                defaultValue={driver ? getActualDriver(driver) : EMPTY_AUTOCOMPLETE_OPTION}
                sendAssignment={sendDriverAssignment}
            />
        </PopupRow>
    );
};

export default connect(({ gtfsDrivers, driverAssignments }: AppState) => ({ driverAssignments, gtfsDrivers }), {
    sendDriverAssignment: sendDriverAssignmentAction,
})(RawDriverEditor);
