import React from 'react';
import Chip from '@mui/material/Chip';
import type { TransitAlertStatusTextType } from './TransitAlertStatusText';
import TransitAlertStatusText from './TransitAlertStatusText';

export interface Props {
    backgroundColor: string;
    label: TransitAlertStatusTextType;
}

const TransitAlertStatusChip: React.FC<Props> = ({ backgroundColor, label }) => (
    <Chip
        label={<TransitAlertStatusText value={label} />}
        sx={{
            height: 24,
            lineHeight: '24px',
            color: 'white',
            fontFamily: 'inherit',
            backgroundColor,
        }}
    />
);

export default TransitAlertStatusChip;
