import type { ApolloError, MutationResult } from '@apollo/client';
import type { ServerParseError } from '@apollo/client/link/http';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MutationProgressDialog from '@realcity/web-frame/lib/components/MutationProgressDialog';

interface Props {
    mutationResult: MutationResult;
    finishedMessage: React.ReactNode;
}

const TransitAlertProgress: React.FC<Props> = ({ mutationResult, finishedMessage }) => (
    <MutationProgressDialog
        mutationResult={mutationResult}
        successMessage={finishedMessage}
        redirectLink="/alerts"
        errorMessage={customErrorMessage}
    />
);

function customErrorMessage(error: ApolloError) {
    return (error.networkError as ServerParseError | null)?.statusCode === 413 && (
        <FormattedMessage id="alert.alert-too-big-error" />
    );
}

export default TransitAlertProgress;
