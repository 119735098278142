import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import React from 'react';
import PageContent from './PageContent';

interface Props {
    title: ReactNode;
}

export const PageHeader: React.FC<Props> = ({ title, children }) => (
    <PageContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h1">
                {title}
            </Typography>

            <div>
                {children}
            </div>
        </Box>
    </PageContent>
);

export default PageHeader;
