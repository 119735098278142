import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import type { TripKey, VehicleId } from '../../../../common/interfaces';
import { VehicleListPanel } from './VehicleListPanel';
import type { UrlParams } from '../../utils/query-params-util';
import { asSingleValue } from '../../utils/query-params-util';
import VehiclePanel from './VehiclePanel';
import TripPanel from './TripPanel';
import AllVehiclesLayer from './AllVehiclesLayer';
import useQueryParamReader from '../../hooks/useQueryParamReader';
import useQueryParamWriter from '../../hooks/useQueryParamWriter';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        bottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: 0,
        '& h2:first-child': {
            marginTop: 0,
        },
    },
});

const MapSidebar: React.FC = () => {
    const classes = useStyles();

    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    const { tripKey, vehicleId, vehiclesListOpen } = parseMapQueryParams(readQueryParam<MapDetailsQueryParams>());

    const tripOrVehicleSelected = !!(tripKey || vehicleId);

    const onToggleVehiclesList = () => {
        if (vehiclesListOpen) {
            writeQueryParams({ open: null });
        } else {
            writeQueryParams({ open: 'true' });
        }
    };

    const onClosePanel = () => {
        writeQueryParams({ open: null });
    };

    return (
        <div className={classes.root}>
            <VehicleListPanel open={vehiclesListOpen} onToggle={onToggleVehiclesList} />

            {tripKey && <TripPanel tripKey={tripKey} onClose={onClosePanel} />}

            {vehicleId && <VehiclePanel vehicleId={vehicleId} onClose={onClosePanel} />}

            {!tripOrVehicleSelected && <AllVehiclesLayer />}
        </div>
    );
};

type MapDetailsQueryParams = 'open' | 'trip-key' | 'vehicle-id';

interface MapDetailsParams {
    tripKey?: TripKey;
    vehicleId?: VehicleId;
    vehiclesListOpen: boolean;
}

function parseMapQueryParams(urlParams: UrlParams<MapDetailsQueryParams>): MapDetailsParams {
    return {
        tripKey: asSingleValue(urlParams['trip-key']) as TripKey,
        vehicleId: asSingleValue(urlParams['vehicle-id']) as VehicleId,
        vehiclesListOpen: Boolean(urlParams.open),
    };
}

export default MapSidebar;
