import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import type { CoreRealtimeVehicle } from '../../../common/interfaces';

export function getDelayText(delay: number | null, delayStatus: CoreRealtimeVehicle['delayStatus']) {
    if (delay === null || !delayStatus) {
        return null;
    }

    const minutes = delay / 60;
    const roundedMinutes = Math.round(minutes * 2.0) / 2.0;
    let prefix = '';
    if (delayStatus === 'WAITING') {
        prefix = '*';
    } else if (roundedMinutes > 0) {
        prefix = '+';
    }

    return `${prefix}${roundedMinutes.toFixed(1)}`;
}

const useStyles = makeStyles({
    EARLY: {
        color: '#e30003',
    },
    ON_TIME: {
        color: '#66CD00',
    },
    LATE: {
        color: '#0f92e3',
    },
    WAITING: {
        color: '#333333',
    },
});

interface Props {
    delayStatus: CoreRealtimeVehicle['delayStatus'];
    delay: number | null;
}

const Delay: React.FunctionComponent<Props> = ({ delay, delayStatus }) => {
    const classes = useStyles();

    if (delay === null || !delayStatus) {
        return null;
    }

    return (
        <span className={classes[delayStatus]}>
            {getDelayText(delay, delayStatus)}
        </span>
    );
};

export default Delay;
