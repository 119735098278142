import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import React from 'react';

interface Props {
    link: string;
    external?: boolean;
}

const LinkButton: React.FC<Props> = ({ link, external, children }) => external ? (
    <Button target="_blank" href={link} color="primary">
        {children}
    </Button>
) : (
    <Button component={Link} to={link} color="primary">
        {children}
    </Button>
);

export default LinkButton;
