import type GeoJSON from 'geojson';
import React from 'react';

interface FormattedCoordinateProps {
    position: GeoJSON.Position;
}

const FormattedCoordinate: React.FunctionComponent<FormattedCoordinateProps> = ({ position }) => (
    <>
        {position.join(', ')}
    </>
);

export default FormattedCoordinate;
