import { useCallback, useRef, useState } from 'react';

const useIntersectionObserver = (intersectionHandler: (entries: IntersectionObserverEntry[]) => void) => {
    const handlerRef = useRef(intersectionHandler);
    handlerRef.current = intersectionHandler;

    const [observer] = useState(() => new IntersectionObserver((entries) => {
        handlerRef.current(entries);
    }));

    const ref = useCallback((newRef: HTMLDivElement | null) => {
        if (newRef === null) {
            observer.disconnect();
        } else {
            observer.observe(newRef);
        }
    }, [observer]);

    return {
        ref,
    };
};

export default useIntersectionObserver;
