import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { FormattedTime } from 'react-intl';

interface Props {
    message: string | null;
    date: string | null;
    children: React.ReactElement;
}

const useStyles = makeStyles({
    tooltip: {
        fontSize: 14,
        minWidth: 160,
    },
});

const NotificationAcknowledgeTooltip: React.FunctionComponent<Props> = ({ date, message, children }) => {
    const classes = useStyles();

    if (!message) {
        return children;
    }

    return (
        <Tooltip
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            title={(
                <>
                    {date && <FormattedTime value={date} />}
                    <div>{message}</div>
                </>
            )}
        >
            {children}
        </Tooltip>
    );
};

export default NotificationAcknowledgeTooltip;
