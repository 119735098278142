import messagesEn from './en.json';
import messagesHu from './hu.json';

type Messages = typeof messagesEn & typeof messagesHu;
export type MessageKeys = keyof Messages;

// force TypeScript to check if every id is present in every language
const hu: Messages = messagesHu;
const en: Messages = messagesEn;

const messages = {
    hu,
    en,
};

declare module 'react-intl/src/components/message' {
    interface Props {
        id: MessageKeys;
    }
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace FormatjsIntl {
        interface Message {
            ids: MessageKeys;
        }
    }
}

export default messages;
