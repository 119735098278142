import type { FitBounds } from 'react-mapbox-gl/lib/map';
import type { FitBoundsOptions } from 'mapbox-gl';
import { getBounds } from '../../utils/geojson';
import type { DisplayedTripFragment, DisplayedVehicleFragment } from '../../graphql/graphql-typings';

export const FIT_BOUNDS_OPTIONS: FitBoundsOptions = {
    padding: {
        left: 440,
        bottom: 20,
        top: 20,
        right: 20,
    },
    maxZoom: 13,
};

export function getBoundsForTripAndVehicle(
    trip: DisplayedTripFragment | null,
    vehicles: DisplayedVehicleFragment[] | null,
): FitBounds | null {
    const bounds = [];
    if (trip) {
        bounds.push(...trip.actualSchedule.map(stop => stop.stop.location.coordinates));
        if (trip.shape) {
            bounds.push(...trip.shape.map(point => point.location.coordinates));
        }
    }
    if (vehicles && vehicles.length > 0) {
        vehicles.forEach((vehicle) => {
            if (vehicle.position) {
                bounds.push(vehicle.position.coordinates);
            }
        });
    }

    if (bounds.length > 0) {
        return getBounds(bounds);
    }

    return null;
}
