import React from 'react';
import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
import DurationUnitFormat from 'intl-unofficial-duration-unit-format';
import moment from 'moment';
import type { Moment } from 'moment';
import type { TransitAlertFormValue } from './TransitAlertFormValue';
import DateTimePicker from '../../components/DateTimePicker';
import FormSectionTitle from './FormSectionTitle';

const ZERO_SECOND = { second: 0, millisecond: 0 };

const PeriodInput: React.FC = () => {
    const intl = useIntl();

    const { control, formState: { errors } } = useFormContext<TransitAlertFormValue>();

    const { field: startTimeField } = useController<TransitAlertFormValue, 'startTime'>({
        name: 'startTime',
        rules: { required: true, validate: value => moment(value).isValid() },
        control,
    });

    const { field: endTimeField } = useController<TransitAlertFormValue, 'endTime'>({
        name: 'endTime',
        rules: { validate: value => !value || (moment(value).isValid() && value > startTimeField.value) },
        control,
    });

    const formattedDurationText = formatDuration(intl.locale, moment(startTimeField.value), moment(endTimeField.value))
        || intl.formatMessage({ id: 'alerts.end-time.further-notice' });

    return (
        <>
            <FormSectionTitle margin="16px 0">
                <FormattedMessage id="alert.period" />
            </FormSectionTitle>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item lg={5} xs={12}>
                    <DateTimePicker
                        label={<FormattedMessage id="alert.start-time" />}
                        {...startTimeField}
                        onChange={(value) => {
                            startTimeField.onChange(value?.toISOString());
                        }}
                        onClose={startTimeField.onBlur}
                        fullWidth
                        error={Boolean(errors.startTime)}
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={5} xs={12}>
                    <DateTimePicker
                        label={<FormattedMessage id="alert.end-time" />}
                        {...endTimeField}
                        onChange={(value) => {
                            endTimeField.onChange(value?.toISOString());
                        }}
                        minDateTime={moment(startTimeField.value)}
                        fullWidth
                        error={Boolean(errors.endTime)}
                        variant="standard"
                    />
                </Grid>
                <Grid item lg={2} xs={12}>
                    <TextField
                        value={formattedDurationText}
                        label={<FormattedMessage id="alert.interval" />}
                        variant="standard"
                        disabled
                        fullWidth
                        sx={{
                            '&, & .MuiInput-root': {
                                height: '100%',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

function formatDuration(locale: string, startTime: Moment | null, endTime: Moment | null): string | null {
    if (!startTime || !startTime.isValid() || !endTime || !endTime.isValid()) {
        return null;
    }

    const startMinutes = startTime.set(ZERO_SECOND);
    const endMinutes = endTime.set(ZERO_SECOND);
    const timeDifferenceSeconds = moment.duration(endMinutes.diff(startMinutes)).asSeconds();

    const durationFormatter = new DurationUnitFormat(locale, { format: '{days} {hours} {minutes}' });
    return durationFormatter.format(timeDifferenceSeconds);
}

export default PeriodInput;
