import Ticker from '@realcity/web-frame/lib/components/Refresher/Ticker';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    fetchNotifications as fetchNotificationsAction,
    resetCurrentNotificationAcknowledgement as resetCurrentNotificationAcknowledgementAction,
} from '../../redux/actions';
import type { AppState, CurrentNotificationAcknowledge } from '../../redux/state';
import CustomSnackbar from '../../components/CustomSnackbar';
import DesktopNotifications from './DesktopNotifications';
import NotificationsSidebar from './NotificationsSidebar';

interface Props {
    showNotifications: boolean;
    fetchNotifications: typeof fetchNotificationsAction;
    currentNotificationAcknowledge: CurrentNotificationAcknowledge;
    resetCurrentNotificationAcknowledgement: typeof resetCurrentNotificationAcknowledgementAction;
}

const ClosableNotificationsSidebar: React.FunctionComponent<Props> = (props: Props) => {
    const { fetchNotifications, showNotifications, currentNotificationAcknowledge, resetCurrentNotificationAcknowledgement } = props;

    return (
        <>
            <DesktopNotifications />
            <CustomSnackbar variant="error" open={currentNotificationAcknowledge.error} onClose={resetCurrentNotificationAcknowledgement}>
                <FormattedMessage id="notifications.acknowledge.save-fail" />
            </CustomSnackbar>
            <Ticker interval={10000} onTick={fetchNotifications} initial />
            {showNotifications && <NotificationsSidebar />}
        </>
    );
};

export default connect(
    ({ showNotifications, currentNotificationAcknowledge }: AppState) => ({
        showNotifications,
        currentNotificationAcknowledge,
    }),
    {
        fetchNotifications: fetchNotificationsAction,
        resetCurrentNotificationAcknowledgement: resetCurrentNotificationAcknowledgementAction,
    },
)(ClosableNotificationsSidebar);
