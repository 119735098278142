import type { IntlShape } from 'react-intl';
import type { Notification, NotificationType } from '../../../../common/interfaces';
import type { MessageKeys } from '../../translations';

export function getNotificationTitle(intl: IntlShape, notification: Notification): string {
    if (notification.message !== null) {
        return notification.message;
    }

    return intl.formatMessage({ id: getNotificationTypeMessageId(notification.type) });
}

export function getNotificationTypeMessageId(type: NotificationType): MessageKeys {
    switch (type) {
        case 'PATH_DEVIATION':
            return 'notifications.type.path-deviation';
        case 'DRIVER_MESSAGE':
            return 'notifications.type.driver-message';
        case 'MULTIPLE_VEHICLES_ON_BLOCK':
            return 'notifications.type.multiple-vehicles-on-block';
        case 'MESSAGE_ACKNOWLEDGED':
            return 'notifications.type.message-acknowledged';
        case 'OVERLAPPING_ACTIVITIES':
            return 'notifications.type.overlapping-activities';
        case 'BLOCK_VERSION_MISMATCH':
            return 'notifications.type.block-version-mismatch';
        case 'MISSING_VEHICLE':
            return 'notifications.type.missing-vehicle';
        case 'DELAYED_TRIP_DEPARTURE':
            return 'notifications.type.delayed-trip-departure';
        case 'TRIP_NOT_STARTED':
            return 'notifications.type.trip-not-started';
        default:
            return 'notifications.type.unknown';
    }
}

export function notificationContainsText(notification: Notification, text: string): boolean {
    function containsText(str: string | null | undefined): boolean {
        return Boolean(str?.toLowerCase().includes(text.toLowerCase()));
    }

    if (containsText(notification.message) || notification.vehicleBlockId === text) {
        return true;
    }
    if (notification.vehicle && (containsText(notification.vehicle.licensePlate) || containsText(notification.vehicle.driverName))) {
        return true;
    }
    return false;
}
