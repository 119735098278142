import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import uniq from 'lodash/uniq';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { ValueType } from 'react-select/lib/types';
import makeStyles from '@mui/styles/makeStyles';
import type { ResolvedCoreRealtimeVehicle } from '../../../../common/interfaces';
import { Roles } from '../../constants';
import { fetchVehicles as fetchVehiclesAction } from '../../redux/actions';
import AutoComplete from '../../components/AutoComplete';
import CustomSnackbar from '../../components/CustomSnackbar';
import type { AppState, Vehicles } from '../../redux/state';
import { routeBackgroundColor, routeTextColor } from '../../utils';
import RouteVehiclesSelector from './RouteVehiclesSelector';
import { asList } from '../../utils/query-params-util';
import type { DispatcherMessageInput } from '../../graphql/graphql-typings';
import { useSendMessageMutation } from '../../graphql/graphql-typings';
import PageTitle from '../../components/PageTitle';
import useQueryParamWriter from '../../hooks/useQueryParamWriter';
import useQueryParamReader from '../../hooks/useQueryParamReader';

const getVehicleIds = (vehicles: ResolvedCoreRealtimeVehicle[]) => vehicles
    .map((vehicle: ResolvedCoreRealtimeVehicle) => vehicle.vehicleId);
const getOptionValue = (vehicleId: string) => vehicleId;
const getVehicleLabel = (vehicle: ResolvedCoreRealtimeVehicle) => vehicle.licensePlate;

const useStyles = makeStyles({
    autoComplete: { backgroundColor: '#E7E7E7' },
    selectorForm: { display: 'flex' },
    messageForm: { margin: 10, alignItems: 'flex-start', flexGrow: 1 },
    routeSelector: { flexWrap: 'wrap', width: 350, marginTop: 10 },
    routeSelectorContainer: { margin: 10 },
    shortNameAvatar: {
        borderRadius: '16px 0 0 16px',
        width: 50,
        height: 32,
        fontSize: '1rem',
    },
});

interface Props {
    vehicles: Vehicles;
    roles: string[];
    fetchVehicles: typeof fetchVehiclesAction;
}

export interface MessageUrlParams {
    recipient: string[];
}

const SendMessageForm: React.FC<Props> = ({ vehicles, roles, fetchVehicles }) => {
    const classes = useStyles();
    const intl = useIntl();
    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    useEffect(() => {
        fetchVehicles();
    }, [fetchVehicles]);

    const selectedVehicleIds = asList(readQueryParam<keyof MessageUrlParams>().recipient);

    const [messageText, setMessageText] = useState('');
    const [executeSendMessage, currentMessage] = useSendMessageMutation();

    const updateUrlWithSelected = (selected: string[]) => {
        writeQueryParams({ recipient: selected });
    };

    const changeVehicleSelection = (newValues: ValueType<string>) => {
        updateUrlWithSelected(newValues as string[]);
    };

    const onSelectAllVehicles = () => {
        const allVehicleIds = getVehicleIds(vehicles.list.vehicles);
        changeVehicleSelection(allVehicleIds);
    };

    const onVehiclesSelected = (selectedVehicles: ResolvedCoreRealtimeVehicle[]) => {
        const uniqueIds = uniq([...getVehicleIds(selectedVehicles), ...selectedVehicleIds]);
        changeVehicleSelection(uniqueIds);
    };

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageText(event.target.value);
    };

    const onSendMessage = () => {
        if (messageText === '' || selectedVehicleIds.length === 0) {
            return;
        }

        const message: DispatcherMessageInput = {
            message: messageText,
            vehicles: selectedVehicleIds,
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeSendMessage({ variables: { message } });

        setMessageText('');
        changeVehicleSelection([]);
    };

    const findVehicleById = (vehicleId: string) => vehicles.list.vehicles.find(v => v.vehicleId === vehicleId);

    const renderAvatar = (vehicleId: string) => {
        const vehicle = findVehicleById(vehicleId);
        return vehicle?.trip ? (
            <Avatar
                style={{ ...routeBackgroundColor(vehicle.trip.route), ...routeTextColor(vehicle.trip.route) }}
                className={classes.shortNameAvatar}
            >
                {vehicle.routeName}
            </Avatar>
        ) : undefined;
    };

    const noOptionsMessage = () => intl.formatMessage({ id: 'dispatcher-messages.form.no-selectable-vehicle' });

    const getOptionLabel = (vehicleId: string) => {
        const vehicle = findVehicleById(vehicleId);
        return vehicle ? getVehicleLabel(vehicle) : vehicleId;
    };

    if (!roles.includes(Roles.Message)) {
        return null;
    }

    return (
        <>
            <CustomSnackbar variant="info" open={currentMessage.loading}>
                <FormattedMessage id="dispatcher-messages.snackbar.message-send-success" />
            </CustomSnackbar>
            <CustomSnackbar variant="error" open={Boolean(currentMessage.error)}>
                <FormattedMessage id="dispatcher-messages.snackbar.message-send-fail" />
            </CustomSnackbar>

            <PageTitle>
                <FormattedMessage id="dispatcher-messages.title" />
            </PageTitle>

            <div className={classes.selectorForm}>
                <div className={classes.messageForm}>
                    <AutoComplete
                        className={classes.autoComplete}
                        renderAvatar={renderAvatar}
                        value={selectedVehicleIds}
                        options={getVehicleIds(vehicles.list.vehicles)}
                        isMulti
                        placeholder={intl.formatMessage({ id: 'dispatcher-messages.form.addressee' })}
                        noOptionsMessage={noOptionsMessage}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChange={changeVehicleSelection}
                    />
                    <TextField
                        fullWidth
                        multiline
                        label={<FormattedMessage id="dispatcher-messages.form.message" />}
                        margin="normal"
                        variant="filled"
                        value={messageText}
                        onChange={handleMessageChange}
                        rows={6}
                        inputProps={{ maxLength: 4000 }}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={onSendMessage}>
                            <FormattedMessage id="dispatcher-messages.form.send" />
                        </Button>
                    </div>
                </div>
                <div className={classes.routeSelectorContainer}>
                    <div>
                        <Button variant="contained" color="primary" onClick={onSelectAllVehicles}>
                            <FormattedMessage id="dispatcher-messages.form.select-all-vehicles" />
                        </Button>
                    </div>
                    <div className={classes.routeSelector}>
                        <RouteVehiclesSelector onVehiclesSelected={onVehiclesSelected} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(({ vehicles, user }: AppState) => ({ vehicles, roles: user.authenticated ? user.roles : [] }), {
    fetchVehicles: fetchVehiclesAction,
})(SendMessageForm);
