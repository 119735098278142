import React from 'react';
import { isInternalMapEnabled } from '../config';
import { getInternalTripMapUrl, getInternalVehicleMapUrl, getMapUrl } from '../utils';
import type { ResolvedCoreRealtimeVehicle, TripBase } from '../../../common/interfaces';
import LinkButton from './LinkButton';

function getLink(trip: TripBase | null, vehicle?: ResolvedCoreRealtimeVehicle) {
    if (vehicle) {
        return getInternalVehicleMapUrl(vehicle.vehicleId);
    }

    if (trip) {
        return getInternalTripMapUrl(trip.tripKey);
    }

    return '/map';
}

interface Props {
    trip: TripBase | null;
    vehicle?: ResolvedCoreRealtimeVehicle;
}

const MapLink: React.FunctionComponent<Props> = ({ trip, vehicle, children }) => {
    if (isInternalMapEnabled()) {
        const link = getLink(trip, vehicle);

        return (
            <LinkButton link={link}>
                {children}
            </LinkButton>
        );
    }

    return (
        <LinkButton link={getMapUrl(trip, vehicle)} external>
            {children}
        </LinkButton>
    );
};

export default MapLink;
