import ButtonBase from '@mui/material/ButtonBase';
import DialogContent from '@mui/material/DialogContent';
import type { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import type { BlockId, BlockKey, StaticVehicle } from '../../../../common/interfaces';
import { PopupRow } from '../../components/BlockOverviewPopup';
import type { ClosablePopoverProps } from '../../components/Closable';
import { ClosablePopover } from '../../components/Closable';
import { INVALID_BLOCK_ID } from '../../constants';
import { getNotificationTypeMessageId } from '../../containers/NotificationsSidebar/utils';
import { sendVehicleAssignment as sendVehicleAssignmentAction } from '../../redux/actions';
import type { AppState, DriverBlock, GtfsVehicles, VehicleAssignments, VehicleBlock } from '../../redux/state';
import { EMPTY_AUTOCOMPLETE_OPTION, getBeginTime, getEndTime, getGtfsEntityOption } from '../../utils';
import AssignmentDialog from './AssignmentDialog';
import DriverEditor from './DriverEditor';
import { calculateBlockDataRowHeight, getBlockRowClasses, groupActivitiesToRows } from './functions';
import './styles.css';

const getActualVehicle = (gtfsVehicle: StaticVehicle) => getGtfsEntityOption(gtfsVehicle, 'licensePlate');

const getPlannedVehicle = (vehicleAssignments: VehicleAssignments, gtfsVehicles: GtfsVehicles, blockId: string) => {
    const foundVehicleAssignment = vehicleAssignments.list.find(x => x.blockId === blockId);

    return foundVehicleAssignment && gtfsVehicles.list.find(v => v.id === foundVehicleAssignment.resourceId);
};

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'right',
};
const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'left',
};

interface BlockInfoPopoverContentProps {
    block: ValidVehicleBlock;
    gtfsVehicles: GtfsVehicles;
    vehicleAssignments: VehicleAssignments;
    sendVehicleAssignment: typeof sendVehicleAssignmentAction;
}

const BlockInfoPopoverContent: React.FunctionComponent<BlockInfoPopoverContentProps> = ({
    block,
    gtfsVehicles,
    vehicleAssignments,
    sendVehicleAssignment,
}) => {
    const { blockId, vehicles, blockVersion, activities, driverBlocks } = block;

    // This is the planned departure
    const start = activities.length > 0 && getBeginTime(activities[0]);
    const end = activities.length > 0 && getEndTime(activities[activities.length - 1]);

    const driverBlockVersionForDriverBlockId = (driverBlockKey: BlockKey): number => {
        const driverBlock = driverBlocks.find((b: DriverBlock) => b.blockKey === driverBlockKey);
        return driverBlock?.blockVersion || 0;
    };

    const foundPlannedVehicle: StaticVehicle | undefined = getPlannedVehicle(vehicleAssignments, gtfsVehicles, blockId);

    const selectedAutocompleteOption = foundPlannedVehicle
        ? {
            label: foundPlannedVehicle.licensePlate,
            value: foundPlannedVehicle.id,
        }
        : EMPTY_AUTOCOMPLETE_OPTION;

    return (
        <DialogContent>
            {start && end && (
                <PopupRow label={<FormattedMessage id="blocks-overview.block-info.activity-period.label" />}>
                    <FormattedTime value={start} />
                    {' - '}
                    <FormattedTime value={end} />
                </PopupRow>
            )}
            {vehicles.length === 0 && (
                <PopupRow label={<FormattedMessage id="blocks-overview.block-info.vehicle-block-version" />}>{blockVersion}</PopupRow>
            )}
            {vehicles.map(v => (
                <React.Fragment key={v.vehicleId}>
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-info.model" />}>{v.model}</PopupRow>
                    {v.driverName && (
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-info.driver" />}>{v.driverName}</PopupRow>
                    )}
                    {v.driverBlockKey ? (
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-info.driver-block-version" />}>
                            {driverBlockVersionForDriverBlockId(v.driverBlockKey)}
                            {' / '}
                            {v.driverBlockVersion}
                        </PopupRow>
                    ) : (
                        <PopupRow label={<FormattedMessage id="blocks-overview.block-info.vehicle-block-version" />}>
                            {blockVersion}
                            {' / '}
                            {v.vehicleBlockVersion}
                        </PopupRow>
                    )}
                </React.Fragment>
            ))}
            <PopupRow label={<FormattedMessage id="blocks-overview.block-info.planned-vehicle" />}>
                {selectedAutocompleteOption.label}
                <AssignmentDialog
                    title={<FormattedMessage id="blocks-overview.block-info.planned-vehicle.select" />}
                    defaultValue={selectedAutocompleteOption}
                    options={gtfsVehicles.list.map(getActualVehicle).sort((a, b) => a.label.localeCompare(b.label))}
                    blockId={blockId}
                    serviceDate={block.blockServiceDate}
                    sendAssignment={sendVehicleAssignment}
                />
            </PopupRow>
            {driverBlocks.map(driverBlock => (
                <React.Fragment key={driverBlock.blockId}>
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-info.driver-block-id" />}>{driverBlock.blockId}</PopupRow>
                    <PopupRow label={<FormattedMessage id="blocks-overview.block-info.activity-period.label" />}>
                        <FormattedTime value={driverBlock.begin} />
                        {' - '}
                        <FormattedTime value={driverBlock.end} />
                    </PopupRow>
                    <DriverEditor driverBlock={driverBlock} />
                </React.Fragment>
            ))}
        </DialogContent>
    );
};

const ConnectedBlockInfoPopoverContent = connect(
    ({ gtfsVehicles, vehicleAssignments }: AppState) => ({ gtfsVehicles, vehicleAssignments }),
    { sendVehicleAssignment: sendVehicleAssignmentAction },
)(BlockInfoPopoverContent);

type ValidVehicleBlock = Omit<VehicleBlock, 'blockId'> & { blockId: BlockId };

interface BlockInfoPopoverProps extends Omit<ClosablePopoverProps, 'dialogTitle'> {
    block: ValidVehicleBlock;
}

const BlockInfoPopover: React.FunctionComponent<BlockInfoPopoverProps> = ({ block, ...popoverProps }) => {
    const { blockId, vehicles } = block;

    let title = <FormattedMessage id="block.id" values={{ blockId }} />;
    if (vehicles.length) {
        title = (
            <>
                {title}
                {' / '}
                {vehicles.map(v => v.licensePlate).join(', ')}
            </>
        );
    }

    return (
        <ClosablePopover dialogTitle={title} anchorOrigin={ANCHOR_ORIGIN} transformOrigin={TRANSFORM_ORIGIN} {...popoverProps}>
            <ConnectedBlockInfoPopoverContent block={block} />
        </ClosablePopover>
    );
};

export interface Props {
    block: VehicleBlock;
    active: boolean;
    highlighted: boolean;
    onBlockSelected: (blockKey: BlockKey, open: boolean) => void;
}

interface State {
    modalOpen: boolean;
}

export default class BlockInfo extends React.PureComponent<Props, State> {
    state = {
        modalOpen: false,
    };

    private readonly buttonRef = React.createRef<HTMLButtonElement>();

    private readonly onClick = () => {
        const {
            onBlockSelected,
            block: { blockKey },
        } = this.props;
        this.setState({ modalOpen: true });
        onBlockSelected(blockKey, true);
    };

    private readonly onClose = () => {
        const {
            onBlockSelected,
            block: { blockKey },
        } = this.props;
        this.setState({ modalOpen: false });
        onBlockSelected(blockKey, false);
    };

    render() {
        const { block, active, highlighted } = this.props;
        const { blockId, activities, vehicles, notifications } = block;
        const { modalOpen } = this.state;

        return (
            <>
                <ButtonBase
                    className={getBlockRowClasses(block, active, highlighted)}
                    {...{ component: 'div' }}
                    style={calculateBlockDataRowHeight(blockId, groupActivitiesToRows(blockId, activities))}
                    onClick={this.onClick}
                    ref={this.buttonRef}
                >
                    <div className="block-info">
                        <div className="block-info__first-row">
                            <Typography variant="subtitle2" className="block-info__label">
                                {blockId === INVALID_BLOCK_ID ? (
                                    <FormattedMessage id="blocks-overview.clipboard" />
                                ) : (
                                    <FormattedMessage id="block.id" values={{ blockId }} />
                                )}
                            </Typography>
                            {notifications.length > 0 && (
                                <Typography
                                    variant="caption"
                                    className={`block-info__notification block-info__notification--${notifications[0].severity}`}
                                >
                                    <FormattedMessage id={getNotificationTypeMessageId(notifications[0].type)} />
                                </Typography>
                            )}
                        </div>
                        <Typography variant="caption" className="block-info__label">
                            {vehicles.map(v => v.licensePlate).join(', ')}
                        </Typography>
                        <div id={block.blockKey} className="block-info__anchor" />
                    </div>
                </ButtonBase>
                {blockId !== INVALID_BLOCK_ID && (
                    <BlockInfoPopover
                        block={block as ValidVehicleBlock}
                        open={modalOpen}
                        anchorEl={this.buttonRef.current}
                        onClose={this.onClose}
                    />
                )}
            </>
        );
    }
}
