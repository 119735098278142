import FormControl from '@mui/material/FormControl';
import React from 'react';
import type { BlockIdSelection } from './DriverBlockSelector';
import DriverBlockSelector, { AUTOMATIC } from './DriverBlockSelector';
import type { BlockId, ServiceDate } from '../../../../common/interfaces';
import VehicleBlockSelector from './VehicleBlockSelector';

interface Props {
    vehicleBlockId: BlockId;
    driverBlockId: BlockIdSelection;
    serviceDate: ServiceDate;
    onVehicleBlockIdChange: (vehicleBlockId: BlockId) => void;
    onDriverBlockIdChange: (driverBlockId: BlockIdSelection) => void;
}

const CompoundBlockSelector: React.FunctionComponent<Props> = (props) => {
    const { vehicleBlockId, driverBlockId, serviceDate, onVehicleBlockIdChange, onDriverBlockIdChange } = props;

    const vehicleBlockIdChange = (newVehicleBlockId: BlockIdSelection) => {
        onVehicleBlockIdChange(newVehicleBlockId as BlockId);
        onDriverBlockIdChange(AUTOMATIC);
    };

    return (
        <>
            <FormControl fullWidth>
                <VehicleBlockSelector vehicleBlockId={vehicleBlockId} serviceDate={serviceDate} onChange={vehicleBlockIdChange} />
            </FormControl>
            <FormControl fullWidth>
                <DriverBlockSelector driverBlockId={driverBlockId} serviceDate={serviceDate} onChange={onDriverBlockIdChange} />
            </FormControl>
        </>
    );
};

export default CompoundBlockSelector;
