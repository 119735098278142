import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Pattern } from '../../../../common/interfaces';
import SelectWithEmptyValue from './SelectWithEmptyValue';
import { GET_PATTERNS } from '../../graphql/rest';
import type { RouteSelectorRoute } from './RouteSelector';

interface GetPatternsQuery {
    patterns?: Pattern[];
}

interface Props {
    route: RouteSelectorRoute | null;
    pattern: Pattern | null;
    onChange: (pattern: Pattern | null) => void;
}

const PatternSelector: React.FunctionComponent<Props> = ({ route, pattern, onChange }) => {
    const queryResult = useQuery<GetPatternsQuery>(GET_PATTERNS, getQueryOptions(route));
    const { data, loading } = queryResult;

    return (
        <SelectWithEmptyValue
            value={pattern}
            onChange={onChange}
            values={loading ? undefined : data?.patterns}
            label={<FormattedMessage id="create-activity-dialog.pattern" />}
        >
            {data?.patterns?.map(p => (
                <MenuItem key={p.patternId} value={p as any}>
                    {p.patternName}
                </MenuItem>
            ))}
        </SelectWithEmptyValue>
    );
};

function getQueryOptions(route: RouteSelectorRoute | null): QueryHookOptions<GetPatternsQuery> {
    if (route === null) {
        return {
            skip: true,
        };
    }

    return {
        variables: {
            routeId: route.id,
        },
    };
}

export default PatternSelector;
