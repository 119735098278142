import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormProvider, useController, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import type { TransitAlertFormValue } from './TransitAlertFormValue';
import PeriodInput from './PeriodInput';
import AlertMessageInput from './AlertMessageInput';
import { LANG_EN, LANG_HU } from './alert-utils';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import AutocompleteRouteSelector from '../../containers/RouteSelector/AutocompleteRouteSelector';
import FormSectionTitle from './FormSectionTitle';

const useStyles = makeStyles({
    messageInput: {
        display: 'flex',
        flexDirection: 'column',
    },
});

interface Props {
    defaultValue: Partial<TransitAlertFormValue>;
    onSubmit: (alert: TransitAlertFormValue) => void;
    title: React.ReactNode;
}

const TransitAlertForm: React.FC<Props> = ({ defaultValue, title, onSubmit }) => {
    const classes = useStyles();

    const form = useForm<TransitAlertFormValue>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: defaultValue,
    });

    const { handleSubmit, control } = form;

    const { field: enabledField } = useController<TransitAlertFormValue, 'enabled'>({
        name: 'enabled',
        control,
    });

    const { field: routeIdsField } = useController<TransitAlertFormValue, 'routeIds'>({
        name: 'routeIds',
        rules: { required: true },
        control,
    });

    return (
        <FormProvider {...form}>
            <PageHeader title={title}>
                <Stack direction="row" spacing="12px">
                    <Button variant="outlined" component={Link} to="/alerts">
                        <FormattedMessage id="alert-editor.cancel" />
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 1 }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleSubmit((data) => {
                            onSubmit(data);
                        })}
                    >
                        <FormattedMessage id="alert-editor.save" />
                    </Button>
                </Stack>
            </PageHeader>

            <PageContent>
                <FormSectionTitle>
                    <FormattedMessage id="alert.status" />
                </FormSectionTitle>

                <FormControlLabel
                    control={<Switch onChange={enabledField.onChange} checked={enabledField.value} />}
                    label={enabledField.value ? (
                        <FormattedMessage id="alerts.status.active" />
                    ) : (
                        <FormattedMessage id="alerts.status.inactive" />
                    )}
                />

                <PeriodInput />

                <FormSectionTitle margin="32px 0 16px">
                    <FormattedMessage id="alert.routes" />
                </FormSectionTitle>

                <AutocompleteRouteSelector {...routeIdsField} pendingRouteIds={routeIdsField.value} />

                <FormSectionTitle margin="32px 0 16px">
                    <FormattedMessage id="alert.message" />
                </FormSectionTitle>

                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item xs={12} lg={6} className={classes.messageInput}>
                        <AlertMessageInput language={LANG_HU} />
                    </Grid>

                    <Grid item xs={12} lg={6} className={classes.messageInput}>
                        <AlertMessageInput language={LANG_EN} />
                    </Grid>
                </Grid>
            </PageContent>
        </FormProvider>
    );
};

export default TransitAlertForm;
