import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterCenterFocus from '@mui/icons-material/FilterCenterFocus';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import type { BlockActivityKey, BlockKey, ServiceDate } from '../../../../common/interfaces';
import LoadableRefresher from '../../components/LoadableRefresher';
import { Roles } from '../../constants';
import CreateActivityDialog from '../../containers/CreateActivityDialog';
import {
    changeServiceDate as changeServiceDateAction,
    fetchDriverAssignments as fetchDriverAssignmentsAction,
    fetchGtfsDrivers as fetchGtfsDriversAction,
    fetchGtfsVehicles as fetchGtfsVehiclesAction,
    fetchVehicleAssignments as fetchVehicleAssignmentsAction,
    fetchVehicles as fetchVehiclesAction,
    refreshBlockActivities as refreshBlockActivitiesAction,
    startBlockActivityEdit as startBlockActivityEditAction,
} from '../../redux/actions';
import type {
    ActivityModificationNotification,
    AppState,
    VehicleBlockState,
    Vehicles,
} from '../../redux/state';
import { momentToServiceDate } from '../../utils';
import ActivityModificationNotificationSnackbar from './ActivityModificationNotificationSnackbar';
import BlockData from './BlockData';
import BlockInfo from './BlockInfo';
import BlockSortControl from './BlockSortControl';
import Container from './Container';
import DateControl from './DateControl';
import DateFrameLabels from './DateFrameLabels';
import DateFrameStripes from './DateFrameStripes';
import { calculateLeft, calculateWidth } from './functions';
import './styles.css';
import ZoomControl from './ZoomControl';
import PageTitle from '../../components/PageTitle';
import RouteFilter from './RouteFilter';

function parseBlockKeyFromHash(hash: string): BlockKey {
    return hash.replace('#', '') as BlockKey;
}

interface InternalProps extends RouteComponentProps {
    notifications: ActivityModificationNotification[];
    vehicleBlocks: VehicleBlockState;
    vehicles: Vehicles;
    fetchVehicles: typeof fetchVehiclesAction;
    refreshBlockActivities: typeof refreshBlockActivitiesAction;
    fetchVehicleAssignments: typeof fetchVehicleAssignmentsAction;
    fetchDriverAssignments: typeof fetchDriverAssignmentsAction;
    fetchGtfsVehicles: typeof fetchGtfsVehiclesAction;
    fetchGtfsDrivers: typeof fetchGtfsDriversAction;
    startBlockActivityEdit: typeof startBlockActivityEditAction;
    changeServiceDate: typeof changeServiceDateAction;
}

interface State {
    serviceDate: ServiceDate;
    // Width of one hour in pixels
    zoom: number;
    activeBlockKeys: BlockKey[];
    highlightedBlockKeys: BlockKey[];
    createActivityDialogOpen: boolean;
}

const TimeMarker: React.FunctionComponent<{ begin: Date; time: Date; zoom: number }> = ({ begin, time, zoom }) => (
    <div className="time-marker" style={{ left: calculateLeft(begin, time, zoom) }} />
);

class BlocksOverviewPage extends React.PureComponent<InternalProps, State> {
    private readonly blockOverviewData = React.createRef<HTMLDivElement>();

    private readonly blockOverviewActivities = React.createRef<HTMLDivElement>();

    constructor(props: InternalProps) {
        super(props);

        this.state = {
            serviceDate: momentToServiceDate(moment().add(-2, 'hours')),
            zoom: 240,
            activeBlockKeys: [],
            highlightedBlockKeys: [],
            createActivityDialogOpen: false,
        };
    }

    componentDidMount() {
        const {
            refreshBlockActivities,
            fetchVehicles,
            fetchVehicleAssignments,
            fetchDriverAssignments,
            fetchGtfsVehicles,
            fetchGtfsDrivers,
            location,
        } = this.props;
        const { serviceDate } = this.state;
        refreshBlockActivities();
        fetchVehicles();
        fetchVehicleAssignments(serviceDate);
        fetchDriverAssignments(serviceDate);
        fetchGtfsVehicles();
        fetchGtfsDrivers();
        this.scrollToCenter();
        this.highlightBlock(location.hash, 3000);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<InternalProps>) {
        const { location } = this.props;
        if (nextProps.location !== location) {
            this.highlightBlock(nextProps.location.hash, 1500);
        }
    }

    componentDidUpdate(prevProps: InternalProps) {
        const { vehicleBlocks } = this.props;
        if (!prevProps.vehicleBlocks.begin && vehicleBlocks.begin) {
            this.scrollToCenter();
        }
    }

    private readonly onZoomChange = (zoom: number) => {
        this.setState({ zoom }, () => {
            this.scrollToCenter();
        });
    };

    private readonly onBlockSelected = (blockKey: BlockKey, open: boolean) => {
        this.changeActiveBlockKey(blockKey, open);
    };

    private readonly onActivitySelected = (blockKey: BlockKey, activityKey: BlockActivityKey, open: boolean) => {
        // TODO: batch updates using the selected tripIds
        const { startBlockActivityEdit } = this.props;
        startBlockActivityEdit();
        this.changeActiveBlockKey(blockKey, open);
    };

    private readonly scrollToCenter = () => {
        const {
            vehicleBlocks: { begin },
        } = this.props;
        const { zoom } = this.state;

        if (!begin) {
            return;
        }

        const container = this.blockOverviewData.current!;
        const activitiesLeft = this.blockOverviewActivities.current!.offsetLeft - container.offsetLeft;
        const activitiesWidth = container.clientWidth - activitiesLeft;
        const target = Math.max(0, calculateLeft(begin, new Date(), zoom) - activitiesWidth / 2);
        container.scrollTo(target, container.scrollTop);
    };

    private readonly openCreateActivityDialog = () => {
        const { startBlockActivityEdit } = this.props;
        startBlockActivityEdit();
        this.setState({ createActivityDialogOpen: true });
    };

    private readonly closeCreateActivityDialog = () => {
        this.setState({ createActivityDialogOpen: false });
    };

    private changeActiveBlockKey(blockKey: BlockKey, open: boolean) {
        const { activeBlockKeys } = this.state;

        if (open) {
            this.setState({ activeBlockKeys: [...activeBlockKeys, blockKey] });
        } else {
            const index = activeBlockKeys.indexOf(blockKey); // it is important to remove only 1 element

            this.setState({ activeBlockKeys: activeBlockKeys.filter((_, i) => i !== index) });
        }
    }

    private removeBlockHighlight(baseBlockKey: BlockKey) {
        const { highlightedBlockKeys } = this.state;
        const index = highlightedBlockKeys.indexOf(baseBlockKey);

        if (index === -1) {
            return;
        }

        const filtered = highlightedBlockKeys.filter((value, i) => index !== i);
        this.setState({ highlightedBlockKeys: filtered });
    }

    private highlightBlock(hash: string, timeout: number) {
        const blockKey = parseBlockKeyFromHash(hash);
        if (blockKey.length === 0) {
            return;
        }
        this.setState(({ highlightedBlockKeys }) => ({ highlightedBlockKeys: [...highlightedBlockKeys, blockKey] }));
        setTimeout(() => {
            this.removeBlockHighlight(blockKey);
        }, timeout);
    }

    render() {
        const { zoom, activeBlockKeys, createActivityDialogOpen, highlightedBlockKeys } = this.state;
        const { vehicleBlocks, vehicles, notifications, fetchVehicles, refreshBlockActivities, changeServiceDate } = this.props;

        const { begin, end, list: blocks, serviceDate, routes, paramsChanged } = vehicleBlocks;

        // TODO: Replace with the time the vehicles were last loaded
        const time = new Date();

        return (
            <div className="blocks-overview">
                {notifications.map(notification => (
                    <ActivityModificationNotificationSnackbar key={notification.id} notification={notification} />
                ))}

                <PageTitle>
                    <FormattedMessage id="blocks-overview.title" />
                </PageTitle>

                <div className="blocks-overview__controls">
                    <LoadableRefresher interval={10000} fetch={fetchVehicles} loadable={vehicles} />
                    <LoadableRefresher interval={15000} fetch={refreshBlockActivities} loadable={vehicleBlocks} />
                    <ZoomControl zoom={zoom} onChange={this.onZoomChange} />
                    <Tooltip title={<FormattedMessage id="blocks-overview.toolbar.focus-center" />} placement="top">
                        <IconButton onClick={this.scrollToCenter} size="large">
                            <FilterCenterFocus />
                        </IconButton>
                    </Tooltip>
                    <DateControl
                        serviceDate={serviceDate}
                        onChange={changeServiceDate}
                        loading={paramsChanged}
                        disabled={routes !== null && routes.length === 0}
                    />
                    <RouteFilter />
                    <div className="blocks-overview__sort-order-form">
                        <BlockSortControl />
                    </div>
                    <IfRole role={Roles.Write}>
                        <div className="blocks-overview__buttons">
                            <Button color="primary" variant="contained" onClick={this.openCreateActivityDialog}>
                                <FormattedMessage id="blocks-overview.toolbar.create-activity" />
                            </Button>
                            <CreateActivityDialog
                                open={createActivityDialogOpen}
                                serviceDate={serviceDate}
                                onClose={this.closeCreateActivityDialog}
                            />
                        </div>
                    </IfRole>
                </div>
                {begin && (
                    <div className="blocks-overview__data" ref={this.blockOverviewData}>
                        <div className="blocks-overview__table">
                            <div className="blocks-overview__table-row">
                                <div className="blocks-overview__table-cell blocks-overview-sticky__left-top" />
                                <div className="blocks-overview__table-cell blocks-overview-sticky__top">
                                    <DateFrameLabels begin={begin} end={end!} zoom={zoom} />
                                </div>
                            </div>

                            <div className="blocks-overview__table-row">
                                <div className="blocks-overview__table-cell blocks-overview-sticky__left">
                                    <div className="blocks-overview__vehicles-column">
                                        {blocks.map(block => (
                                            <BlockInfo
                                                block={block}
                                                active={activeBlockKeys.includes(block.blockKey)}
                                                highlighted={highlightedBlockKeys.includes(block.blockKey)}
                                                onBlockSelected={this.onBlockSelected}
                                                key={block.blockKey}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div
                                    className="blocks-overview__table-cell blocks-overview-sticky__content"
                                    ref={this.blockOverviewActivities}
                                >
                                    <div
                                        className="blocks-overview__activity-column"
                                        style={{ width: calculateWidth(begin, begin, end!, zoom) }}
                                    >
                                        <DateFrameStripes begin={begin} end={end!} zoom={zoom} />
                                        <Container.Provider value={this.blockOverviewActivities.current}>
                                            <div>
                                                {blocks.map(block => (
                                                    <BlockData
                                                        block={block}
                                                        active={activeBlockKeys.includes(block.blockKey)}
                                                        highlighted={highlightedBlockKeys.includes(block.blockKey)}
                                                        begin={begin}
                                                        zoom={zoom}
                                                        time={time}
                                                        onActivitySelected={this.onActivitySelected}
                                                        key={block.blockKey}
                                                    />
                                                ))}
                                            </div>
                                        </Container.Provider>
                                        <TimeMarker begin={begin} zoom={zoom} time={time} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    ({ vehicleBlocks, vehicles, activityModificationNotifications: notifications }: AppState) => ({
        vehicleBlocks,
        vehicles,
        notifications,
    }),
    {
        fetchVehicles: fetchVehiclesAction as any,
        refreshBlockActivities: refreshBlockActivitiesAction as any,
        fetchVehicleAssignments: fetchVehicleAssignmentsAction as any,
        fetchDriverAssignments: fetchDriverAssignmentsAction as any,
        fetchGtfsVehicles: fetchGtfsVehiclesAction as any,
        fetchGtfsDrivers: fetchGtfsDriversAction as any,
        startBlockActivityEdit: startBlockActivityEditAction as any,
        changeServiceDate: changeServiceDateAction,
    },
)(BlocksOverviewPage);
