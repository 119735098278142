import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DetailedItem from '@realcity/web-frame/lib/components/DetailedItem';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import Refresher from '@realcity/web-frame/lib/components/Refresher';

import FormattedRelativeWithHint, { DATE_FORMAT_OPTIONS } from '../../components/FormattedRelativeWithHint';
import RecipientChips from './RecipientChips';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import TimeTooltip from '../../components/TimeTooltip';
import PageTitle from '../../components/PageTitle';
import type { DispatcherMessageItemFragment } from '../../graphql/graphql-typings';
import { useDispatcherMessagesQuery } from '../../graphql/graphql-typings';

const useStyles = makeStyles({
    dayHeader: {
        margin: 15,
        '&::first-letter': {
            textTransform: 'uppercase',
        },
        textAlign: 'center',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px 0',
    },
    text: {
        whiteSpace: 'pre-line',
    },
});

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
const LOAD_MORE_BUFFER = 10;

export const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
};

const SentMessages: React.FC = () => {
    const [lastRefresh, setLastRefresh] = useState<Date | undefined>(undefined);
    const { loading, data, error, refetch, fetchMore } = useDispatcherMessagesQuery({
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setLastRefresh(new Date());
        },
    });

    const { ref: scrollIntersectionRef } = useIntersectionObserver((entries) => {
        const nextCursor = data?.dispatcherMessages.paginationInfo.nextCursor;
        if (entries[0].isIntersecting && nextCursor) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            fetchMore({
                variables: { cursor: nextCursor },
            });
        }
    });

    const classes = useStyles();

    const autoRefresh = useRef(true);

    const { ref: loadingIntersectionRef } = useIntersectionObserver((entries) => {
        autoRefresh.current = entries[0].isIntersecting;
    });

    const fetchFromStart = useCallback(() => {
        if (autoRefresh.current) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            refetch();
        }
    }, [refetch]);

    const messages = data?.dispatcherMessages.items ?? [];

    return (
        <>
            <PageTitle variant="h2">
                <FormattedMessage id="dispatcher-messages.messages.sent-messages" />
            </PageTitle>

            <div ref={loadingIntersectionRef}>
                <Refresher interval={10000} refresh={fetchFromStart} error={Boolean(error)} loading={loading} lastRefresh={lastRefresh} />
            </div>
            <div>
                {messages.map((message, i) => (
                    <div key={message.id} ref={i === messages.length - LOAD_MORE_BUFFER ? scrollIntersectionRef : null}>
                        {differentFromPrevious(messages, i) && notToday(message.sent) && (
                            <>
                                <Typography variant="h5" classes={{ root: classes.dayHeader }}>
                                    <FormattedRelativeWithHint value={message.sent} relativeLimit={ONE_WEEK} />
                                </Typography>
                                <Divider />
                            </>
                        )}

                        <DetailedItem
                            title={<RecipientChips recipients={message.recipients} />}
                            topNotes={(
                                <TimeTooltip value={message.sent} {...DATE_FORMAT_OPTIONS} {...TIME_FORMAT_OPTIONS} second="2-digit">
                                    <FormattedTime value={message.sent} {...TIME_FORMAT_OPTIONS} />
                                </TimeTooltip>
                            )}
                            bottomNotes={message.sender?.name}
                        >
                            <span className={classes.text}>{message.message}</span>
                        </DetailedItem>
                    </div>
                ))}

                {loading && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}
            </div>
        </>
    );
};

function differentDaysFromDate(date1: Date, date2: Date) {
    return date1.getDay() !== date2.getDay() || date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
}

function differentDaysFromString(date1: string, date2: string) {
    return differentDaysFromDate(new Date(date1), new Date(date2));
}

function differentFromPrevious(items: DispatcherMessageItemFragment[], index: number) {
    return index === 0 || differentDaysFromString(items[index - 1].sent, items[index].sent);
}

function notToday(date: string) {
    return differentDaysFromDate(new Date(date), new Date());
}

export default SentMessages;
