import * as React from 'react';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import TimeTooltip from './TimeTooltip';

interface Props {
    value: string;
    relativeLimit: number;
}

export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
};

const ONE_DAY = 1000 * 60 * 60 * 24;

const FormattedRelativeWithHint: React.FunctionComponent<Props> = ({ value, relativeLimit }) => {
    const valueMs = Date.parse(value);
    const now = Date.now();
    const showRelative = valueMs > now - relativeLimit;

    if (!showRelative) {
        return <FormattedDate value={value} {...DATE_FORMAT_OPTIONS} />;
    }

    return (
        <TimeTooltip value={value} {...DATE_FORMAT_OPTIONS}>
            <FormattedRelativeTime value={Math.floor(valueMs / ONE_DAY) - Math.floor(now / ONE_DAY)} unit="day" />
        </TimeTooltip>
    );
};

export default FormattedRelativeWithHint;
