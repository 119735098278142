import { parse, stringify } from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { UrlParam } from '../utils/query-params-util';

function useQueryParamWriter() {
    const location = useLocation();
    const history = useHistory();

    return useMemo(() => (newParams: { [key: string]: UrlParam }) => {
        const existingParams = parse(location.search);

        const search = stringify({ ...existingParams, ...newParams });
        history.replace({ search });
    }, [history, location.search]);
}

export default useQueryParamWriter;
