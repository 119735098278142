import { Popup } from 'react-mapbox-gl';
import React from 'react';
import type { OnlineVehicleFragment } from '../../graphql/graphql-typings';
import VehicleDetailsTable from '../../components/VehicleDetailsTable';

interface Props {
    vehicle: OnlineVehicleFragment;
    onMouseLeave: () => void;
}

const VehiclePopup: React.FC<Props> = ({ vehicle, onMouseLeave }) => (
    <Popup offset={[-20, -30]} coordinates={vehicle.position!.coordinates} onMouseLeave={onMouseLeave}>
        <VehicleDetailsTable vehicle={vehicle} />
    </Popup>
);

export default VehiclePopup;
