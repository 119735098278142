import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import React, { useEffect } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import type { IsoDateString } from '../../../../common/interfaces';
import useQueryParamReader from '../../hooks/useQueryParamReader';
import useQueryParamWriter from '../../hooks/useQueryParamWriter';
import { setVehicleBlockSortingCondition } from '../../redux/actions';
import type { AppState, VehicleBlockSortingCondition } from '../../redux/state';
import type { UrlParam } from '../../utils/query-params-util';

const BlockSortControl: React.FC = () => {
    const dispatch = useDispatch();

    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    useEffect(() => {
        const { sortBy } = readQueryParam<'sortBy'>();
        dispatch(setVehicleBlockSortingCondition(getVehicleSortingCondition(sortBy)));
    }, [readQueryParam, dispatch]);

    const sortingCondition = useSelector<AppState, VehicleBlockSortingCondition>(state => state.vehicleBlocks.sortingCondition);

    const onChange = (event: SelectChangeEvent<VehicleBlockSortingCondition['type']>) => {
        const type = event.target.value as VehicleBlockSortingCondition['type'];
        writeQueryParams({ sortBy: type });
    };

    const renderSelectedValue = () => (
        <div className="blocks-overview__sort-selected-container">
            <div>{getLabelForType(sortingCondition.type)}</div>
            {sortingCondition.type === 'nearestDeparture' && (
                <div className="blocks-overview__sort-nearest-departure-click-date">
                    (
                    <FormattedTime value={sortingCondition.clickTime} />
                    )
                </div>
            )}
        </div>
    );

    return (
        <FormControl variant="standard">
            <InputLabel htmlFor="block-sort">
                <FormattedMessage id="blocks-overview.sorting-condition-selector.label" />
            </InputLabel>
            <Select
                inputProps={{ id: 'block-sort' }}
                value={sortingCondition.type}
                onChange={onChange}
                renderValue={renderSelectedValue}
            >
                {renderMenuItem('blockId')}
                {renderMenuItem('beginTime')}
                {renderMenuItem('nearestDeparture')}
            </Select>
        </FormControl>
    );
};

function getLabelForType(type: VehicleBlockSortingCondition['type']) {
    switch (type) {
        case 'blockId':
            return <FormattedMessage id="blocks-overview.sorting-condition-selector.block-key" />;
        case 'beginTime':
            return <FormattedMessage id="blocks-overview.sorting-condition-selector.begin-time" />;
        case 'nearestDeparture':
            return <FormattedMessage id="blocks-overview.sorting-condition-selector.nearest-departure" />;
    }
}

function renderMenuItem(type: VehicleBlockSortingCondition['type']) {
    return <MenuItem value={type}>{getLabelForType(type)}</MenuItem>;
}

function getVehicleSortingCondition(sortBy: UrlParam): VehicleBlockSortingCondition {
    if (sortBy === 'beginTime') {
        return { type: 'beginTime' };
    }

    if (sortBy === 'nearestDeparture') {
        return { type: 'nearestDeparture', clickTime: new Date().toISOString() as IsoDateString };
    }

    return { type: 'blockId' };
}

export default BlockSortControl;
