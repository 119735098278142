import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactNode } from 'react';
import React from 'react';
import type { Props as SearchInputProps } from './SearchInput';
import SearchInput from './SearchInput';

interface Props extends SearchInputProps {
    label: ReactNode;
}

const useStyles = makeStyles({
    searchBox: {
        marginRight: 15,
    },
});

const SearchInputWithLabel: React.FunctionComponent<Props> = ({ label, ...rest }) => {
    const styles = useStyles();

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <SearchInput classes={{ root: styles.searchBox }} {...rest} />
        </FormControl>
    );
};

export default SearchInputWithLabel;
