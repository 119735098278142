import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        width: 400,
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
});

interface Props {
    className?: string;
}

const MapCard: React.FunctionComponent<Props> = ({ className, children }) => {
    const classes = useStyles();

    return (
        <Card className={classNames(classes.root, className)}>
            {children}
        </Card>
    );
};

export default MapCard;
