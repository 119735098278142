import Box from '@mui/material/Box';
import React from 'react';

const PageContent: React.FC = ({ children }) => (
    <Box sx={{ padding: '10px' }}>
        {children}
    </Box>
);

export default PageContent;
