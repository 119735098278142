import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type {
    BlockActivity,
    BlockActivityKey,
    BlockKey,
    ResolvedCoreRealtimeVehicle,
    TripTimeDescriptor,
} from '../../../../common/interfaces';
import { getBeginTime, getDirectionText, getEndTime } from '../../utils';
import BlockActivityPopover from './BlockActivityPopover';
import Container from './Container';
import { calculateBlockActivityTop, calculateLeft, calculateWidth } from './functions';

const TripFactBar: React.FunctionComponent<{
    begin: Date;
    zoom: number;
    predictedTimes: TripTimeDescriptor;
    onClick: () => void;
    hasVehicle: boolean;
}> = ({ begin, zoom, predictedTimes, onClick, hasVehicle }) => {
    const beginTime = predictedTimes.firstStop.departureTime;
    const endTime = predictedTimes.lastStop.arrivalTime;

    const factDataDivLeft = calculateLeft(begin, beginTime, zoom);
    const factDataDivWidth = calculateWidth(begin, beginTime, endTime, zoom);

    return (
        <div
            className={classNames('block-activity-fact-data', hasVehicle && 'block-activity-fact-data--active-vehicle')}
            style={{
                left: factDataDivLeft,
                width: factDataDivWidth,
            }}
        >
            <ButtonBase {...{ component: 'div' }} className="block-activity__button" onClick={onClick} />
        </div>
    );
};

export interface Props {
    vehicleBlockKey: BlockKey;
    activity: BlockActivity;
    vehicle?: ResolvedCoreRealtimeVehicle;
    begin: Date;
    zoom: number;
    row: number;
    onActivitySelected: (blockKey: BlockKey, activityKey: BlockActivityKey, open: boolean) => void;
}

interface State {
    modalOpen: boolean;
}

export default class BlockActivityComponent extends React.PureComponent<Props, State> {
    state: State = {
        modalOpen: false,
    };

    private readonly buttonRef = React.createRef<HTMLButtonElement>();

    private readonly onClick = () => {
        const { onActivitySelected, vehicleBlockKey, activity } = this.props;
        const { modalOpen } = this.state;
        if (!modalOpen) {
            onActivitySelected(vehicleBlockKey, activity.activityKey, true);
            this.setState({ modalOpen: true });
        }
    };

    private readonly onClose = () => {
        const { onActivitySelected, vehicleBlockKey, activity } = this.props;
        onActivitySelected(vehicleBlockKey, activity.activityKey, false);
        this.setState({ modalOpen: false });
    };

    render() {
        const { activity, vehicle, begin, zoom, row } = this.props;
        const { modalOpen } = this.state;

        const { trip } = activity;

        const activityDivLeft = calculateLeft(begin, getBeginTime(activity), zoom);
        const activityDivWidth = calculateWidth(begin, getBeginTime(activity), getEndTime(activity), zoom);

        let modBadge = null;
        if (activity.modified) {
            const smallBadge = activityDivWidth < 60;
            modBadge = (
                <div className={smallBadge ? 'mod-badge mod-badge--small' : 'mod-badge'}>
                    <Typography variant="body1" className="mod-badge__text">
                        {smallBadge ? (
                            <FormattedMessage id="blocks-overview.block-activity.modified-short" />
                        ) : (
                            <FormattedMessage id="blocks-overview.block-activity.modified-long" />
                        )}
                    </Typography>
                </div>
            );
        }

        const blockActivityDivClasses = [
            'block-activity',
            vehicle?.delayStatus && `block-activity--status-${vehicle.delayStatus}`,
            !trip && 'block-activity--non-driving',
        ];

        const wrapperDivStyle: { left: number; width: number; top?: number } = {
            left: activityDivLeft,
            width: activityDivWidth,
        };

        if (row !== 0) {
            wrapperDivStyle.top = calculateBlockActivityTop(row);
        }

        return (
            <>
                {trip?.predictedTimes && (
                    <TripFactBar
                        begin={begin}
                        zoom={zoom}
                        predictedTimes={trip.predictedTimes}
                        onClick={this.onClick}
                        hasVehicle={!!vehicle}
                    />
                )}

                <div className={classNames(blockActivityDivClasses)} style={wrapperDivStyle}>
                    <ButtonBase {...{ component: 'div' }} className="block-activity__button" onClick={this.onClick} ref={this.buttonRef}>
                        <Typography variant="body2">
                            {trip ? `${trip.route.shortName}${getDirectionText(trip.directionId)}` : activity.activityType.name}
                        </Typography>
                    </ButtonBase>
                    {modBadge}
                </div>
                <Container.Consumer>
                    {container => (
                        <BlockActivityPopover
                            activity={activity}
                            vehicle={vehicle}
                            open={modalOpen}
                            onClose={this.onClose}
                            anchorEl={this.buttonRef.current}
                            container={container}
                            clickAwayContainer={container}
                            className="block-activity__popover"
                        />
                    )}
                </Container.Consumer>
            </>
        );
    }
}
