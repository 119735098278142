import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import type { PopoverOrigin } from '@mui/material/Popover';
import type { StyleRules, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { BlockActivity, ResolvedCoreRealtimeVehicle, Trip } from '../../../../common/interfaces';
import { ClosablePopover } from '../../components/Closable';
import BlockActivityBlockChangeDialog from './BlockActivityBlockChangeDialog';
import BlockActivityValidationChange from './BlockActivityValidationChangeDialog';
import BlockActivityTimeModificationDialog from './BlockActivityTimeModificationDialog';
import TripBeginTimeModificationDialog from './TripBeginTimeModificationDialog';
import TripStopSkippingModificationDialog from './TripStopSkippingModificationDialog';
import BlockActivityActionButton from './BlockActivityActionButton';

const BlockActivityActionLabel: React.FunctionComponent<{ blockActivityAction: BlockActivityAction }> = ({ blockActivityAction }) => {
    switch (blockActivityAction.type) {
        case 'BLOCK_ACTIVITY_VALIDATION':
            return <FormattedMessage id="blocks-overview.block-activity-actions.validation" />;
        case 'BLOCK_ACTIVITY_INVALIDATION':
            return <FormattedMessage id="blocks-overview.block-activity-actions.invalidation" />;
        case 'TRIP_BEGIN_TIME_MODIFICATION':
            return <FormattedMessage id="blocks-overview.block-activity-actions.begin-time-modification" />;
        case 'BLOCK_CHANGE':
            return <FormattedMessage id="blocks-overview.block-activity-actions.block-change" />;
        case 'BLOCK_ACTIVITY_TIME_MODIFICATION':
            return <FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification" />;
        case 'STOP_SKIPPING':
            return <FormattedMessage id="blocks-overview.block-activity-actions.stop-skipping" />;
    }
};

type ClassKeys = 'activityActionTitle' | 'back' | 'blockActivityActionButtons' | 'blockActivityActionContainer' | 'licensePlate' | 'paper';

const styles: StyleRules<{}, ClassKeys> = {
    paper: {
        marginLeft: 10,
    },
    blockActivityActionContainer: {
        margin: 10,
    },
    blockActivityActionButtons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    licensePlate: {
        paddingRight: 5,
    },
    activityActionTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    back: {
        marginLeft: -8,
    },
};

interface NonTripAction {
    type: 'BLOCK_ACTIVITY_INVALIDATION' | 'BLOCK_ACTIVITY_TIME_MODIFICATION' | 'BLOCK_ACTIVITY_VALIDATION' | 'BLOCK_CHANGE';
}

interface TripAction {
    type: 'STOP_SKIPPING' | 'TRIP_BEGIN_TIME_MODIFICATION';
    trip: Trip;
}

export type BlockActivityAction = NonTripAction | TripAction;

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'right',
};
const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: 'center',
    horizontal: 'left',
};

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    blockActivity: BlockActivity;
    vehicle?: ResolvedCoreRealtimeVehicle;
    activityModificationCompleted: () => void;
}

interface State {
    activeBlockActivityAction: BlockActivityAction | null;
}

class BlockActivityActionsPopover extends React.PureComponent<Props & WithStyles<ClassKeys>, State> {
    state: State = {
        activeBlockActivityAction: null,
    };

    private readonly onBlockActivityActionChoose = (blockActivityAction: BlockActivityAction) => {
        this.setState({ activeBlockActivityAction: blockActivityAction });
    };

    private getPopoverDetails(): [title: React.ReactNode, content: React.ReactNode] {
        const { activeBlockActivityAction } = this.state;
        const { classes, blockActivity } = this.props;
        const { trip } = blockActivity;

        if (activeBlockActivityAction) {
            return [
                (
                    <span className={classes.activityActionTitle}>
                        <IconButton onClick={this.clearBlockActivityAction} className={classes.back} size="large">
                            <ArrowBack />
                        </IconButton>
                        <BlockActivityActionLabel blockActivityAction={activeBlockActivityAction} />
                    </span>
                ),
                (
                    <div className={classes.blockActivityActionContainer}>
                        {this.renderBlockActivityActionComponent(activeBlockActivityAction)}
                    </div>
                ),
            ];
        }

        return [
            (
                <FormattedMessage id="blocks-overview.block-activity-actions.title" />
            ),
            (
                <DialogContent className={classes.blockActivityActionButtons}>
                    {blockActivity.valid
                        ? this.renderActionButton({ type: 'BLOCK_ACTIVITY_INVALIDATION' })
                        : this.renderActionButton({ type: 'BLOCK_ACTIVITY_VALIDATION' })}
                    {trip
                        ? this.renderActionButton({ type: 'TRIP_BEGIN_TIME_MODIFICATION', trip })
                        : this.renderActionButton({ type: 'BLOCK_ACTIVITY_TIME_MODIFICATION' })}
                    {this.renderActionButton({ type: 'BLOCK_CHANGE' })}
                    {trip && <>{this.renderActionButton({ type: 'STOP_SKIPPING', trip })}</>}
                </DialogContent>
            ),
        ];
    }

    private readonly clearBlockActivityAction = () => {
        this.setState({ activeBlockActivityAction: null });
    };

    private readonly onClose = () => {
        this.setState({ activeBlockActivityAction: null });
        const { onClose } = this.props;
        onClose();
    };

    private renderActionButton(blockActivityAction: BlockActivityAction) {
        return (
            <BlockActivityActionButton
                blockActivityAction={blockActivityAction}
                onClick={this.onBlockActivityActionChoose}
                label={<BlockActivityActionLabel blockActivityAction={blockActivityAction} />}
            />
        );
    }

    private renderBlockActivityActionComponent(blockActivityAction: BlockActivityAction) {
        const { activityModificationCompleted, blockActivity } = this.props;

        switch (blockActivityAction.type) {
            case 'BLOCK_ACTIVITY_VALIDATION':
                return (
                    <BlockActivityValidationChange
                        type="validate"
                        blockActivity={blockActivity}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
            case 'BLOCK_ACTIVITY_INVALIDATION':
                return (
                    <BlockActivityValidationChange
                        type="invalidate"
                        blockActivity={blockActivity}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
            case 'TRIP_BEGIN_TIME_MODIFICATION':
                return (
                    <TripBeginTimeModificationDialog
                        blockActivity={blockActivity}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
            case 'BLOCK_CHANGE':
                return (
                    <BlockActivityBlockChangeDialog
                        blockActivity={blockActivity}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
            case 'BLOCK_ACTIVITY_TIME_MODIFICATION':
                return (
                    <BlockActivityTimeModificationDialog
                        blockActivity={blockActivity}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
            case 'STOP_SKIPPING':
                return (
                    <TripStopSkippingModificationDialog
                        blockActivity={blockActivity}
                        tripKey={blockActivityAction.trip.tripKey}
                        activityModificationCompleted={activityModificationCompleted}
                    />
                );
        }
    }

    render() {
        const { anchorEl, open, classes } = this.props;
        const { activeBlockActivityAction } = this.state;

        const [title, content] = this.getPopoverDetails();

        return (
            <ClosablePopover
                dialogTitle={title}
                open={open}
                onClose={this.onClose}
                anchorOrigin={ANCHOR_ORIGIN}
                transformOrigin={TRANSFORM_ORIGIN}
                anchorEl={anchorEl}
                className={classes.paper}
                key={activeBlockActivityAction ? activeBlockActivityAction.type : ''}
            >
                {content}
            </ClosablePopover>
        );
    }
}

export default withStyles(styles)(BlockActivityActionsPopover);
