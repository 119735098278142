import type { CSSProperties } from '@mui/styles/withStyles';
import type { Moment } from 'moment';
import moment from 'moment';
import { stringify } from 'query-string';
import type {
    AutocompleteOption,
    BlockActivity,
    BlockId,
    BlockKey,
    BlockType,
    IsoDateString,
    Notification,
    NotificationSeverity,
    ResolvedCoreRealtimeVehicle,
    Route,
    ServiceDate,
    TripBase,
    TripKey,
    VehicleId,
    WithMeta,
} from '../../common/interfaces';
import { APP_CONFIG, isLargeNetwork } from './config';
import type { BlockIdSelection } from './containers/BlockSelectors/DriverBlockSelector';
import type { RouteSelectorRoute } from './containers/RouteSelector';
import type { TranslatedStringInput } from './graphql/graphql-typings';

export const EMPTY_AUTOCOMPLETE_OPTION = {
    label: '',
    value: '',
};

export type Invalid = WithMeta<string, { format: 'invalid' }>;

export const getGtfsEntityOption = <T extends { [P in K]: string } & { id: string }, K extends string>(
    gtfsEntity: T,
    key: K,
): AutocompleteOption => ({ value: gtfsEntity.id, label: gtfsEntity[key] });

export function defaultString(text?: string) {
    return text || '';
}

export function routeBackgroundColor(route: Pick<Route, 'color'>): CSSProperties {
    return { backgroundColor: `#${route.color}` };
}

export function routeTextColor(route: Pick<Route, 'textColor'>): CSSProperties {
    return { color: `#${route.textColor}` };
}

export function getMapUrl(
    trip: Pick<TripBase, 'serviceDate' | 'tripId'> | null,
    vehicle?: Pick<ResolvedCoreRealtimeVehicle, 'latitude' | 'longitude'>,
): string {
    const { MAP_URL } = APP_CONFIG;

    if (trip) {
        return `${MAP_URL}/trip/${trip.tripId}/${trip.serviceDate}`;
    }
    if (vehicle?.latitude && vehicle.longitude) {
        return `${MAP_URL}/?map=15/${vehicle.latitude}/${vehicle.longitude}`;
    }
    return `${MAP_URL}`;
}

export function getInternalMapUrl(
    trip: Pick<TripBase, 'tripKey'> | null,
    vehicle?: Pick<ResolvedCoreRealtimeVehicle, 'vehicleId'>,
): string {
    if (vehicle) {
        return getInternalVehicleMapUrl(vehicle.vehicleId);
    }
    if (trip) {
        return getInternalTripMapUrl(trip.tripKey);
    }
    return '/map';
}

export function getInternalVehicleMapUrl(vehicleId: VehicleId): string {
    return `/map?${stringify({ 'vehicle-id': vehicleId })}`;
}

export function getInternalTripMapUrl(tripKey: TripKey): string {
    return `/map?${stringify({ 'trip-key': tripKey })}`;
}

export function getDirectionText(directionId?: string | null) {
    switch (directionId) {
        case '0':
            return '+';
        case '1':
            return '−';
        default:
            return '';
    }
}

export function isNotificationClosed(notification: Notification): boolean {
    return notification.closed !== null;
}

export function getVehicleBlockId(blockActivity: BlockActivity) {
    return blockActivity.modifiedVehicleBlockId || blockActivity.originalVehicleBlockId!;
}

export function getVehicleBlockKey(blockActivity: BlockActivity): BlockKey {
    return getBlockKey('VEHICLE', getVehicleBlockId(blockActivity), blockActivity.serviceDate);
}

export function getDriverBlockId(blockActivity: BlockActivity) {
    return blockActivity.modifiedDriverBlockId || blockActivity.originalDriverBlockId;
}

export function getDriverBlockKey(blockActivity: BlockActivity): BlockKey | null {
    return getDriverBlockId(blockActivity) ? getBlockKey('DRIVER', getDriverBlockId(blockActivity)!, blockActivity.serviceDate) : null;
}

export function getBlockKey(blockType: BlockType, blockId: BlockId, activityServiceDate: ServiceDate): BlockKey {
    return `${activityServiceDate}:${blockType}:${blockId}` as BlockKey;
}

const notificationSeverities: { [P in NotificationSeverity]: number } = {
    FEEDBACK: 1,
    INFO: 2,
    WARNING: 3,
    ERROR: 4,
};

export function getNotificationNumericSeverity(severity: NotificationSeverity): number {
    return notificationSeverities[severity] || 0;
}

const SERVICE_DATE_FORMAT = 'YYYYMMDD';

export function serviceDateToMoment(serviceDate: ServiceDate): Moment {
    return moment(serviceDate, SERVICE_DATE_FORMAT);
}

export function momentToServiceDate(date: Moment): ServiceDate {
    return date.format(SERVICE_DATE_FORMAT) as ServiceDate;
}

export function minMaxMomentAroundServiceDate(serviceDate: ServiceDate): { minDate: Moment; maxDate: Moment } {
    return {
        minDate: serviceDateToMoment(serviceDate).subtract(1, 'days'),
        maxDate: serviceDateToMoment(serviceDate).add(1, 'days'),
    };
}

export function convertBlockIdSelection(driverBlockId: BlockIdSelection): BlockId | null {
    return typeof driverBlockId === 'string' ? (driverBlockId as BlockId) : null;
}

export function getBeginTime(blockActivity: BlockActivity): IsoDateString {
    return blockActivity.modifiedBeginTime || blockActivity.originalBeginTime;
}

export function getEndTime(blockActivity: BlockActivity): IsoDateString {
    return blockActivity.modifiedEndTime || blockActivity.originalEndTime;
}

export function getDefaultRouteFilterValue() {
    return isLargeNetwork() ? [] : null;
}

interface ItemContainer<T> {
    items: T[];
}

export function mergeItemContainers<T, P extends ItemContainer<T>>(oldData: P | undefined, newData: P): P {
    if (!oldData) {
        return newData;
    }

    const items = [...oldData.items];

    for (const item of newData.items) {
        const index = items.indexOf(item);

        if (index === -1) {
            items.push(item);
        } else {
            items[index] = item;
        }
    }

    return {
        ...newData,
        items,
    };
}

export function routeIdsToRoutes(routeIds: string[], routes: RouteSelectorRoute[]): RouteSelectorRoute[] {
    return routeIds.map(routeId => routes.find(r => r.id === routeId)).filter((r): r is RouteSelectorRoute => r !== undefined);
}

export function translatedStrings<T extends { [P in K]: string }, K extends string>(
    value: { hu: T; en: T },
    field: K,
): TranslatedStringInput[] {
    return (['hu', 'en'] as const).map<TranslatedStringInput>(locale => ({
        locale,
        text: value[locale][field],
    }));
}
