import type { DialogActionsProps } from '@mui/material/DialogActions';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    root: {
        padding: 24,
    },
});

const ContainedDialogActions: React.FunctionComponent<DialogActionsProps> = ({ children, ...rest }) => {
    const classes = useStyles();

    return (
        <DialogActions className={classes.root} {...rest}>
            {children}
        </DialogActions>
    );
};

export default ContainedDialogActions;
