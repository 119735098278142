import React from 'react';
import type { FormattedStopFragment } from '../../graphql/graphql-typings';

interface Props {
    stop: FormattedStopFragment;
}

const FormattedStop: React.FunctionComponent<Props> = ({ stop }) => (
    <>
        {stop.name}
        {' '}
        {stop.code && `(${stop.code})`}
    </>
);

export default FormattedStop;
