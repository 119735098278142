import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CardHeader from '@mui/material/CardHeader';
import { FormattedMessage } from 'react-intl';
import type { TripKey } from '../../../../common/interfaces';
import TripDetailsContent from '../../components/TripDetailsContent';
import VehicleDetailsContent from '../../components/VehicleDetailsContent';
import CloseButton from '../../components/CloseButton';
import MapCardContent from '../../components/MapCardContent';
import MapCard from '../../components/MapCard';
import ZoomTo from './ZoomTo';
import { useTripQuery } from '../../graphql/graphql-typings';

interface Props {
    tripKey: TripKey;
    onClose: () => void;
}

const TripPanel: React.FunctionComponent<Props> = ({ tripKey, onClose }) => {
    const queryResult = useTripQuery({
        variables: { tripKey },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    return (
        <MapCard>
            <CardHeader title={<FormattedMessage id="map.trip-panel.title" />} action={<CloseButton onClose={onClose} />} />

            <MapCardContent>
                {queryResult.loading && <CircularProgress />}
                {queryResult.data?.tripWithSchedule && (
                    <>
                        {queryResult.data.tripWithSchedule.vehicle ? (
                            <>
                                <VehicleDetailsContent vehicle={queryResult.data.tripWithSchedule.vehicle} />

                                <TripDetailsContent trip={queryResult.data.tripWithSchedule} />
                            </>
                        ) : (
                            <TripDetailsContent trip={queryResult.data.tripWithSchedule} />
                        )}
                        <ZoomTo
                            trip={queryResult.data.tripWithSchedule}
                            vehicles={queryResult.data.tripWithSchedule.vehicle && [queryResult.data.tripWithSchedule.vehicle]}
                        />
                    </>
                )}
            </MapCardContent>
        </MapCard>
    );
};

export default TripPanel;
