import { parse } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import type { UrlParam } from '../utils/query-params-util';

function useQueryParamReader() {
    const location = useLocation();

    return useMemo(() => <K extends string>() => parse(location.search) as { [key in K]: UrlParam }, [location.search]);
}

export default useQueryParamReader;
