import type { AlertInput, TransitAlertDetailsFragment, TranslatedStringFragment } from '../../graphql/graphql-typings';
import { TransitAlertStatus } from '../../graphql/graphql-typings';
import { translatedStrings } from '../../utils';
import type { AlertMessageFormValue, TransitAlertFormValue } from './TransitAlertFormValue';

export const LANG_HU = 'hu';
export const LANG_EN = 'en';

export function alertFromFragment(alertFragment: TransitAlertDetailsFragment): TransitAlertFormValue {
    return {
        startTime: alertFragment.startTime,
        endTime: alertFragment.endTime,
        hu: messageInputFromFragment(alertFragment, LANG_HU),
        en: messageInputFromFragment(alertFragment, LANG_EN),
        enabled: alertFragment.status === TransitAlertStatus.Active,
        routeIds: alertFragment.routes.map(route => route.id),
    };
}

function messageInputFromFragment(alertFragment: TransitAlertDetailsFragment, locale: string): AlertMessageFormValue {
    return {
        summary: findLocaleOrDefault(alertFragment.summary, locale),
        description: findLocaleOrDefault(alertFragment.description, locale),
    };
}

function findLocaleOrDefault(translations: TranslatedStringFragment[], locale: string) {
    return translations.find(e => e.locale === locale)?.text || '';
}

export function alertInputFromFormValue(formValue: TransitAlertFormValue): AlertInput {
    return {
        startTime: formValue.startTime,
        endTime: formValue.endTime,
        summary: translatedStrings(formValue, 'summary'),
        description: translatedStrings(formValue, 'description'),
        enabled: formValue.enabled,
        routeIds: formValue.routeIds,
    };
}
