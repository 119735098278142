import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FormattedMessage } from 'react-intl';
import Table from '@mui/material/Table';
import React from 'react';
import { getDirectionText } from '../../utils';
import type { DisplayedTripFragment } from '../../graphql/graphql-typings';

interface Props {
    trip: DisplayedTripFragment;
}

const TripDetailsTable: React.FC<Props> = ({ trip }) => (
    <Table size="small">
        <TableBody>
            <TableRow>
                <TableCell>
                    <FormattedMessage id="trip-details-content.trip-key" />
                </TableCell>
                <TableCell>{trip.tripKey}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <FormattedMessage id="trip-details-content.headsign" />
                </TableCell>
                <TableCell>{trip.headsign}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <FormattedMessage id="trip-details-content.route" />
                </TableCell>
                <TableCell>
                    {trip.route.shortName}
                    {getDirectionText(trip.directionId)}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
);

export default TripDetailsTable;
