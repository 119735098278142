import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { BlockActivity } from '../../../../common/interfaces';
import { PopupRow } from '../../components/BlockOverviewPopup';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import { BLOCK_ACTIVITY_VALIDATION_CHANGE } from '../../graphql/rest';
import SaveButton from './SaveButton';

interface Props {
    blockActivity: BlockActivity;
    activityModificationCompleted: () => void;
    type: 'invalidate' | 'validate';
}

const BlockActivityValidationChangeDialog: React.FunctionComponent<Props> = (props) => {
    const { blockActivity, activityModificationCompleted, type } = props;

    const mutationOptions = {
        variables: { blockActivityKey: blockActivity.activityKey, input: { type } },
    };

    return (
        <>
            <DialogContent>
                <PopupRow label={<FormattedMessage id="blocks-overview.block-activity.validity" />}>
                    <FormattedMessage id={type === 'validate'
                        ? 'blocks-overview.block-activity.validity-yes'
                        : 'blocks-overview.block-activity.validity-no'}
                    />
                </PopupRow>
            </DialogContent>
            <ContainedDialogActions>
                <SaveButton
                    blockActivity={blockActivity}
                    graphQlQuery={BLOCK_ACTIVITY_VALIDATION_CHANGE}
                    mutationOptions={mutationOptions}
                    activityModificationCompleted={activityModificationCompleted}
                />
            </ContainedDialogActions>
        </>
    );
};

export default BlockActivityValidationChangeDialog;
