import type { Dictionary } from 'lodash';
import forEach from 'lodash/forEach';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import max from 'lodash/max';
import min from 'lodash/min';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import type {
    BlockActivitiesResponse,
    BlockActivity,
    BlockId,
    BlockKey,
    ResolvedCoreRealtimeVehicle,
    ServiceDate,
} from '../../../common/interfaces';
import { INVALID_BLOCK_ID } from '../constants';
import type { Invalid } from '../utils';
import {
    getBeginTime,
    getBlockKey,
    getDefaultRouteFilterValue,
    getDriverBlockId,
    getDriverBlockKey,
    getEndTime,
    getNotificationNumericSeverity,
    getVehicleBlockId,
    getVehicleBlockKey,
    momentToServiceDate,
} from '../utils';
import type { AppAction, BaseFetchableAction, BlockActivitiesRequestParams } from './actions';
import { getBaseStateValue, isZeroBlock, sortActivities } from './functions';
import type { DriverBlock, VehicleBlock, VehicleBlockState } from './state';

type BlockKeyOrInvalid = BlockKey | Invalid;

export type BlockIdOrInvalid = BlockId | Invalid;

function getBaseVehicleBlockState(): VehicleBlockState {
    return {
        ...getBaseStateValue([]),
        serviceDate: momentToServiceDate(moment().add(-2, 'hours')),
        routes: getDefaultRouteFilterValue(),
        paramsChanged: true,
        sortingCondition: { type: 'blockId' },
        lockVehicleBlockVersions: {},
    };
}

function getDriverBlocksFromVehicleBlock(vehicleBlock: VehicleBlock, responseData: BlockActivitiesResponse): DriverBlock[] {
    const activitiesWithDriverBlockId = vehicleBlock.activities.filter(activity => getDriverBlockId(activity));
    const groupedActivities = groupBy(activitiesWithDriverBlockId, a => getDriverBlockKey(a));
    const driverBlocks: DriverBlock[] = [];
    forEach(groupedActivities, (activities, driverBlockKey) => {
        const firstActivity = activities[0];
        driverBlocks.push({
            blockId: getDriverBlockId(firstActivity)!,
            blockServiceDate: firstActivity.serviceDate,
            blockKey: driverBlockKey as BlockKey,
            blockVersion: responseData.driverBlockVersions[driverBlockKey] || 0,
            begin: getBeginTime(activities[0]),
            end: getEndTime(activities[activities.length - 1]),
        });
    });
    return driverBlocks;
}

function handleModifiedBlockVersions(responseData: BlockActivitiesResponse, vehicleBlocksById: Map<string, VehicleBlock>) {
    for (const blockKey of Object.keys(responseData.vehicleBlockVersions)) {
        const blockVersion = responseData.vehicleBlockVersions[blockKey];
        const block: VehicleBlock | undefined = vehicleBlocksById.get(blockKey);
        if (block) {
            block.blockVersion = blockVersion;
            block.driverBlocks = getDriverBlocksFromVehicleBlock(block, responseData);
        }
    }
}

function createNewVehicleBlock(
    vehicleBlockKey: BlockKey,
    blockId: BlockIdOrInvalid,
    serviceDate: ServiceDate,
    vehicleBlockVersion: number,
): VehicleBlock {
    return {
        blockId,
        blockServiceDate: serviceDate,
        blockKey: vehicleBlockKey,
        blockVersion: vehicleBlockVersion,
        activities: [],
        vehicles: [],
        driverBlocks: [],
        notifications: [],
    };
}

function getBlockKeyOrInvalid(activity: BlockActivity): BlockKeyOrInvalid {
    if (activity.valid) {
        return getVehicleBlockKey(activity);
    }
    return INVALID_BLOCK_ID;
}

function getBlockIdOrInvalid(activity: BlockActivity): BlockIdOrInvalid {
    if (activity.valid) {
        return getVehicleBlockId(activity);
    }
    return INVALID_BLOCK_ID;
}

function updateBlockInMap(
    vehicleBlocksById: Map<BlockKeyOrInvalid, VehicleBlock>,
    vehicleBlockKey: BlockKeyOrInvalid,
    activities: BlockActivity[],
) {
    let block = vehicleBlocksById.get(vehicleBlockKey);
    if (!block) {
        const firstActivity = activities[0];
        block = createNewVehicleBlock(vehicleBlockKey as BlockKey, getBlockIdOrInvalid(firstActivity), firstActivity.serviceDate, 0);
        vehicleBlocksById.set(vehicleBlockKey, block);
    }
    block.activities.push(...activities);
    block.activities.sort((a, b) => getBeginTime(a).localeCompare(getBeginTime(b)));
}

function handleModifiedActivities(modifiedActivities: BlockActivity[], vehicleBlocksById: Map<BlockKeyOrInvalid, VehicleBlock>) {
    const modifiedActivitiesByBlock = groupBy(modifiedActivities, getBlockKeyOrInvalid);

    forEach(modifiedActivitiesByBlock, (activities, vehicleBlockKey) => {
        updateBlockInMap(vehicleBlocksById, vehicleBlockKey as BlockKeyOrInvalid, activities);
    });
}

function excludeModifiedActivitiesFromBlocksInState(blocksInState: VehicleBlock[], modifiedActivities: BlockActivity[]) {
    const modifiedActivityKeys = modifiedActivities.map(activity => activity.activityKey);

    return blocksInState.map(block => ({
        ...block,
        activities: block.activities.filter(activity => !modifiedActivityKeys.includes(activity.activityKey)),
    }));
}

function handleActivitiesAction(
    state: VehicleBlockState,
    action: BaseFetchableAction<'ACTIVITIES', [BlockActivitiesRequestParams]>,
): VehicleBlockState {
    const { sortingCondition } = state;
    const { list: responseData, ...rest } = action.data;
    const [params] = action.params;

    if (!responseData) {
        return { ...state, ...rest };
    }

    if (state.serviceDate !== params.serviceDate || state.routes !== params.routes) {
        // Received a response for the old configuration, the new data is still loading, ignoring this requst.
        return state;
    }
    if (state.paramsChanged && params.cursor) {
        // Received a response for the new configuration with a cursor, before the base data could be loaded.
        return state;
    }

    const { activities } = responseData;

    const filteredBlockActivities = activities.filter(activity => !isZeroBlock(getVehicleBlockId(activity)));

    const vehicleBlocks = params.cursor ? excludeModifiedActivitiesFromBlocksInState(state.list, filteredBlockActivities) : [];
    const vehicleBlocksById = new Map<BlockKeyOrInvalid, VehicleBlock>(vehicleBlocks.map(b => [b.blockKey, b]));

    handleModifiedActivities(filteredBlockActivities, vehicleBlocksById);
    handleModifiedBlockVersions(responseData, vehicleBlocksById);

    const blocksWithActivities = Array.from(vehicleBlocksById.values()).filter(block => block.activities.length > 0);
    const blockList = sortActivities(blocksWithActivities, sortingCondition);

    const blockBeginTime = blockList.length > 0 ? new Date(min(blockList.map(block => getBeginTime(block.activities[0])))!) : undefined;
    const blockEndTime = blockList.length > 0
        ? new Date(max(blockList.map(block => getEndTime(block.activities[block.activities.length - 1])))!)
        : undefined;

    return {
        ...state,
        ...rest,
        paramsChanged: false,
        cursor: responseData.cursor,
        list: blockList,
        begin: deduplicateDate(state.begin, blockBeginTime),
        end: deduplicateDate(state.end, blockEndTime),
    };
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function vehicleBlocksReducer(state: VehicleBlockState = getBaseVehicleBlockState(), action: AppAction): VehicleBlockState {
    // TODO: handle unassigned vehicles
    const { sortingCondition, routes: routeFilter } = state;

    if (action.type === 'ACTIVITIES') {
        return handleActivitiesAction(state, action);
    }
    if (action.type === 'VEHICLES' && action.data.list) {
        const { vehicles } = action.data.list;
        const { list: prevData, ...rest } = state;

        const filteredVehicles = filterVehicles(vehicles, routeFilter);
        const sortedVehicles = sortBy(filteredVehicles, 'licensePlate');
        const groupedVehicles = groupBy(sortedVehicles, v => v.vehicleBlockKey);

        const [newData, updatedGroupedVehicles] = mergeBlockAttribute(prevData, 'vehicles', groupedVehicles);

        forEach(updatedGroupedVehicles, (vehiclesForBlock, blockKey) => {
            const firstVehicle = vehiclesForBlock[0];
            if (firstVehicle.blockServiceDate !== state.serviceDate) {
                return;
            }
            newData.push({
                blockId: firstVehicle.vehicleBlockId!,
                blockServiceDate: firstVehicle.blockServiceDate,
                blockKey: blockKey as BlockKey,
                blockVersion: 0,
                activities: [],
                vehicles: vehiclesForBlock,
                driverBlocks: [],
                notifications: [],
            });
        });

        return { list: sortActivities(newData, sortingCondition), ...rest };
    }
    if (action.type === 'NOTIFICATIONS' && action.data.list) {
        const notifications = action.data.list;
        const { list: prevData, ...rest } = state;

        const filteredNotification = notifications.filter(n => n.vehicleBlockId && !n.closed);
        const sortedNotifications = orderBy(
            filteredNotification,
            [n => getNotificationNumericSeverity(n.severity), n => n.created],
            ['desc', 'desc'],
        );
        const groupedNotifications = groupBy(sortedNotifications, n => getBlockKey('VEHICLE', n.vehicleBlockId!, state.serviceDate));

        const newData = mergeBlockAttribute(prevData, 'notifications', groupedNotifications)[0];

        return { list: newData, ...rest };
    }
    if (action.type === 'START_BLOCK_ACTIVITY_EDIT') {
        const lockVehicleBlockVersions = fromPairs(state.list.map(v => [v.blockKey, v.blockVersion]));
        return { ...state, lockVehicleBlockVersions };
    }
    if (action.type === 'VEHICLE_BLOCK_SORTING_CONDITION_CHANGE') {
        return { ...state, sortingCondition: action.sortingCondition };
    }
    if (action.type === 'CHANGE_SERVICE_DATE') {
        return { ...state, serviceDate: action.serviceDate, paramsChanged: true };
    }
    if (action.type === 'CHANGE_ROUTES') {
        const newState = { ...state, routes: action.routes, paramsChanged: true };
        if (action.routes && action.routes.length === 0) {
            newState.list = [];
            newState.begin = undefined;
            newState.end = undefined;
        }
        return newState;
    }

    return state;
}

function mergeBlockAttribute<K extends keyof VehicleBlock>(
    prevData: VehicleBlock[],
    key: K,
    valuesByBlocks: Dictionary<unknown[] & VehicleBlock[K]>,
): [VehicleBlock[], Dictionary<unknown[] & VehicleBlock[K]>] {
    const valuesByBlocksMap = new Map(Object.entries(valuesByBlocks));
    return [prevData.map((block) => {
        const { blockKey } = block;

        const newValue = valuesByBlocksMap.get(blockKey) || [];
        const oldValue = block[key];
        if (newValue.length === 0 && (oldValue as unknown[]).length === 0) {
            // Value left unchanged, we can return the same instance thereby improving performance thanks to React's strict equality checks
            return block;
        }

        valuesByBlocksMap.delete(blockKey);

        return {
            ...block,
            [key]: newValue,
        };
    }), Object.fromEntries(valuesByBlocksMap)];
}

function filterVehicles(vehicles: ResolvedCoreRealtimeVehicle[], routes: string[] | null): ResolvedCoreRealtimeVehicle[] {
    return vehicles.filter(v => routes ? v.routeId && routes.includes(v.routeId) : v.vehicleBlockKey && !isZeroBlock(v.vehicleBlockId));
}

function deduplicateDate(originalDate: Date | undefined, modifiedDate: Date | undefined) {
    if (originalDate && modifiedDate && originalDate.valueOf() === modifiedDate.valueOf()) {
        return originalDate;
    }
    return modifiedDate;
}
