import Book from '@mui/icons-material/Book';
import ChatBubble from '@mui/icons-material/ChatBubble';
import Commute from '@mui/icons-material/Commute';
import Map from '@mui/icons-material/Map';
import Traffic from '@mui/icons-material/Traffic';
import Error from '@mui/icons-material/Error';
import { SidebarItem } from '@realcity/web-frame/lib/components/Sidebar/SidebarItem';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Roles } from './constants';
import { APP_CONFIG, isDrtEnabled, isInternalMapEnabled, isMessagesEnabled } from './config';

const MainMenu: React.FunctionComponent = () => (
    <>
        {isInternalMapEnabled() ? (
            <SidebarItem icon={Map} text={<FormattedMessage id="main-menu.map" />} path="/map" />
        ) : (
            <SidebarItem icon={Map} text={<FormattedMessage id="main-menu.map" />} path={APP_CONFIG.MAP_URL} external />
        )}
        <SidebarItem icon={Traffic} text={<FormattedMessage id="main-menu.blocks" />} path="/blocks" />
        <SidebarItem icon={Commute} text={<FormattedMessage id="main-menu.vehicles" />} path="/vehicles" />
        {isMessagesEnabled() && <SidebarItem icon={ChatBubble} text={<FormattedMessage id="main-menu.messages" />} path="/messages" />}
        {isDrtEnabled() && <SidebarItem icon={Book} text={<FormattedMessage id="main-menu.bookings" />} path="/bookings" />}
        <IfRole role={Roles.AlertsRead}>
            <SidebarItem icon={Error} text={<FormattedMessage id="main-menu.alerts" />} path="/alerts" />
        </IfRole>
    </>
);

export default MainMenu;
