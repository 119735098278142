import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Notifications from 'react-web-notification';
import type { Notification } from '../../../../common/interfaces';
import type { AppState, DesktopNotificationsData } from '../../redux/state';
import { getNotificationTitle } from './utils';

interface Props {
    desktopNotifications: DesktopNotificationsData;
}

const DesktopNotifications: React.FC<Props> = ({ desktopNotifications }) => {
    const intl = useIntl();

    return (
        <>
            {desktopNotifications.notifications.map((notification: Notification) => {
                const { id } = notification;

                return (
                    <Notifications
                        key={id}
                        timeout={10000}
                        title={getNotificationTitle(intl, notification)}
                        onClick={notificationClicked}
                        options={{ tag: `${id}`, body: getNotificationBody(notification) }}
                    />
                );
            })}
        </>
    );
};

function getNotificationBody(notification: Notification): string {
    const { vehicle } = notification;
    if (!vehicle) {
        return '';
    }

    const { licensePlate, driverName } = vehicle;
    return `${licensePlate || ''} ${driverName || ''}`;
}

function notificationClicked() {
    window.focus();
}

export default connect(({ desktopNotifications }: AppState) => ({ desktopNotifications }))(DesktopNotifications);
