import * as React from 'react';
import SendMessageForm from './SendMessageForm';
import SentMessages from './SentMessages';

const MessagesPage: React.FC = () => (
    <>
        <SendMessageForm />
        <SentMessages />
    </>
);

export default MessagesPage;
