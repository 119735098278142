import React, { useEffect, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import FilterRouteDialog from '../../containers/FilterRouteDialog';
import FilteredRoutesRow from './FilteredRoutesList';
import { getDefaultRouteFilterValue } from '../../utils';
import { asList } from '../../utils/query-params-util';
import { changeRoutes } from '../../redux/actions';
import useQueryParamWriter from '../../hooks/useQueryParamWriter';
import useQueryParamReader from '../../hooks/useQueryParamReader';
import type AppState from '../../redux/state';

// Trick to avoid creating an empty array on each render which degrades the performance because of the strict equality checks.
const emptyArray: never[] = [];

const RouteFilter: React.FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    useEffect(() => {
        const { route } = readQueryParam<'route'>();

        if (route) {
            const routeIds = asList(route);
            dispatch(changeRoutes(routeIds.length > 0 ? routeIds : getDefaultRouteFilterValue()));
        } else {
            dispatch(changeRoutes(getDefaultRouteFilterValue()));
        }
    }, [dispatch, readQueryParam]);

    const routeIds = useSelector<AppState, string[] | null>(state => state.vehicleBlocks.routes);

    const openFilterRouteDialog = () => {
        setDialogOpen(true);
    };

    const closeFilterRouteDialog = () => {
        setDialogOpen(false);
    };

    const onRoutesSelected = (selectedRouteIds: string[]) => {
        writeQueryParams({ route: selectedRouteIds });
    };

    return (
        <>
            <Tooltip title={<FormattedMessage id="route-selector.title" />} placement="top">
                <IconButton onClick={openFilterRouteDialog} size="large">
                    <FilterList />
                </IconButton>
            </Tooltip>
            <FilteredRoutesRow routeIds={routeIds} />
            <FilterRouteDialog
                open={dialogOpen}
                onClose={closeFilterRouteDialog}
                onAccept={onRoutesSelected}
                selectedRouteIds={routeIds || emptyArray}
            />
        </>
    );
};

export default RouteFilter;
