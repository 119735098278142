import type { Invalid } from './utils';

export const Roles = {
    Admin: 'ADMIN',
    Read: 'DISPATCH_READ',
    Write: 'DISPATCH_WRITE',
    Message: 'DISPATCH_MESSAGE',
    AlertsRead: 'ALERTS_READ',
    AlertsWrite: 'ALERTS_WRITE',
};

export const INVALID_BLOCK_ID = 'INVALID' as Invalid;
