import sortBy from 'lodash/sortBy';
import type { VehicleBlock, VehicleBlockSortingCondition } from './state';
import type { Loadable } from './actions';
import { getBeginTime } from '../utils';

export function sortActivities(vehicleBlocks: VehicleBlock[], sortingCondition: VehicleBlockSortingCondition): VehicleBlock[] {
    switch (sortingCondition.type) {
        case 'blockId':
            return sortBy(vehicleBlocks, ({ blockId }) => parseInt(blockId, 10));
        case 'beginTime':
            return sortBy(vehicleBlocks, ({ activities }) => getBeginTime(activities[0]));
        case 'nearestDeparture':
            return sortBy(vehicleBlocks, ({ activities }) => {
                const nearestTrip = activities
                    .filter(activity => activity.trip)
                    .find(activity => getBeginTime(activity) >= sortingCondition.clickTime);
                return nearestTrip ? getBeginTime(nearestTrip) : undefined;
            });
    }
}

export function isZeroBlock(blockId: string | null) {
    return blockId === '0';
}

export function getBaseStateValue<T>(initial: T): Loadable<T> {
    return { list: initial, loading: false };
}
