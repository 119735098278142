import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { DateTimePickerProps } from '@mui/lab/DateTimePicker';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import Event from '@mui/icons-material/Event';
import type { DateType } from '@date-io/type';
import type { Moment } from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import DateTimePickerDialog from './DateTimePickerDialog';

interface RenderInputOptions {
    openPicker: () => void;
    fullWidth?: boolean;
    error?: boolean;
    variant?: TextFieldProps['variant'];
}

const createRenderInput = ({ openPicker, fullWidth, error, variant }: RenderInputOptions) => ({ label, ...props }: TextFieldProps) => (
    <TextField
        label={props.error ? <FormattedMessage id="date-time-picker.invalid" /> : label}
        {...props}
        InputProps={{
            ...props.InputProps,
            endAdornment: (
                <IconButton onClick={openPicker}>
                    <Event />
                </IconButton>
            ),
            sx: {
                paddingRight: 0,
            },
        }}
        fullWidth={fullWidth}
        error={error}
        variant={variant}
    />
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {
}

interface Props extends Omit<RenderInputOptions, 'openPicker'>, Omit<DateTimePickerProps<Moment>, 'onChange' | 'renderInput'> {
    onChange?: (value: DateType | null) => void;
}

const DateTimePicker = forwardRef<HTMLDivElement, Props>(({ onChange, fullWidth, error, variant, ...rest }, ref) => {
    const intl = useIntl();
    const [showPicker, setShowPicker] = useState(false);

    const format = intl.formatMessage({ id: 'date-time-picker.format' });
    const mask = intl.formatMessage({ id: 'date-time-picker.mask' });

    const openPicker = useCallback(
        () => {
            setShowPicker(true);
        },
        [],
    );

    const onDialogClose = (newValue: DateType | null) => {
        if (newValue !== null) {
            onChange?.(newValue);
        }

        setShowPicker(false);
    };

    const renderInput = useMemo(
        () => createRenderInput({ openPicker, fullWidth, error, variant: variant ?? 'filled' }),
        [openPicker, fullWidth, error, variant],
    );

    const commonProps: Omit<DateTimePickerProps<Moment>, 'onChange'> = {
        ampm: format.includes('a'),
        inputFormat: format,
        mask,
        renderInput,
        ...rest,
    };

    return (
        <>
            <DesktopDateTimePicker
                onChange={onChange || noop}
                disableOpenPicker
                ref={ref}
                {...commonProps}
            />

            <DateTimePickerDialog open={showPicker} onDialogClose={onDialogClose} {...commonProps} />
        </>
    );
});

export default DateTimePicker;
