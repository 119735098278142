import React from 'react';
import { FormattedTime } from 'react-intl';
import type { IsoDateString } from '../../../../common/interfaces';

interface Props {
    arrival: IsoDateString;
    departure: IsoDateString;
}

const FormatArrivalAndDeparture: React.FunctionComponent<Props> = ({ arrival, departure }) => (
    <span>
        <FormattedTime value={arrival} />
        {' - '}
        <FormattedTime value={departure} />
    </span>
);

export default FormatArrivalAndDeparture;
