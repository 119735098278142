import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import type { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from 'react-router-dom';
import RequestErrorMessage from '@realcity/web-frame/lib/components/Message/RequestErrorMessage';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Roles } from '../../constants';
import { useTransitAlertsQuery } from '../../graphql/graphql-typings';
import TransitAlertTableRow from './TransitAlertTableRow';
import PageHeader from '../../components/PageHeader';
import usePagination from '../../hooks/usePagination';
import CustomTablePaginationActions from './CustomTablePaginationActions';

const ONE_MINUTE = 60 * 1000;

const DEFAULT_PAGE_SIZE = 10;

const PAGE_SIZE_OPTIONS = [10, 25, 100];

const DisplayedRows: React.FC<LabelDisplayedRowsArgs> = ({ from, to, count }) => {
    const intl = useIntl();

    const countText = count !== -1 ? count : intl.formatMessage({ id: 'table-pagination.unknown-count' }, { estimate: to });

    return <FormattedMessage id="table-pagination.current-page" values={{ from, to, count: countText }} />;
};

const TransitAlertPage: React.FC = () => {
    const { offset, limit, page, handlePageChange, handleChangeRowsPerPage } = usePagination(0, DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS);

    const { loading, error, data, previousData } = useTransitAlertsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            pagination: { offset, limit },
        },
        pollInterval: ONE_MINUTE,
    });

    const lastData = data || previousData;
    const lastResult = lastData ? lastData.alerts : null;

    const onPageChange = (event: unknown, newPage: number) => {
        handlePageChange(newPage);
    };

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeRowsPerPage(parseInt(event.target.value, 10));
    };

    return (
        <>
            <PageHeader title={<FormattedMessage id="alerts.title" />}>
                <IfRole role={Roles.AlertsWrite}>
                    <Button variant="outlined" component={Link} to="/alerts/new">
                        <FormattedMessage id="alerts.create" />
                    </Button>
                </IfRole>
            </PageHeader>

            <IfRoleOrUnauthorizedFeatureMessage role={Roles.AlertsRead}>
                <TableContainer sx={{ position: 'relative', overflowY: 'hidden' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><FormattedMessage id="alerts.data.title" /></TableCell>
                                <TableCell><FormattedMessage id="alerts.data.status" /></TableCell>
                                <TableCell><FormattedMessage id="alerts.data.routes" /></TableCell>
                                <TableCell><FormattedMessage id="alerts.data.start-time" /></TableCell>
                                <TableCell><FormattedMessage id="alerts.data.end-time" /></TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex' }}>
                                        <FormattedMessage id="alerts.data.last-modified" />
                                        <ArrowDownwardIcon sx={{ color: '#bbb', marginLeft: 1 }} />
                                    </Box>
                                </TableCell>
                                <IfRole role={Roles.AlertsWrite}>
                                    <TableCell />
                                </IfRole>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lastResult?.items.map(alert => <TransitAlertTableRow key={alert.id} alert={alert} />)}
                        </TableBody>
                    </Table>

                    {
                        lastResult?.paginationInfo && (
                            <TablePagination
                                sx={{ overflow: 'unset' }}
                                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                                count={lastResult.paginationInfo.total ?? -1}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                                component="div"
                                labelRowsPerPage={<FormattedMessage id="table-pagination.rows-per-page" />}
                                labelDisplayedRows={DisplayedRows}
                                showFirstButton
                                showLastButton
                                ActionsComponent={CustomTablePaginationActions}
                            />
                        )
                    }

                    <Backdrop open={Boolean(loading || error)} style={{ position: 'absolute' }}>
                        {loading && <CircularProgress />}
                        {error && <RequestErrorMessage />}
                    </Backdrop>
                </TableContainer>
            </IfRoleOrUnauthorizedFeatureMessage>
        </>
    );
};

export default TransitAlertPage;
