import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCreateTransitAlertMutation } from '../../graphql/graphql-typings';
import { alertInputFromFormValue } from './alert-utils';
import TransitAlertForm from './TransitAlertForm';
import type { TransitAlertFormValue } from './TransitAlertFormValue';
import TransitAlertProgress from './TransitAlertProgress';

const CreateAlertForm: React.FC = () => {
    const [executeCreateAppAlert, mutationResult] = useCreateTransitAlertMutation();

    const createAlert = (formValue: TransitAlertFormValue) => {
        const alertInput = alertInputFromFormValue(formValue);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        executeCreateAppAlert({
            variables: {
                alertInput,
            },
        });
    };

    return (
        <>
            <TransitAlertForm
                defaultValue={{ startTime: moment().toISOString(), endTime: null, enabled: true, routeIds: [] }}
                title={<FormattedMessage id="alert-editor.creating-title" />}
                onSubmit={createAlert}
            />

            <TransitAlertProgress
                mutationResult={mutationResult}
                finishedMessage={<FormattedMessage id="alert-editor.alert-created" />}
            />
        </>
    );
};

export default CreateAlertForm;
