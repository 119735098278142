import React from 'react';
import RouteBox from '../../components/RouteBox';
import { useRoutesForRouteSelectorQuery } from '../../graphql/graphql-typings';

interface Props {
    onSelect: (routeId: string) => void;
    selectedRouteIds?: string[];
}

const RouteBoxRouteSelector = (props: Props) => {
    const { onSelect, selectedRouteIds } = props;
    const { data } = useRoutesForRouteSelectorQuery();

    const onClick = (routeId: string) => {
        onSelect(routeId);
    };

    return (
        <>
            {data?.routes.map((route) => {
                const isInactiveRoute = selectedRouteIds && !selectedRouteIds.includes(route.id);
                return <RouteBox key={route.id} inactive={isInactiveRoute} onClick={onClick} route={route} />;
            })}
        </>
    );
};

export default RouteBoxRouteSelector;
