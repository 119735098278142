import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import React from 'react';
import PageContent from './PageContent';

const PageTitle: React.FC<TypographyProps> = ({ children, ...typographyProps }) => (
    <PageContent>
        <Typography variant="h1" {...typographyProps}>
            {children}
        </Typography>
    </PageContent>
);

export default PageTitle;
