import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Edit from '@mui/icons-material/EditOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import IfRole from '@realcity/web-frame/lib/components/Auth/IfRole';
import RouteBox from '../../components/RouteBox';
import TranslatedText from '../../components/TranslatedText';
import FormattedDateTime from '../../containers/Bookings/FormattedDateTime';
import type { RouteBoxFragment, TransitAlertSummaryFragment } from '../../graphql/graphql-typings';
import { TransitAlertStatus } from '../../graphql/graphql-typings';
import TransitAlertStatusChip from './TransitAlertStatusChip';
import type { TransitAlertStatusTextType } from './TransitAlertStatusText';
import { Roles } from '../../constants';

export interface Props {
    alert: TransitAlertSummaryFragment;
}

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            padding: 12,
        },
    },
    disabled: {
        '& .MuiTableCell-root': {
            color: 'rgba(0, 0, 0, 0.5)',
        },
    },
    routeBoxContainer: {
        display: 'flex',
    },
    routeBox: {
        margin: '0 2px',
    },
});

const TransitAlertTableRow: React.FC<Props> = ({ alert }) => {
    const classes = useStyles();

    const [backgroundColor, label] = getStatusDisplayProperties(alert);

    const isDisabled = label.type === 'INACTIVE' || (label.type === 'ACTIVE' && label.timing === 'PAST');

    return (
        <TableRow className={classNames(classes.root, isDisabled && classes.disabled)}>
            <TableCell width="25%">
                <TranslatedText translatedStrings={alert.summary} />
            </TableCell>
            <TableCell width="120px">
                <TransitAlertStatusChip backgroundColor={backgroundColor} label={label} />
            </TableCell>
            <TableCell width="15%">
                <div className={classes.routeBoxContainer}>
                    {alert.routes.map(route => (
                        <RouteBox key={route.id} route={transformRoute(route)} className={classes.routeBox} />
                    ))}
                </div>
            </TableCell>
            <TableCell>
                <FormattedDateTime value={alert.startTime} />
            </TableCell>
            <TableCell>
                {alert.endTime ? <FormattedDateTime value={alert.endTime} /> : <FormattedMessage id="alerts.end-time.further-notice" />}
            </TableCell>
            <TableCell>
                <FormattedDateTime value={alert.modifiedTime} />
            </TableCell>
            <IfRole role={Roles.AlertsWrite}>
                <TableCell>
                    <Button component={Link} to={`/alerts/edit/${alert.id}`}>
                        <Edit />
                    </Button>
                </TableCell>
            </IfRole>
        </TableRow>
    );
};

function transformRoute(routeData: TransitAlertSummaryFragment['routes'][0]): RouteBoxFragment {
    if (routeData.data) {
        return routeData.data;
    }

    return {
        id: routeData.id,
        color: 'BBBBBB',
        textColor: 'FFFFFF',
        shortName: routeData.id,

    };
}

function getStatusDisplayProperties(alert: TransitAlertSummaryFragment): [backgroundColor: string, label: TransitAlertStatusTextType] {
    if (alert.status !== TransitAlertStatus.Active) {
        return ['#EB5757', { type: alert.status }];
    }

    const now = Date.now();

    if (now < Date.parse(alert.startTime)) {
        return [
            '#F1821F',
            {
                type: TransitAlertStatus.Active,
                timing: 'FUTURE',
            },
        ];
    }

    if (!alert.endTime || now < Date.parse(alert.endTime)) {
        return [
            '#219653',
            {
                type: TransitAlertStatus.Active,
                timing: 'PRESENT',
            },
        ];
    }

    return [
        '#828282',
        {
            type: TransitAlertStatus.Active,
            timing: 'PAST',
        },
    ];
}

export default TransitAlertTableRow;
