import React from 'react';
import { FormattedMessage } from 'react-intl';
import VehicleLayer from './VehicleLayer';
import type { DisplayedVehicleFragment } from '../../graphql/graphql-typings';
import MapCardSectionHeader from '../MapCardSectionHeader';
import VehicleDetailsTable from '../VehicleDetailsTable';

interface Props {
    vehicle: DisplayedVehicleFragment;
}

const VehicleDetailsContent: React.FunctionComponent<Props> = ({ vehicle }) => (
    <>
        <MapCardSectionHeader>
            <FormattedMessage id="vehicle-details-content.title" />
        </MapCardSectionHeader>

        <VehicleDetailsTable vehicle={vehicle} />
        {vehicle.position && <VehicleLayer vehiclePosition={vehicle.position} />}
    </>
);

export default VehicleDetailsContent;
