import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { hasRole } from '@realcity/web-frame/lib/components/Auth/utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ClosablePopover } from '../../components/Closable';
import { Roles } from '../../constants';
import { acknowledgeNotification as acknowledgeNotificationAction } from '../../redux/actions';
import type { AppState } from '../../redux/state';

interface Props {
    id: number;
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    defaultMessage: string;
}

interface InternalProps extends Props {
    hasWriteRole: boolean;
    acknowledgeNotification: typeof acknowledgeNotificationAction;
}

interface State {
    message: string;
}

class EditAcknowledgementDialog extends React.PureComponent<InternalProps, State> {
    constructor(props: InternalProps) {
        super(props);
        this.state = {
            message: props.defaultMessage,
        };
    }

    private readonly changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ message: e.currentTarget.value });
    };

    private readonly save = () => {
        const { id, acknowledgeNotification, onClose } = this.props;
        const { message } = this.state;
        acknowledgeNotification({
            id,
            acknowledgeMessage: message,
        });
        onClose();
    };

    render() {
        const { open, anchorEl, onClose, hasWriteRole } = this.props;
        const { message } = this.state;

        return (
            <ClosablePopover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                dialogTitle={<FormattedMessage id="notifications.acknowledge.title" />}
            >
                <DialogContent>
                    <TextField
                        disabled={!hasWriteRole}
                        value={message}
                        multiline
                        variant="filled"
                        onChange={this.changeMessage}
                        label={<FormattedMessage id="notifications.acknowledge.message" />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={message.length === 0 || !hasWriteRole}
                        variant="contained"
                        onClick={this.save}
                        color="primary"
                        autoFocus
                    >
                        <FormattedMessage id="notifications.acknowledge.save" />
                    </Button>
                </DialogActions>
            </ClosablePopover>
        );
    }
}

export default connect((state: AppState) => ({ hasWriteRole: hasRole(state, Roles.Write) }), {
    acknowledgeNotification: acknowledgeNotificationAction,
})(EditAcknowledgementDialog);
