import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { NotificationType } from '../../../../common/interfaces';
import { getNotificationTypeMessageId } from './utils';

interface Props {
    type: NotificationType;
}

const NotificationTypeLabel: React.FunctionComponent<Props> = ({ type }) => <FormattedMessage id={getNotificationTypeMessageId(type)} />;

export default NotificationTypeLabel;
