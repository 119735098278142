import * as React from 'react';
import Refresher from '@realcity/web-frame/lib/components/Refresher';
import type { Loadable } from '../redux/actions';

interface Props {
    interval?: number;
    fetch: () => void;
    loadable: Loadable<any>;
}

const LoadableRefresher: React.FunctionComponent<Props> = ({ interval, fetch, loadable }) => (
    <Refresher
        interval={interval}
        refresh={fetch}
        error={loadable.error}
        loading={loadable.loading}
        lastRefresh={loadable.loadedTime === undefined ? undefined : new Date(loadable.loadedTime)}
    />
);

export default LoadableRefresher;
