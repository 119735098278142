import type { Context } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import type { KeycloakInstance } from 'keycloak-js';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isAuthenticationDisabled } from '../config';
import type { AppState } from '../redux/state';
import { mergeItemContainers } from '../utils';

interface InternalProps {
    keycloak: KeycloakInstance;
}

const GraphqlWrapper: React.FC<InternalProps> = ({ keycloak, children }) => {
    const [client] = useState(() => createClient(keycloak));

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

function createClient(keycloak: KeycloakInstance): ApolloClient<unknown> {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((error) => {
                // eslint-disable-next-line no-console
                console.log('[GraphQL error]: ', error);
            });
        }
        if (networkError) {
            // eslint-disable-next-line no-console
            console.log('[Network error]:', networkError);
        }
        // TODO: show a toast message with the error or put it into the redux store?
    });

    const restLink = new RestLink({ uri: '/api' });
    const httpLink = new HttpLink({ uri: '/graphql' });

    const links = [errorLink, restLink, httpLink];

    if (!isAuthenticationDisabled()) {
        const authLink = setContext(async (request, previousContext): Promise<Context> => {
            await keycloak.updateToken(30);

            return {
                ...previousContext,
                headers: {
                    ...previousContext.headers,
                    Authorization: `Bearer ${keycloak.token!}`,
                },
            };
        });

        links.splice(1, 0, authLink);
    }

    return new ApolloClient({
        link: ApolloLink.from(links),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        dispatcherMessages: {
                            merge: mergeItemContainers,
                            keyArgs: false,
                        },
                    },
                },
            },
        }),
    });
}

export default connect(({ auth }: AppState) => ({ keycloak: auth.keycloak! }), {})(GraphqlWrapper);
