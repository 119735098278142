import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CardHeader from '@mui/material/CardHeader';
import { FormattedMessage } from 'react-intl';
import type { VehicleId } from '../../../../common/interfaces';
import VehicleDetailsContent from '../../components/VehicleDetailsContent';
import TripDetailsContent from '../../components/TripDetailsContent';
import MapCard from '../../components/MapCard';
import CloseButton from '../../components/CloseButton';
import MapCardContent from '../../components/MapCardContent';
import ZoomTo from './ZoomTo';
import { useVehicleQuery } from '../../graphql/graphql-typings';

interface Props {
    vehicleId: VehicleId;
    onClose: () => void;
}

const VehiclePanel: React.FunctionComponent<Props> = ({ vehicleId, onClose }) => {
    const queryResult = useVehicleQuery({
        variables: { vehicleId },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    return (
        <MapCard>
            <CardHeader
                title={(
                    <>
                        <FormattedMessage id="map.vehicle-panel.title" />
                        {queryResult.data?.vehicle && ` (${queryResult.data.vehicle.licensePlate})`}
                    </>
                )}
                action={<CloseButton onClose={onClose} />}
            />

            <MapCardContent>
                {queryResult.loading && <CircularProgress />}
                {queryResult.data?.vehicle && (
                    <>
                        <VehicleDetailsContent vehicle={queryResult.data.vehicle} />
                        {queryResult.data.vehicle.trip && <TripDetailsContent trip={queryResult.data.vehicle.trip} />}
                        <ZoomTo trip={queryResult.data.vehicle.trip} vehicles={[queryResult.data.vehicle]} />
                    </>
                )}
            </MapCardContent>
        </MapCard>
    );
};

export default VehiclePanel;
