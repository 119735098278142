import type { WFState } from '@realcity/web-frame';
import differenceBy from 'lodash/differenceBy';
import type { Notification } from '../../../common/interfaces';
import type { ACTION_TYPES, ACTION_VALUES, AppAction, BaseFetchableAction, Loadable } from './actions';
import { getBaseStateValue } from './functions';
import type {
    ActivityModificationNotification,
    CurrentNotificationAcknowledge,
    DesktopNotificationsData,
    DriverAssignments,
    GtfsDrivers,
    GtfsVehicles,
    LogoutDriverLoadable,
    Notifications,
    VehicleAssignments,
    Vehicles,
} from './state';
import type AppState from './state';
import { vehicleBlocksReducer } from './vehicle-blocks-reducer';

// eslint-disable-next-line @typescript-eslint/default-param-last
function vehiclesReducer(state: Vehicles = getBaseStateValue({ time: 0, vehicles: [] }), action: AppAction): Vehicles {
    switch (action.type) {
        case 'VEHICLES':
            return getFetchState(action, state);
        default:
            return state;
    }
}

function currentAcknowledgeReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: CurrentNotificationAcknowledge = getBaseStateValue(undefined),
    action: AppAction,
): CurrentNotificationAcknowledge {
    switch (action.type) {
        case 'ACKNOWLEDGE_NOTIFICATION':
            return getFetchState(action, state);
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function notificationsReducer(state: Notifications = getBaseStateValue([]), action: AppAction): Notifications {
    switch (action.type) {
        case 'NOTIFICATIONS':
            return getFetchState(action, state);
        case 'ACKNOWLEDGE_NOTIFICATION': {
            const notification = action.data.list;
            if (notification) {
                return { ...state, list: state.list.map(n => n.id === notification.id ? notification : n) };
            }
            return state;
        }
        default:
            return state;
    }
}

function desktopNotificationsReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: DesktopNotificationsData = { notifications: [], ignored: [], fetched: false },
    action: AppAction,
): DesktopNotificationsData {
    if (action.type !== 'NOTIFICATIONS' || !action.data.list) {
        return state;
    }

    let notifications: Notification[] = [];

    if (state.fetched) {
        notifications = differenceBy(action.data.list, state.ignored, 'id');
    }

    return { ignored: action.data.list, notifications, fetched: true };
}

function getFetchState<T extends ACTION_TYPES>(
    action: BaseFetchableAction<T>,
    state: Loadable<ACTION_VALUES[T]>,
): Loadable<ACTION_VALUES[T]> {
    return { ...state, ...action.data };
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function showNotificationsReducer(state = true, action: AppAction): boolean {
    if (action.type === 'TOGGLE_NOTIFICATIONS') {
        return !state;
    }
    return state;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function logoutDriverReducer(state: LogoutDriverLoadable = getBaseStateValue(undefined), action: AppAction): LogoutDriverLoadable {
    if (action.type === 'LOGOUT_DRIVER') {
        return getFetchState(action, state);
    }
    return state;
}

let nextId = 0;

function activityModificationNotificationsReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: ActivityModificationNotification[] = [],
    action: AppAction,
): ActivityModificationNotification[] {
    switch (action.type) {
        case 'CLOSE_ACTIVITY_MODIFICATION_NOTIFICATION':
            return state.filter(n => n.id !== action.id);
        case 'ADD_ACTIVITY_MODIFICATION_NOTIFICATION':
            return [...state, { id: nextId++, type: action.notificationType }];
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function vehicleAssignmentsReducer(state: VehicleAssignments = getBaseStateValue([]), action: AppAction): VehicleAssignments {
    switch (action.type) {
        case 'VEHICLE_ASSIGNMENTS':
            return getFetchState(action, state);
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function driverAssignmentsReducer(state: DriverAssignments = getBaseStateValue([]), action: AppAction): DriverAssignments {
    switch (action.type) {
        case 'DRIVER_ASSIGNMENTS':
            return getFetchState(action, state);
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function gtfsVehiclesReducer(state: GtfsVehicles = getBaseStateValue([]), action: AppAction): GtfsVehicles {
    switch (action.type) {
        case 'GTFS_VEHICLES':
            return getFetchState(action, state);
        default:
            return state;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function gtfsDriversReducer(state: GtfsDrivers = getBaseStateValue([]), action: AppAction): GtfsDrivers {
    switch (action.type) {
        case 'GTFS_DRIVERS':
            return getFetchState(action, state);
        default:
            return state;
    }
}

type Reducer<T> = (current: T | undefined, action: AppAction) => T;

const reducers: { [P in Exclude<keyof AppState, keyof WFState>]: Reducer<AppState[P]> } = {
    vehicles: vehiclesReducer,
    vehicleBlocks: vehicleBlocksReducer,
    currentNotificationAcknowledge: currentAcknowledgeReducer,
    notifications: notificationsReducer,
    desktopNotifications: desktopNotificationsReducer,
    showNotifications: showNotificationsReducer,
    logoutDriverLoadable: logoutDriverReducer,
    activityModificationNotifications: activityModificationNotificationsReducer,
    vehicleAssignments: vehicleAssignmentsReducer,
    driverAssignments: driverAssignmentsReducer,
    gtfsVehicles: gtfsVehiclesReducer,
    gtfsDrivers: gtfsDriversReducer,
};

export default reducers;
