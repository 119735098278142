import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { BookingStatus } from '../../../../common/interfaces';

interface Props {
    status: BookingStatus;
}

const BookingStatusMessage: React.FunctionComponent<Props> = ({ status }) => {
    switch (status) {
        case 'ACTIVE':
            return <FormattedMessage id="bookings.status.active" />;
        case 'CANCELLED':
            return <FormattedMessage id="bookings.status.cancelled" />;
        case 'FINALIZED':
            return <FormattedMessage id="bookings.status.finalized" />;
    }
};

export default BookingStatusMessage;
