import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ClosableDialog } from '../../components/Closable';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import RouteSelector from '../RouteSelector';

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept: (routeIds: string[]) => void;
    selectedRouteIds: string[];
}

const FilterRouteDialog = (props: Props) => {
    const { onClose, onAccept, open, selectedRouteIds } = props;

    const [pendingRouteIds, setPendingRouteIds] = useState(selectedRouteIds);

    const onAcceptClicked = () => {
        onAccept(pendingRouteIds);
        onClose();
    };

    return (
        <ClosableDialog open={open} onClose={onClose} dialogTitle={<FormattedMessage id="route-selector.title" />}>
            <DialogContent>
                <RouteSelector onChange={setPendingRouteIds} pendingRouteIds={pendingRouteIds} />
            </DialogContent>
            <ContainedDialogActions>
                <Button variant="contained" color="primary" onClick={onAcceptClicked}>
                    <FormattedMessage id="filter-route-dialog.confirm" />
                </Button>
            </ContainedDialogActions>
        </ClosableDialog>
    );
};

export default React.memo(FilterRouteDialog);
