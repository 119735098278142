import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import MapCardContent from '../../components/MapCardContent';
import MapCard from '../../components/MapCard';
import LinkButton from '../../components/LinkButton';
import { getInternalTripMapUrl, getInternalVehicleMapUrl } from '../../utils';
import ZoomTo from './ZoomTo';
import type { DisplayedVehicleFragment } from '../../graphql/graphql-typings';
import { useVehiclesOnlineQuery } from '../../graphql/graphql-typings';

const useStyles = makeStyles({
    root: {
        flexShrink: 0,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

interface Props {
    open: boolean;
    onToggle: () => void;
}

export const VehicleListPanel: React.FunctionComponent<Props> = ({ open, onToggle }) => {
    const classes = useStyles();

    const queryResult = useVehiclesOnlineQuery({
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    return (
        <MapCard className={classes.root}>
            <CardHeader
                title={<FormattedMessage id="map.vehicle-list-panel.title" />}
                action={(
                    <IconButton onClick={onToggle} size="large">
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                )}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MapCardContent>
                    {queryResult.data?.vehiclesOnline && (
                        <>
                            <ZoomTo trip={null} vehicles={queryResult.data.vehiclesOnline} />

                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            <FormattedMessage id="map.vehicle-list-panel.license-plate" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormattedMessage id="map.vehicle-list-panel.driver" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormattedMessage id="map.vehicle-list-panel.trip" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {queryResult.data.vehiclesOnline.map(vehicle => (
                                        <TableRow key={vehicle.id}>
                                            <TableCell align="center">
                                                <LinkButton link={getInternalVehicleMapUrl(vehicle.id)}>{vehicle.licensePlate}</LinkButton>
                                            </TableCell>
                                            <TableCell align="center">{vehicle.driverName}</TableCell>
                                            <TableCell align="center">
                                                {vehicle.trip && (
                                                    <LinkButton link={getInternalTripMapUrl(vehicle.trip.tripKey)}>
                                                        <FormattedMessage id="map.vehicle-list-panel.trip-link" />
                                                    </LinkButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </MapCardContent>
            </Collapse>
        </MapCard>
    );
};

export function getVehiclesWithPosition(onlineVehicles: DisplayedVehicleFragment[]): DisplayedVehicleFragment[] | null {
    const vehiclesWithPosition = onlineVehicles.filter(vehicle => vehicle.position !== null);
    return vehiclesWithPosition.length > 0 ? vehiclesWithPosition : null;
}
