import type { DateType } from '@date-io/type';
import DialogContent from '@mui/material/DialogContent';
import type { StyleRules, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type {
    BaseCreateActivityCommand,
    BlockActivityType,
    BlockId,
    CreateActivityCommand,
    IsoDateString,
    Pattern,
    ServiceDate,
} from '../../../../common/interfaces';
import { ClosableDialog } from '../../components/Closable';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import DateTimePicker from '../../components/DateTimePicker';
import { convertBlockIdSelection, minMaxMomentAroundServiceDate } from '../../utils';
import CompoundBlockSelector from '../BlockSelectors/CompoundBlockSelector';
import type { BlockIdSelection } from '../BlockSelectors/DriverBlockSelector';
import ActivityTypeSelector from './ActivityTypeSelector';
import CreateActivityButton from './CreateActivityButton';
import PatternSelector from './PatternSelector';
import type { RouteSelectorRoute } from './RouteSelector';
import RouteSelector from './RouteSelector';

type ClassKeys = 'content';

const styles: StyleRules<{}, ClassKeys> = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 400,
    },
};

interface Props {
    open: boolean;
    onClose: () => void;
    serviceDate: ServiceDate;
}

export enum ActivityCategory {
    TRIP = 'TRIP',
    OTHER = 'OTHER'
}

interface State {
    category: ActivityCategory;
    activityType: BlockActivityType | null;
    beginTime: DateType | null;
    endTime: DateType | null;
    route: RouteSelectorRoute | null;
    pattern: Pattern | null;
    vehicleBlockId: BlockId;
    driverBlockId: BlockIdSelection;
}

class CreateActivityDialog extends React.PureComponent<Props & WithStyles<ClassKeys>, State> {
    constructor(props: Props & WithStyles<ClassKeys>) {
        super(props);
        const date = new Date();
        date.setSeconds(0);
        date.setMilliseconds(0);
        const currentTime = moment(date);

        this.state = {
            category: ActivityCategory.TRIP,
            activityType: null,
            beginTime: currentTime,
            endTime: currentTime,
            route: null,
            pattern: null,
            vehicleBlockId: '' as BlockId,
            driverBlockId: '' as BlockId,
        };
    }

    private readonly onTabChange = (event: React.ChangeEvent<{}>, category: ActivityCategory) => {
        this.setState({ category, activityType: null });
    };

    private readonly onActivityTypeChange = (activityType: BlockActivityType | null) => {
        this.setState({ activityType });
    };

    private readonly onStartTimeChange = (beginTime: DateType | null) => {
        this.setState({ beginTime });
    };

    private readonly onEndTimeChange = (endTime: DateType | null) => {
        this.setState({ endTime });
    };

    private readonly onRouteChange = (route: RouteSelectorRoute | null) => {
        this.setState({ route, pattern: null });
    };

    private readonly onPatternChange = (pattern: Pattern | null) => {
        this.setState({ pattern });
    };

    private readonly onVehicleBlockIdChange = (vehicleBlockId: BlockId) => {
        this.setState({ vehicleBlockId });
    };

    private readonly onDriverBlockIdChange = (driverBlockId: BlockIdSelection) => {
        this.setState({ driverBlockId });
    };

    private buildActivityCommandIfCan(): CreateActivityCommand | null {
        const { serviceDate } = this.props;
        const { category, activityType, beginTime, endTime, route, pattern, vehicleBlockId, driverBlockId } = this.state;

        if (activityType === null || serviceDate === '' || beginTime === null || !beginTime.isValid() || vehicleBlockId === '') {
            return null;
        }

        const base: BaseCreateActivityCommand = {
            activityType: activityType.id,
            serviceDate,
            beginTime: beginTime.toISOString() as IsoDateString,
            vehicleBlockId,
            driverBlockId: convertBlockIdSelection(driverBlockId),
        };

        switch (category) {
            case ActivityCategory.TRIP:
                if (route === null || pattern === null) {
                    return null;
                }
                return {
                    ...base,
                    trip: {
                        refTripId: pattern.patternId,
                        serviceDate,
                    },
                };
            case ActivityCategory.OTHER:
                if (endTime === null || !endTime.isValid()) {
                    return null;
                }
                return {
                    ...base,
                    endTime: endTime.toISOString() as IsoDateString,
                };
        }
    }

    render() {
        const { serviceDate, open, onClose, classes } = this.props;
        const { category, activityType, beginTime, endTime, route, pattern, vehicleBlockId, driverBlockId } = this.state;

        return (
            <ClosableDialog open={open} onClose={onClose} dialogTitle={<FormattedMessage id="create-activity-dialog.title" />}>
                <DialogContent className={classes.content}>
                    <Tabs value={category} onChange={this.onTabChange} indicatorColor="primary" textColor="primary">
                        <Tab value={ActivityCategory.TRIP} label={<FormattedMessage id="create-activity-dialog.category.trip" />} />
                        <Tab value={ActivityCategory.OTHER} label={<FormattedMessage id="create-activity-dialog.category.other" />} />
                    </Tabs>
                    <ActivityTypeSelector category={category} activityType={activityType} onChange={this.onActivityTypeChange} />
                    <DateTimePicker
                        fullWidth
                        label={<FormattedMessage id="create-activity-dialog.start-time" />}
                        {...minMaxMomentAroundServiceDate(serviceDate)}
                        value={beginTime}
                        onChange={this.onStartTimeChange}
                    />

                    {category === ActivityCategory.TRIP ? (
                        <>
                            <RouteSelector route={route} onChange={this.onRouteChange} />
                            <PatternSelector route={route} pattern={pattern} onChange={this.onPatternChange} />
                        </>
                    ) : (
                        <DateTimePicker
                            fullWidth
                            label={<FormattedMessage id="create-activity-dialog.end-time" />}
                            value={endTime}
                            onChange={this.onEndTimeChange}
                        />
                    )}

                    <CompoundBlockSelector
                        vehicleBlockId={vehicleBlockId}
                        driverBlockId={driverBlockId}
                        serviceDate={serviceDate}
                        onVehicleBlockIdChange={this.onVehicleBlockIdChange}
                        onDriverBlockIdChange={this.onDriverBlockIdChange}
                    />
                </DialogContent>
                <ContainedDialogActions>
                    <CreateActivityButton activityCommand={this.buildActivityCommandIfCan()} onFinish={onClose} />
                </ContainedDialogActions>
            </ClosableDialog>
        );
    }
}

export default withStyles(styles)(CreateActivityDialog);
