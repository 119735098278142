import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as React from 'react';
import { connect } from 'react-redux';
import type { Notification } from '../../../../common/interfaces';
import { toggleNotifications as toggleNotificationsActions } from '../../redux/actions';
import type { AppState, Notifications } from '../../redux/state';
import { isNotificationClosed } from '../../utils';

const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: theme.palette.error.dark,
    },
}))(Badge);

interface Props {
    showNotifications: boolean;
    notifications: Notifications;
    toggleNotifications: typeof toggleNotificationsActions;
}

const ToggleNotificationsButton: React.FunctionComponent<Props> = (props: Props) => {
    const { showNotifications, notifications, toggleNotifications } = props;

    return (
        <Button color="secondary" variant={showNotifications ? 'contained' : 'text'} onClick={toggleNotifications}>
            <StyledBadge badgeContent={getActiveNonAcknowledgedCount(notifications.list)}>
                <NotificationsIcon />
            </StyledBadge>
        </Button>
    );
};

function getActiveNonAcknowledgedCount(notifications: Notification[]): number {
    return notifications.filter(notification => !isNotificationClosed(notification) && !notification.acknowledged).length;
}

export default connect(({ showNotifications, notifications }: AppState) => ({ showNotifications, notifications }), {
    toggleNotifications: toggleNotificationsActions,
})(ToggleNotificationsButton);
