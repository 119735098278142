import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import type { CSSProperties, HTMLAttributes } from 'react';
import React from 'react';
import Chip from '@mui/material/Chip';
import type { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FormattedMessage } from 'react-intl';
import { routeBackgroundColor, routeIdsToRoutes, routeTextColor } from '../../utils';
import { useRoutesForRouteSelectorQuery } from '../../graphql/graphql-typings';
import type { RouteSelectorRoute } from '.';

interface Props {
    onChange: (routeIds: string[]) => void;
    pendingRouteIds?: string[];
}

const useStyles = makeStyles({
    autoCompleteTextField: {
        minWidth: 300,
    },
    shortName: {
        marginRight: 15,
        fontWeight: 'bold',
    },
});

const filterOptions = createFilterOptions<RouteSelectorRoute>({
    matchFrom: 'start',
    stringify: route => route.shortName,
});

const AutocompleteRouteSelector = React.forwardRef((props: Props, ref) => {
    const { onChange, pendingRouteIds } = props;
    const classes = useStyles();
    const { data } = useRoutesForRouteSelectorQuery();
    const routes = data?.routes || [];

    const onSelectionChange = (event: React.SyntheticEvent, selectedRoutes: RouteSelectorRoute[]) => {
        const routeIds = selectedRoutes.map(r => r.id);
        onChange(routeIds);
    };

    const renderRouteLabel = (renderProps: HTMLAttributes<HTMLLIElement>, route: RouteSelectorRoute) => (
        <li {...renderProps}>
            <span className={classes.shortName}>
                {' '}
                {route.shortName}
                {' '}
            </span>
            {route.label}
        </li>
    );

    const renderTextField = (params: AutocompleteRenderInputParams) => (
        <TextField
            className={classes.autoCompleteTextField}
            {...params}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            placeholder=""
            label={<FormattedMessage id="route-selector.title" />}
        />
    );

    const renderSelectedChips = (
        selectedRoutes: RouteSelectorRoute[],
        getTagProps: AutocompleteRenderGetTagProps,
    ): React.ReactNode => selectedRoutes.map((route: RouteSelectorRoute, index: number) => {
        const chipStyle: CSSProperties = {
            ...routeBackgroundColor(route),
            ...routeTextColor(route),
        };

        return (
            <Tooltip key={route.id} title={route.label}>
                <Chip variant="outlined" label={route.shortName} style={chipStyle} {...getTagProps({ index })} />
            </Tooltip>
        );
    });

    const getRouteId = (route: RouteSelectorRoute) => route.id;

    const isOptionEqualToValue = (option: RouteSelectorRoute, value: RouteSelectorRoute) => option.id === value.id;

    return (
        <Autocomplete
            onChange={onSelectionChange}
            multiple
            disabled={routes.length === 0}
            value={routeIdsToRoutes(pendingRouteIds || [], routes)}
            options={routes}
            getOptionLabel={getRouteId}
            renderOption={renderRouteLabel}
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions
            renderInput={renderTextField}
            renderTags={renderSelectedChips}
            ref={ref}
        />
    );
});

export default React.memo(AutocompleteRouteSelector);
