import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import React from 'react';
import type { BookingDirection } from '../../../../common/interfaces';
import type { BookingListFragment } from '../../graphql/graphql-typings';
import BookingDirectionMessage from './BookingDirectionMessage';
import BookingStatusMessage from './BookingStatusMessage';
import FormattedCoordinate from './FormattedCoordinate';
import FormattedDateTime from './FormattedDateTime';
import FormattedStop from './FormattedStop';
import WalkingPathIcon from './WalkingPathIcon';
import RouteBox from '../../components/RouteBox';

type TextFilter = Partial<Pick<BookingListFragment, 'tripKey' | 'zoneId'>>;

interface Props {
    bookingList: BookingListFragment[];
    userName: string;
    textFilters: TextFilter;
    directions: BookingDirection[];
}

interface RowProps {
    booking: BookingListFragment;
}

const useStyles = makeStyles({
    FINALIZED: {
        backgroundColor: '#b6fabc',
    },
    ACTIVE: {
        backgroundColor: '#f9ffa8',
    },
    CANCELLED: {
        '& td': {
            color: '#6a77725c',
        },
    },
    small_padding: {
        padding: 0.5,
    },
});

const BookingRow: React.FunctionComponent<RowProps> = ({ booking }) => {
    const classes = useStyles();

    const rowClassName = classes[booking.status];

    return (
        <TableRow className={classNames(rowClassName, classes.small_padding)}>
            <TableCell>
                <BookingStatusMessage status={booking.status} />
            </TableCell>
            <TableCell>{booking.user.name}</TableCell>
            <TableCell>
                <RouteBox route={booking.route} />
            </TableCell>
            <TableCell>
                <FormattedDateTime value={booking.createdAt} />
            </TableCell>
            <TableCell>{booking.finalizedAt && <FormattedDateTime value={booking.finalizedAt} />}</TableCell>
            <TableCell>{booking.cancelledAt && <FormattedDateTime value={booking.cancelledAt} />}</TableCell>
            <TableCell>
                <BookingDirectionMessage direction={booking.direction} />
            </TableCell>
            <TableCell>{booking.tripKey}</TableCell>
            <TableCell>{booking.passengerCount}</TableCell>
            <TableCell>
                <FormattedCoordinate position={booking.userCoordinate.coordinates} />
            </TableCell>
            <TableCell>
                <FormattedDateTime value={booking.minTimeAtUserCoordinate} />
            </TableCell>
            <TableCell>
                <FormattedDateTime value={booking.maxTimeAtUserCoordinate} />
            </TableCell>
            <TableCell>{booking.stop && <FormattedStop stop={booking.stop} />}</TableCell>
            <TableCell>{booking.timeAtUserCoordinate && <FormattedDateTime value={booking.timeAtUserCoordinate} />}</TableCell>
            <TableCell>{booking.timeAtStop && <FormattedDateTime value={booking.timeAtStop} />}</TableCell>
            <TableCell>{booking.walkingPath && <WalkingPathIcon walkingPath={booking.walkingPath} />}</TableCell>
        </TableRow>
    );
};

const BookingsList: React.FunctionComponent<Props> = ({ bookingList, textFilters, userName, directions }) => {
    const filteredBookings = bookingList
        .filter(booking => matchesUserFilter(booking, userName)
            && matchesTextFilters(booking, textFilters)
            && matchesDirectionFilter(booking, directions));

    return (
        <>
            {orderBy(filteredBookings, ['minTimeAtUserCoordinate', 'maxTimeAtUserCoordinate', 'userId'], ['desc', 'desc', 'asc']).map(
                booking => (
                    <BookingRow key={booking.bookingId} booking={booking} />
                ),
            )}
        </>
    );
};

function matchesUserFilter(booking: BookingListFragment, userName: string): boolean {
    return booking.user.name.toLowerCase().includes(userName.toLowerCase());
}

function matchesTextFilters(booking: BookingListFragment, filter: TextFilter): boolean {
    for (const key of Object.keys(filter) as (keyof TextFilter)[]) {
        const filterField = filter[key];
        if (!filterField) {
            continue;
        }
        if (!booking[key].toLowerCase().includes(filterField.toLowerCase())) {
            return false;
        }
    }

    return true;
}

function matchesDirectionFilter(booking: BookingListFragment, directions: BookingDirection[]): boolean {
    if (directions.length > 0) {
        return directions.includes(booking.direction);
    }

    return true;
}

export default BookingsList;
