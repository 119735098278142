import type { MutationFunctionOptions } from '@apollo/react-common/lib/types/types';
import { useMutation } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import type { DocumentNode } from 'graphql';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { BlockActivity, BlockKey } from '../../../../common/interfaces';
import {
    activityModificationError as activityModificationErrorAction,
    activityModificationSuccess as activityModificationSuccessAction,
} from '../../redux/actions';
import AsyncActionButton from '../../components/AsyncActionButton';
import type { LockVehicleBlockVersions } from './locking';
import { getBlockVersionsGraphQLContext, getLockVehicleBlockVersions } from './locking';

const useStyles = makeStyles({
    container: {
        display: 'flex',
    },
    button: {
        margin: 5,
    },
});

export interface InputVariables {
    input: any;
}

interface Props {
    blockActivity: BlockActivity;
    graphQlQuery: DocumentNode;
    mutationOptions?: Omit<MutationFunctionOptions<any, InputVariables>, 'context'>; // Todo - Integrate typing from GraphQL types
    activityModificationCompleted?: () => void;
    disabled?: boolean;
    additionalBlockKey?: BlockKey;
    lockVehicleBlockVersions: LockVehicleBlockVersions;
    activityModificationSuccess: typeof activityModificationSuccessAction;
    activityModificationError: typeof activityModificationErrorAction;
}

const BlockActivityModificationSaveButton: React.FunctionComponent<Props> = ({
    graphQlQuery,
    mutationOptions,
    activityModificationCompleted,
    disabled,
    lockVehicleBlockVersions,
    blockActivity,
    additionalBlockKey,
    activityModificationSuccess,
    activityModificationError,
}) => {
    const classes = useStyles();

    const [modifyActivity, { loading }] = useMutation(graphQlQuery, {
        onCompleted() {
            activityModificationSuccess();
            if (activityModificationCompleted) {
                activityModificationCompleted();
            }
        },
        onError: activityModificationError,
    });

    const modifyWithMessageClick = () => {
        modifyActivityClick(true);
    };

    const modifyWithoutMessageClick = () => {
        modifyActivityClick(false);
    };

    function modifyActivityClick(sendMessage: boolean) {
        if (!mutationOptions) {
            return;
        }

        const { variables } = mutationOptions;
        const extendedOptions: MutationFunctionOptions<any, InputVariables> = {
            ...mutationOptions,
            variables: variables ? { ...variables, input: { ...variables.input, sendMessage } } : undefined,
            context: getBlockVersionsGraphQLContext(lockVehicleBlockVersions, blockActivity, additionalBlockKey),
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        modifyActivity(extendedOptions);
    }

    return (
        <div className={classes.container}>
            <AsyncActionButton
                color="primary"
                variant="contained"
                className={classes.button}
                loading={loading}
                onClick={modifyWithMessageClick}
                disabled={disabled}
            >
                <FormattedMessage id="blocks-overview.block-activity-modification.save-with-message" />
            </AsyncActionButton>
            <AsyncActionButton
                color="primary"
                variant="contained"
                className={classes.button}
                loading={loading}
                onClick={modifyWithoutMessageClick}
                disabled={disabled}
            >
                <FormattedMessage id="blocks-overview.block-activity-modification.save-without-message" />
            </AsyncActionButton>
        </div>
    );
};

export default connect(getLockVehicleBlockVersions, {
    activityModificationSuccess: activityModificationSuccessAction,
    activityModificationError: activityModificationErrorAction,
})(BlockActivityModificationSaveButton);
