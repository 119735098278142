import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import type { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const POPPER_MODIFIERS: PopperProps['modifiers'] = [
    {
        name: 'preventOverflow',
        options: {
            altAxis: true,
            padding: 4,
        },
    },
    {
        name: 'flip',
        enabled: false,
    },
];

const useStyles = makeStyles({
    paper: {
        borderRadius: 10,
    },
});

export interface Props extends PopperProps {
    onClose: () => void;
    clickAwayContainer: HTMLElement | null;
}

const RoundedPopper: React.FunctionComponent<Props> = ({ children, onClose, clickAwayContainer, ...rest }) => {
    const classes = useStyles();

    const onClickAway = (event: MouseEvent | TouchEvent) => {
        if (!clickAwayContainer || clickAwayContainer.contains(event.target as HTMLElement)) {
            onClose();
        }
    };

    return (
        <Popper {...rest} modifiers={POPPER_MODIFIERS}>
            <ClickAwayListener onClickAway={onClickAway}>
                <Paper elevation={8} className={classes.paper}>
                    {children}
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
};

export default RoundedPopper;
