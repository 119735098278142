import type { VehicleBlock } from '../../redux/state';
import type { BlockActivity as BlockActivityInterface, IsoDateString } from '../../../../common/interfaces';
import { getBeginTime, getEndTime } from '../../utils';
import { INVALID_BLOCK_ID } from '../../constants';

export function calculateLeft(base: Date, date: Date | string, zoom: number) {
    const offset = Date.parse(date as string) - base.valueOf();
    return offset / 1000 / 3600 * zoom;
}

export function calculateWidth(base: Date, begin: Date | string, end: Date | string, zoom: number) {
    return calculateLeft(base, end, zoom) - calculateLeft(base, begin, zoom);
}

export function getBlockRowClasses(block: VehicleBlock, active: boolean, highlighted: boolean, ...additionalClasses: string[]) {
    const classes = ['block-row', ...additionalClasses];

    if (active) {
        classes.push('block-row--active');
    }
    if (block.notifications.length) {
        classes.push(`block-row--${block.notifications[0].severity}`);
    }
    if (highlighted) {
        classes.push('block-row--highlighted');
    }
    return classes.join(' ');
}

export const BLOCK_DATA_ROW_HEIGHT = 47;

export function calculateBlockActivityTop(rowIndex: number) {
    return rowIndex * BLOCK_DATA_ROW_HEIGHT + 20;
}

export function groupActivitiesToRows(blockId: string, activities: BlockActivityInterface[]): BlockActivityInterface[][] {
    if (blockId !== INVALID_BLOCK_ID) {
        return [activities];
    }

    const resultList: BlockActivityInterface[][] = [];
    const clipboardRowEndTimes: IsoDateString[] = [];

    for (const activity of activities) {
        let newRowNeeded = true;

        let index = 0;

        for (const rowEnd of clipboardRowEndTimes) {
            if (getBeginTime(activity) >= rowEnd) {
                clipboardRowEndTimes[index] = getEndTime(activity);
                resultList[index].push(activity);
                newRowNeeded = false;
                break;
            }

            index++;
        }

        if (newRowNeeded) {
            clipboardRowEndTimes.push(getEndTime(activity));
            resultList.push([activity]);
        }
    }
    return resultList;
}

export function calculateBlockDataRowHeight(blockId: string, sortedActivities: BlockActivityInterface[][]) {
    if (blockId !== INVALID_BLOCK_ID) {
        return {};
    }

    if (sortedActivities.length === 0) {
        return { height: BLOCK_DATA_ROW_HEIGHT - 1 };
    }

    return { height: sortedActivities.length * BLOCK_DATA_ROW_HEIGHT - 1 };
}
