import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import type { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import type { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withStyles from '@mui/styles/withStyles';
import type { MultiSelectOption } from '@realcity/web-frame/lib/components/MultiSelect';
import MultiSelect from '@realcity/web-frame/lib/components/MultiSelect';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import type { DelayStatus } from '../../../../common/interfaces';
import ConfirmDialog from '../../components/ConfirmDialog';
import CustomSnackbar from '../../components/CustomSnackbar';
import LoadableRefresher from '../../components/LoadableRefresher';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import SearchInput from '../../components/SearchInput';
import { isLargeNetwork } from '../../config';
import AutocompleteRouteSelector from '../../containers/RouteSelector/AutocompleteRouteSelector';
import StatefulRouteSelector from '../../containers/RouteSelector/StatefulRouteSelector';
import { fetchVehicles as fetchVehiclesAction, logoutDriver as logoutDriverAction } from '../../redux/actions';
import type { AppState, LogoutDriverLoadable, Vehicles } from '../../redux/state';
import type { MessageKeys } from '../../translations';
import FilteredVehicleList from './FilteredVehicleList';

interface Props {
    vehicles: Vehicles;
    fetchVehicles: typeof fetchVehiclesAction;
    logoutDriverLoadable: LogoutDriverLoadable;
    logoutDriver: typeof logoutDriverAction;
}

interface State {
    filter: string;
    routeIds: string[];
    confirmDialogOpen: boolean;
    vehicleIdForLogout?: string;
    delayStatusFilter: DelayStatus[];
}

interface DelayStatusMultiSelectOption extends MultiSelectOption {
    value: DelayStatus;
}

type ClassKeys =
    | 'delayStatusLabel'
    | 'filterContainer'
    | 'formControl'
    | 'routePanel'
    | 'routeSelector'
    | 'searchBox';

const styles: StyleRulesCallback<Theme, {}, ClassKeys> = theme => ({
    searchBox: {
        marginRight: 15,
    },
    routePanel: {
        display: 'flex',
        alignItems: 'center',
    },
    routeSelector: {
        flex: 1,
        margin: '15px 0px',
    },
    filterContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    delayStatusLabel: {
        marginRight: 10,
    },
    formControl: {
        marginRight: 15,
        minWidth: 200,
        minHeight: theme.spacing(4),
    },
});

class VehiclesPage extends React.PureComponent<Props & WithStyles<ClassKeys> & WrappedComponentProps, State> {
    state: State = {
        filter: '',
        routeIds: [],
        confirmDialogOpen: false,
        delayStatusFilter: [],
    };

    private getFormattedMessage(messageId: MessageKeys) {
        const { intl } = this.props;
        return intl.formatMessage({ id: messageId });
    }

    private getDelayStatusOptions(): DelayStatusMultiSelectOption[] {
        return [
            { value: 'WAITING', label: this.getFormattedMessage('vehicles.delay-status.waiting') },
            { value: 'EARLY', label: this.getFormattedMessage('vehicles.delay-status.early') },
            { value: 'ON_TIME', label: this.getFormattedMessage('vehicles.delay-status.on-time') },
            { value: 'LATE', label: this.getFormattedMessage('vehicles.delay-status.late') },
        ];
    }

    private readonly onFilterChange = (filter: string) => {
        this.setState({ filter });
    };

    private readonly onRoutesChange = (routeIds: string[]) => {
        this.setState({ routeIds });
    };

    private readonly handleCancel = () => {
        this.setState({ confirmDialogOpen: false });
    };

    private readonly showLogoutConfirmDialog = (vehicleId: string) => {
        this.setState({ confirmDialogOpen: true, vehicleIdForLogout: vehicleId });
    };

    private readonly logoutDriver = () => {
        const { vehicleIdForLogout } = this.state;
        const { logoutDriver } = this.props;
        if (vehicleIdForLogout) {
            logoutDriver(vehicleIdForLogout);
        }
        this.setState({ confirmDialogOpen: false });
    };

    private readonly delayStatusSelectionChange = (selected: string[]) => {
        this.setState({ delayStatusFilter: selected as DelayStatus[] });
    };

    render() {
        const {
            classes,
            vehicles,
            fetchVehicles,
            logoutDriverLoadable: { loading, error },
        } = this.props;
        const { filter, routeIds, delayStatusFilter, confirmDialogOpen } = this.state;

        return (
            <>
                <PageHeader title={<FormattedMessage id="vehicles.title" />}>
                    <LoadableRefresher interval={10000} fetch={fetchVehicles} loadable={vehicles} />
                </PageHeader>
                <PageContent>
                    <div className={classes.routeSelector}>
                        {!isLargeNetwork() && <StatefulRouteSelector onChange={this.onRoutesChange} selectedRouteIds={routeIds} />}
                    </div>
                    <div className={classes.filterContainer}>
                        <SearchInput classes={{ root: classes.searchBox }} value={filter} onChange={this.onFilterChange} />
                        <FormControl variant="standard" className={classes.formControl}>
                            {/* Todo -  Remove shrink after empty style is added to MultiSelect */}
                            <InputLabel shrink>
                                <FormattedMessage id="vehicles.delay.status" />
                            </InputLabel>
                            <MultiSelect
                                options={this.getDelayStatusOptions()}
                                selected={delayStatusFilter}
                                onChange={this.delayStatusSelectionChange}
                            />
                        </FormControl>
                        {isLargeNetwork() && <AutocompleteRouteSelector pendingRouteIds={routeIds} onChange={this.onRoutesChange} />}
                    </div>
                </PageContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="vehicles.license-plate" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.block-id" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.line" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.stop" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.delay" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.model" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.driver" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.phone-number" />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="vehicles.refreshed" />
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <FilteredVehicleList
                            filter={filter}
                            routeIds={routeIds}
                            logoutDriverOnClick={this.showLogoutConfirmDialog}
                            delayStatusFilter={delayStatusFilter}
                        />
                    </TableBody>
                </Table>
                <CustomSnackbar variant="info" open={loading}>
                    <FormattedMessage id="vehicles.snackbar.driver-logout-success" />
                </CustomSnackbar>
                <CustomSnackbar variant="error" open={error}>
                    <FormattedMessage id="vehicles.snackbar.driver-logout-fail" />
                </CustomSnackbar>
                <ConfirmDialog
                    handleOk={this.logoutDriver}
                    handleCancel={this.handleCancel}
                    open={confirmDialogOpen}
                    content={(
                        <DialogContentText>
                            <FormattedMessage id="vehicles.driver-logout-confirmation-dialog.content" />
                        </DialogContentText>
                    )}
                    title={<FormattedMessage id="vehicles.driver-logout-confirmation-dialog.title" />}
                />
            </>
        );
    }
}

export default connect(({ vehicles, logoutDriverLoadable }: AppState) => ({ vehicles, logoutDriverLoadable }), {
    fetchVehicles: fetchVehiclesAction,
    logoutDriver: logoutDriverAction,
})(withStyles(styles)(injectIntl(VehiclesPage)));
