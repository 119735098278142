import gql from 'graphql-tag';

export const CREATE_ACTIVITY = gql`
    mutation createActivity($command: CreateActivityCommand) {
        createActivity(input: $command)
        @rest(type: "Post", path: "/activities", method: "POST") {
            NoResponse
        }
    }
`;

export const CHANGE_BLOCK = gql`
    mutation changeBlock($blockActivityKey: ID, $input: ChangeBlock) {
        changeBlock(blockActivityKey: $blockActivityKey, input: $input)
        @rest(type: "Patch", path: "/activities/{args.blockActivityKey}", method: "PATCH"){
            NoResponse
        }
    }
`;

export const BLOCK_ACTIVITY_VALIDATION_CHANGE = gql`
    mutation blockActivityValidationChange($blockActivityKey: ID!, $input: BlockActivityValidationChange) {
        blockActivityValidationChange(blockActivityKey: $blockActivityKey, input: $input)
        @rest(type: "Patch", path: "/activities/{args.blockActivityKey}", method: "PATCH") {
            NoResponse
        }
    }`;

export const CHANGE_BLOCK_ACTIVITY_TIME = gql`
    mutation changeTimeForBlockActivity($blockActivityKey: ID, $input: BlockActivityTimeChangeInput) {
        changeTimeForBlockActivity(blockActivityKey: $blockActivityKey, input: $input)
        @rest(type: "Patch", path: "/activities/{args.blockActivityKey}", method: "PATCH") {
            NoResponse
        }
    }
`;

export const CHANGE_BEGIN_DATE_FOR_TRIP = gql`
    mutation changeBeginTimeForTrip($tripKey: ID, $input: ChangeBeginTimeInput) {
        changeBeginTimeForTrip(tripKey: $tripKey, input: $input)
        @rest(type: "Patch", path: "/trips/{args.tripKey}", method: "PATCH"){
            NoResponse
        }
    }
`;

export const SHORTEN_TRIP = gql`
    mutation stopSkipping($tripKey: ID, $input: StopSkippingTripModification) {
        stopSkipping(tripKey: $tripKey, input: $input)
        @rest(type: "Patch", path: "/trips/{args.tripKey}", method: "PATCH") {
            NoResponse
        }
    }
`;

export const GET_SCHEDULE = gql`
    query schedule($tripKey: ID!, $blockActivityKey: ID!) {
        schedule(tripKey: $tripKey, blockActivityKey: $blockActivityKey)
        @rest(type: "[ScheduleEntry]", path: "/trips/{args.tripKey}/active-schedule?blockActivityKey={args.blockActivityKey}") {
            stopId
            stopSequence
            arrivalTime
            departureTime
            skipped
            stop
        }
    }
`;

export const GET_DRIVER_BLOCKS = gql`
    query driverBlocks($serviceDate: String!) {
        driverBlocks(serviceDate: $serviceDate)
        @rest(type: "[Block]", path: "/blocks/drivers/{args.serviceDate}") {
            blockKey
            blockId
            serviceDate
        }
    }
`;

export const GET_VEHICLE_BLOCKS = gql`
    query vehicleBlocks($serviceDate: String!) {
        vehicleBlocks(serviceDate: $serviceDate)
        @rest(type: "[Block]", path: "/blocks/vehicles/{args.serviceDate}") {
            blockKey
            blockId
            serviceDate
        }
    }
`;

export const GET_ACTIVITY_TYPES = gql`
    query activityTypes {
        activityTypes @rest(type: "[ActivityType]", path: "/activity-types") {
            id
            type
            code
            name
        }
    }
`;

export const GET_PATTERNS = gql`
    query patterns($routeId: String!) {
        patterns(routeId: $routeId) @rest(type: "[Pattern]", path: "/patterns?routeId={args.routeId}" ) {
            patternName
            headsign
            patternId
        }
    }
`;
