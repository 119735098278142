import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layer } from 'react-mapbox-gl';
import StyledMap from './StyledMap';
import MapSidebar from './MapSidebar';
import PageTitle from '../../components/PageTitle';

export const TRIP_MAP_LAYER = 'TRIP_MAP_LAYER';
export const STOPS_MAP_LAYER = 'STOPS_MAP_LAYER';
export const VEHICLE_MAP_LAYER = 'VEHICLE_MAP_LAYER';

const MapPage: React.FunctionComponent = () => (
    <>
        <PageTitle>
            <FormattedMessage id="map.title" />
        </PageTitle>

        <StyledMap>
            <Layer id={VEHICLE_MAP_LAYER} />
            <Layer id={STOPS_MAP_LAYER} before={VEHICLE_MAP_LAYER} />
            <Layer id={TRIP_MAP_LAYER} before={STOPS_MAP_LAYER} />
            <MapSidebar />
        </StyledMap>

    </>
);

export default MapPage;
