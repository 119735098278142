import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import React from 'react';
import { getDirectionText } from '../utils';
import type { DisplayedVehicleFragment, OnlineVehicleFragment } from '../graphql/graphql-typings';

const useStyles = makeStyles({
    titleCell: {
        paddingRight: 0,
    },
});

interface Props {
    vehicle: DisplayedVehicleFragment | OnlineVehicleFragment;
}

const VehicleDetailsTable: React.FC<Props> = ({ vehicle }) => {
    const classes = useStyles();

    return (
        <Table size="small">
            <TableBody>
                <TableRow>
                    <TableCell className={classes.titleCell} variant="head">
                        <FormattedMessage id="vehicle-details-table.license-plate" />
                    </TableCell>
                    <TableCell>{vehicle.licensePlate}</TableCell>
                </TableRow>
                {vehicle.driverName && (
                    <TableRow>
                        <TableCell className={classes.titleCell} variant="head">
                            <FormattedMessage id="vehicle-details-table.driver" />
                        </TableCell>
                        <TableCell>
                            <Typography noWrap>{vehicle.driverName}</Typography>
                        </TableCell>
                    </TableRow>
                )}
                {isVehicleWithTrip(vehicle) && vehicle.trip && (
                    <>
                        <TableRow>
                            <TableCell className={classes.titleCell} variant="head">
                                <FormattedMessage id="vehicle-details-table.route" />
                            </TableCell>
                            <TableCell>
                                {vehicle.trip.route.shortName}
                                {getDirectionText(vehicle.trip.directionId)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.titleCell} variant="head">
                                <FormattedMessage id="vehicle-details-table.headsign" />
                            </TableCell>
                            <TableCell>{vehicle.trip.headsign}</TableCell>
                        </TableRow>
                    </>
                )}
            </TableBody>
        </Table>
    );
};

function isVehicleWithTrip(vehicle: DisplayedVehicleFragment | OnlineVehicleFragment): vehicle is OnlineVehicleFragment {
    return 'trip' in vehicle;
}

export default VehicleDetailsTable;
