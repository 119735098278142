import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import grey from '@mui/material/colors/grey';
import lightGreen from '@mui/material/colors/lightGreen';
import orange from '@mui/material/colors/orange';
import makeStyles from '@mui/styles/makeStyles';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import classNames from 'classnames';
import * as React from 'react';
import RecipientTimeDetails from './RecipientTimeDetails';
import type { RecipientItemFragment } from '../../graphql/graphql-typings';

type RecipientStatus = RecipientItemFragment['status'];

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chip: {
        marginRight: 5,
        marginBottom: 5,
    },
    acknowledged: {
        backgroundColor: lightGreen[600],
    },
    shown: {
        backgroundColor: orange[600],
    },
    default: {
        backgroundColor: grey[800],
    },
    recipientContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

interface Props {
    recipients: RecipientItemFragment[];
}

const RecipientChips: React.FunctionComponent<Props> = ({ recipients }) => {
    const classes = useStyles();

    return (
        <div className={classes.recipientContainer}>
            {recipients.map(recipient => (
                <Tooltip key={recipient.id} placement="top" title={<RecipientTimeDetails recipient={recipient} />}>
                    <Chip
                        icon={getRecipientIcon(recipient.status)}
                        color="primary"
                        className={classNames(classes.root, classes.chip, classes[getRecipientStatusClass(recipient.status)])}
                        label={vehicleIdToVehicleName(recipient.vehicleId)}
                    />
                </Tooltip>
            ))}
        </div>
    );
};

// TODO: use the license plate instead of the vehicle ID
function vehicleIdToVehicleName(otpVehicleId: string) {
    const [, vehicleId] = otpVehicleId.split('_', 2);
    return vehicleId;
}

function getRecipientIcon(status: RecipientStatus): React.ReactElement | undefined {
    switch (status) {
        case 'SHOWN':
            return <Done />;
        case 'ACKNOWLEDGED':
            return <DoneAll />;
        default:
    }
}

function getRecipientStatusClass(status: RecipientStatus): keyof ReturnType<typeof useStyles> {
    switch (status) {
        case 'ACKNOWLEDGED':
            return 'acknowledged';
        case 'SHOWN':
            return 'shown';
        default:
            return 'default';
    }
}

export default RecipientChips;
