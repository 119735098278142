import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MultiSelect from '@realcity/web-frame/lib/components/MultiSelect';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import GraphqlRefresher, { REFRESHABLE_QUERY_OPTIONS } from '../../components/GraphqlRefresher';
import type { BookingDirection } from '../../../../common/interfaces';
import PageHeader from '../../components/PageHeader';
import SearchInputWithLabel from '../../components/SearchInputWithLabel';
import BookingsList from '../../containers/Bookings/BookingsList';
import { useBookingsPageQuery } from '../../graphql/graphql-typings';
import PageContent from '../../components/PageContent';
import useQueryParamReader from '../../hooks/useQueryParamReader';
import useQueryParamWriter from '../../hooks/useQueryParamWriter';

const useStyles = makeStyles({
    filterContainer: {
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
    },
    multiSelectForm: {
        minWidth: 200,
    },
});

const BookingsPage: React.FunctionComponent = () => {
    const intl = useIntl();
    const query = useBookingsPageQuery(REFRESHABLE_QUERY_OPTIONS);
    const [userName, setUserName] = useState('');
    const [zoneId, setZoneId] = useState('');
    const [directions, setDirections] = useState<BookingDirection[]>([]);
    const classes = useStyles();

    const readQueryParam = useQueryParamReader();
    const writeQueryParams = useQueryParamWriter();

    function getTripKeyFromUrlIfExists() {
        const { tripKey: tripKeyParam } = readQueryParam<'tripKey'>();

        // TODO - Store original tripKey in the result of dispatch/trips API
        if (tripKeyParam && typeof tripKeyParam === 'string') {
            const tripIdMatch = /^([0-9]{8}_[A-Z]+_[A-Z0-9-]+)$/.exec(tripKeyParam) || /^ (.*)-([0-9]{8})-[0-9]{2,}$/.exec(tripKeyParam);

            if (tripIdMatch) {
                return tripIdMatch[1];
            }
        }

        return '';
    }

    const tripKey = getTripKeyFromUrlIfExists();

    const onTripKeyChange = (text: string) => {
        writeQueryParams({ tripKey: text });
    };

    const updateDirectionFilter = (statusSelection: string[]) => {
        setDirections(statusSelection as BookingDirection[]);
    };

    const getDirectionOptions = () => [
        { value: 'INBOUND', label: intl.formatMessage({ id: 'bookings.direction.inbound' }) },
        { value: 'OUTBOUND', label: intl.formatMessage({ id: 'bookings.direction.outbound' }) },
    ];

    return (
        <>
            <PageHeader title={<FormattedMessage id="bookings.title" />}>
                <GraphqlRefresher interval={10000} queryResult={query} />
            </PageHeader>
            <PageContent>
                <div className={classes.filterContainer}>
                    <SearchInputWithLabel
                        label={<FormattedMessage id="bookings.user" />}
                        value={userName}
                        onChange={setUserName}
                    />
                    <SearchInputWithLabel
                        label={<FormattedMessage id="bookings.trip" />}
                        value={tripKey}
                        onChange={onTripKeyChange}
                    />
                    <SearchInputWithLabel
                        label={<FormattedMessage id="bookings.zone" />}
                        value={zoneId}
                        onChange={setZoneId}
                    />
                    <FormControl variant="standard" className={classes.multiSelectForm}>
                        <InputLabel shrink sx={{ transform: 'translate(0, -9px) scale(0.75)' }}>
                            <FormattedMessage id="bookings.direction" />
                        </InputLabel>
                        <MultiSelect options={getDirectionOptions()} selected={directions} onChange={updateDirectionFilter} />
                    </FormControl>
                </div>
            </PageContent>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage id="bookings.state" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.user" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.zone" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.created-at" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.finalized-at" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.canceled-at" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.direction" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.trip" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.passenger-count" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.user-coordinate" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.min-time-at-coordinate" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.max-time-at-coordinate" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.stop" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.time-user" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.time-stop" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id="bookings.walking-path" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <BookingsList
                        userName={userName}
                        textFilters={{ tripKey, zoneId }}
                        directions={directions}
                        bookingList={query.data?.bookings || []}
                    />
                </TableBody>
            </Table>
        </>
    );
};

export default BookingsPage;
