import * as React from 'react';
import { isLargeNetwork } from '../../config';
import type { RoutesForRouteSelectorQuery } from '../../graphql/graphql-typings';
import AutocompleteRouteSelector from './AutocompleteRouteSelector';
import StatefulRouteSelector from './StatefulRouteSelector';

export type RouteSelectorRoute = RoutesForRouteSelectorQuery['routes'][0];

interface Props {
    onChange: (routeIds: string[]) => void;
    pendingRouteIds?: string[];
}

const RouteSelector: React.FC<Props> = ({ onChange, pendingRouteIds }) => isLargeNetwork() ? (
    <AutocompleteRouteSelector onChange={onChange} pendingRouteIds={pendingRouteIds} />
) : (
    <StatefulRouteSelector onChange={onChange} selectedRouteIds={pendingRouteIds} />
);
export default RouteSelector;
