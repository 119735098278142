import { ID } from '../scalars';
import { String } from '../scalars';
import { Boolean } from '../scalars';
import { Int } from '../scalars';
import { Float } from '../scalars';
import { BlockId } from '../scalars';
import { DateTime } from '../scalars';
import { IsoDateString } from '../scalars';
import { LineString } from '../scalars';
import { Point } from '../scalars';
import { ServiceDate } from '../scalars';
import { TripId } from '../scalars';
import { TripKey } from '../scalars';
import { VehicleId } from '../scalars';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: ID;
  String: String;
  Boolean: Boolean;
  Int: Int;
  Float: Float;
  BlockId: BlockId;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: DateTime;
  IsoDateString: IsoDateString;
  LineString: LineString;
  Point: Point;
  ServiceDate: ServiceDate;
  TripId: TripId;
  TripKey: TripKey;
  VehicleId: VehicleId;
};

export type AlertInput = {
  description: Array<TranslatedStringInput>;
  enabled: Scalars['Boolean'];
  endTime: InputMaybe<Scalars['DateTime']>;
  routeIds: Array<Scalars['String']>;
  startTime: Scalars['DateTime'];
  summary: Array<TranslatedStringInput>;
};

export type BaseDispatcherMessage = {
  message: Scalars['String'];
  vehicles: Array<Scalars['String']>;
};

export type Booking = {
  bookingId: Scalars['ID'];
  cancelledAt: Maybe<Scalars['IsoDateString']>;
  createdAt: Scalars['IsoDateString'];
  direction: BookingDirection;
  finalizedAt: Maybe<Scalars['IsoDateString']>;
  maxTimeAtUserCoordinate: Scalars['IsoDateString'];
  minTimeAtUserCoordinate: Scalars['IsoDateString'];
  passengerCount: Scalars['Float'];
  route: Route;
  status: BookingStatus;
  stop: Maybe<Stop>;
  stopId: Maybe<Scalars['String']>;
  timeAtStop: Maybe<Scalars['IsoDateString']>;
  timeAtUserCoordinate: Maybe<Scalars['IsoDateString']>;
  tripKey: Scalars['String'];
  user: User;
  userCoordinate: Scalars['Point'];
  userId: Scalars['String'];
  walkingPath: Maybe<Scalars['LineString']>;
  zoneId: Scalars['String'];
};

export enum BookingDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export enum BookingStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Finalized = 'FINALIZED'
}

export type CursorPagination = {
  cursor: InputMaybe<Scalars['String']>;
  rows: Scalars['Int'];
};

export type CursorPaginationInfo = {
  nextCursor: Maybe<Scalars['String']>;
  previousCursor: Maybe<Scalars['String']>;
  total: Maybe<Scalars['Int']>;
};

export type Device = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
  lastUpdated: Scalars['String'];
  online: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  vehicleId: Maybe<Scalars['String']>;
};

export type DispatcherMessage = {
  id: Scalars['ID'];
  message: Scalars['String'];
  recipients: Array<Recipient>;
  sender: Maybe<User>;
  sent: Scalars['String'];
  vehicles: Array<Scalars['String']>;
};

export type DispatcherMessageInput = {
  message: Scalars['String'];
  vehicles: Array<Scalars['String']>;
};

export type Mutation = {
  createAlert: Scalars['String'];
  deleteAlert: TransitAlert;
  sendMessage: DispatcherMessage;
  updateAlert: TransitAlert;
};


export type MutationCreateAlertArgs = {
  alert: AlertInput;
};


export type MutationDeleteAlertArgs = {
  alertId: Scalars['String'];
};


export type MutationSendMessageArgs = {
  messageInput: DispatcherMessageInput;
};


export type MutationUpdateAlertArgs = {
  alert: AlertInput;
  alertId: Scalars['String'];
};

export type OffsetLimitPagination = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type OffsetLimitPaginationInfo = {
  total: Maybe<Scalars['Int']>;
};

export type PaginatedDispatcherMessageResponse = {
  items: Array<DispatcherMessage>;
  paginationInfo: CursorPaginationInfo;
};

export type PaginatedTransitAlertResponse = {
  items: Array<TransitAlert>;
  paginationInfo: OffsetLimitPaginationInfo;
};

export type Query = {
  alert: TransitAlert;
  alerts: PaginatedTransitAlertResponse;
  booking: Maybe<Booking>;
  bookings: Array<Booking>;
  device: Maybe<Device>;
  devicesOnline: Array<Device>;
  dispatcherMessages: PaginatedDispatcherMessageResponse;
  getUser: Maybe<User>;
  routes: Array<Route>;
  stop: Stop;
  stops: Array<Stop>;
  trip: Maybe<Trip>;
  tripPatterns: Array<TripPattern>;
  tripWithSchedule: Maybe<TripWithSchedule>;
  vehicle: Maybe<RealtimeVehicle>;
  vehiclesOnline: Array<RealtimeVehicle>;
  viewer: Viewer;
};


export type QueryAlertArgs = {
  alertId: Scalars['String'];
};


export type QueryAlertsArgs = {
  pagination: OffsetLimitPagination;
};


export type QueryBookingArgs = {
  id: Scalars['String'];
};


export type QueryDeviceArgs = {
  id: Scalars['String'];
};


export type QueryDispatcherMessagesArgs = {
  cursor: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  id: Scalars['String'];
};


export type QueryStopArgs = {
  id: Scalars['String'];
};


export type QueryTripArgs = {
  tripKey: Scalars['TripKey'];
};


export type QueryTripPatternsArgs = {
  routeId: Scalars['String'];
};


export type QueryTripWithScheduleArgs = {
  tripKey: Scalars['TripKey'];
};


export type QueryVehicleArgs = {
  id: Scalars['VehicleId'];
};

export type RealtimeVehicle = {
  actualStopIndex: Maybe<Scalars['Float']>;
  actualStopSequence: Maybe<Scalars['Float']>;
  atActualStop: Maybe<Scalars['Boolean']>;
  delay: Maybe<Scalars['Float']>;
  delayStatus: Maybe<Scalars['String']>;
  device: Maybe<Device>;
  driverName: Maybe<Scalars['String']>;
  id: Scalars['VehicleId'];
  instant: Scalars['String'];
  latitude: Maybe<Scalars['Float']>;
  licensePlate: Scalars['String'];
  longitude: Maybe<Scalars['Float']>;
  model: Scalars['String'];
  position: Maybe<Scalars['Point']>;
  stopIndexCount: Maybe<Scalars['Float']>;
  trip: Maybe<TripWithSchedule>;
  tripKey: Maybe<Scalars['TripKey']>;
  vehicleBlockId: Maybe<Scalars['BlockId']>;
  /** @deprecated Prefer ID because of caching */
  vehicleId: Scalars['VehicleId'];
};

export type Recipient = {
  acknowledgeTime: Maybe<Scalars['IsoDateString']>;
  id: Scalars['Int'];
  receivedTime: Maybe<Scalars['IsoDateString']>;
  shownTime: Maybe<Scalars['IsoDateString']>;
  status: Maybe<RecipientStatus>;
  vehicleId: Scalars['String'];
};

export enum RecipientStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Received = 'RECEIVED',
  Sent = 'SENT',
  Shown = 'SHOWN'
}

export type Route = {
  color: Scalars['String'];
  desc: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  longName: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  sortOrder: Scalars['Int'];
  textColor: Scalars['String'];
  tripPatterns: Array<TripPattern>;
};

export type ShapePoint = {
  distTraveled: Scalars['Float'];
  location: Scalars['Point'];
};

export type Stop = {
  code: Scalars['String'];
  id: Scalars['ID'];
  location: Scalars['Point'];
  name: Scalars['String'];
};

export type StopTime = {
  arrivalTime: Scalars['IsoDateString'];
  departureTime: Scalars['IsoDateString'];
  stop: Stop;
  stopSequence: Scalars['Int'];
};

export type TransitAlert = {
  description: Array<TranslatedString>;
  endTime: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  modifiedTime: Scalars['DateTime'];
  routes: Array<TransitAlertAffectedRoute>;
  startTime: Scalars['DateTime'];
  status: TransitAlertStatus;
  summary: Array<TranslatedString>;
};

export type TransitAlertAffectedRoute = {
  data: Maybe<Route>;
  id: Scalars['ID'];
};

export enum TransitAlertStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Stale = 'STALE'
}

export type TranslatedString = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type TranslatedStringInput = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type Trip = {
  directionId: Maybe<Scalars['String']>;
  headsign: Scalars['String'];
  route: Route;
  routeId: Scalars['String'];
  serviceDate: Scalars['ServiceDate'];
  shape: Maybe<Array<ShapePoint>>;
  tripId: Scalars['TripId'];
  tripKey: Scalars['TripKey'];
  valid: Scalars['Boolean'];
  vehicle: Maybe<RealtimeVehicle>;
  vehicleId: Maybe<Scalars['VehicleId']>;
  wheelchairAccessible: Scalars['Boolean'];
};

export type TripPattern = {
  endStop: Stop;
  headsign: Scalars['String'];
  patternId: Scalars['ID'];
  patternName: Scalars['String'];
  startStop: Stop;
};

export type TripWithSchedule = {
  actualSchedule: Array<StopTime>;
  directionId: Maybe<Scalars['String']>;
  headsign: Scalars['String'];
  modifiedSchedule: Maybe<Array<StopTime>>;
  originalSchedule: Array<StopTime>;
  predictedSchedule: Maybe<Array<StopTime>>;
  route: Route;
  routeId: Scalars['String'];
  serviceDate: Scalars['ServiceDate'];
  shape: Maybe<Array<ShapePoint>>;
  tripId: Scalars['TripId'];
  tripKey: Scalars['TripKey'];
  valid: Scalars['Boolean'];
  vehicle: Maybe<RealtimeVehicle>;
  vehicleId: Maybe<Scalars['VehicleId']>;
  wheelchairAccessible: Scalars['Boolean'];
};

export type User = {
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Viewer = {
  fullName: Scalars['String'];
  isAuthenticated: Scalars['Boolean'];
  locale: Scalars['String'];
  roles: Array<Scalars['String']>;
  user: User;
  username: Scalars['String'];
};

export type RouteBoxFragment = { id: ID, shortName: String, color: String, textColor: String };

export type FormattedStopFragment = { name: String, code: String };

export type BookingListFragment = { bookingId: ID, status: BookingStatus, userId: String, zoneId: String, createdAt: IsoDateString, finalizedAt: IsoDateString | null, cancelledAt: IsoDateString | null, direction: BookingDirection, tripKey: String, passengerCount: Float, userCoordinate: Point, minTimeAtUserCoordinate: IsoDateString, maxTimeAtUserCoordinate: IsoDateString, stopId: String | null, timeAtUserCoordinate: IsoDateString | null, timeAtStop: IsoDateString | null, walkingPath: LineString | null, user: { name: String }, route: { id: ID, shortName: String, color: String, textColor: String }, stop: { name: String, code: String } | null };

export type RoutesForCreateActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type RoutesForCreateActivityQuery = { routes: Array<{ id: ID, color: String, textColor: String, longName: String | null, shortName: String }> };

export type RoutesForRouteSelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type RoutesForRouteSelectorQuery = { routes: Array<{ id: ID, shortName: String, color: String, textColor: String, label: String, sortOrder: Int }> };

export type InfiniteScrollFragment = { nextCursor: String | null };

export type TranslatedStringFragment = { locale: String, text: String };

export type RoutesForBlocksOverviewFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type RoutesForBlocksOverviewFilterQuery = { routes: Array<{ id: ID, shortName: String, color: String, textColor: String, label: String, sortOrder: Int }> };

export type BookingsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingsPageQuery = { bookings: Array<{ bookingId: ID, status: BookingStatus, userId: String, zoneId: String, createdAt: IsoDateString, finalizedAt: IsoDateString | null, cancelledAt: IsoDateString | null, direction: BookingDirection, tripKey: String, passengerCount: Float, userCoordinate: Point, minTimeAtUserCoordinate: IsoDateString, maxTimeAtUserCoordinate: IsoDateString, stopId: String | null, timeAtUserCoordinate: IsoDateString | null, timeAtStop: IsoDateString | null, walkingPath: LineString | null, user: { name: String }, route: { id: ID, shortName: String, color: String, textColor: String }, stop: { name: String, code: String } | null }> };

export type DisplayedStopFragment = { stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } };

export type DisplayedVehicleFragment = { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null };

export type TripShapePointFragment = { location: Point };

export type DisplayedTripFragment = { tripKey: TripKey, headsign: String, directionId: String | null, route: { shortName: String }, actualSchedule: Array<{ stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } }>, shape: Array<{ location: Point }> | null };

export type OnlineVehicleFragment = { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null, trip: { headsign: String, directionId: String | null, route: { shortName: String } } | null };

export type OnlineVehiclesPositionQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type OnlineVehiclesPositionQueryQuery = { vehiclesOnline: Array<{ id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null, trip: { headsign: String, directionId: String | null, route: { shortName: String } } | null }> };

export type TripDetailsFragment = { tripKey: TripKey, headsign: String, directionId: String | null, vehicle: { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null } | null, route: { shortName: String }, actualSchedule: Array<{ stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } }>, shape: Array<{ location: Point }> | null };

export type TripQueryVariables = Exact<{
  tripKey: Scalars['TripKey'];
}>;


export type TripQuery = { tripWithSchedule: { tripKey: TripKey, headsign: String, directionId: String | null, vehicle: { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null } | null, route: { shortName: String }, actualSchedule: Array<{ stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } }>, shape: Array<{ location: Point }> | null } | null };

export type VehiclesOnlineQueryVariables = Exact<{ [key: string]: never; }>;


export type VehiclesOnlineQuery = { vehiclesOnline: Array<{ id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null, trip: { tripKey: TripKey } | null }> };

export type VehicleDetailsFragment = { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null, trip: { tripKey: TripKey, headsign: String, directionId: String | null, route: { shortName: String }, actualSchedule: Array<{ stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } }>, shape: Array<{ location: Point }> | null } | null };

export type VehicleQueryVariables = Exact<{
  vehicleId: Scalars['VehicleId'];
}>;


export type VehicleQuery = { vehicle: { id: VehicleId, licensePlate: String, driverName: String | null, position: Point | null, trip: { tripKey: TripKey, headsign: String, directionId: String | null, route: { shortName: String }, actualSchedule: Array<{ stopSequence: Int, arrivalTime: IsoDateString, departureTime: IsoDateString, stop: { id: ID, code: String, name: String, location: Point } }>, shape: Array<{ location: Point }> | null } | null } | null };

export type VehiclePositionForMapRedirectQueryVariables = Exact<{
  vehicleId: Scalars['VehicleId'];
}>;


export type VehiclePositionForMapRedirectQuery = { vehicle: { vehicleId: VehicleId, latitude: Float | null, longitude: Float | null, trip: { tripId: TripId, tripKey: TripKey, serviceDate: ServiceDate } | null } | null };

export type RecipientItemFragment = { id: Int, vehicleId: String, status: RecipientStatus | null, receivedTime: IsoDateString | null, shownTime: IsoDateString | null, acknowledgeTime: IsoDateString | null };

export type DispatcherMessageItemFragment = { id: ID, sent: String, message: String, sender: { name: String } | null, recipients: Array<{ id: Int, vehicleId: String, status: RecipientStatus | null, receivedTime: IsoDateString | null, shownTime: IsoDateString | null, acknowledgeTime: IsoDateString | null }> };

export type DispatcherMessagesQueryVariables = Exact<{
  cursor: InputMaybe<Scalars['String']>;
}>;


export type DispatcherMessagesQuery = { dispatcherMessages: { items: Array<{ id: ID, sent: String, message: String, sender: { name: String } | null, recipients: Array<{ id: Int, vehicleId: String, status: RecipientStatus | null, receivedTime: IsoDateString | null, shownTime: IsoDateString | null, acknowledgeTime: IsoDateString | null }> }>, paginationInfo: { nextCursor: String | null } } };

export type SendMessageMutationVariables = Exact<{
  message: DispatcherMessageInput;
}>;


export type SendMessageMutation = { sendMessage: { id: ID } };

export type TransitAlertSummaryFragment = { id: String, startTime: DateTime, endTime: DateTime | null, status: TransitAlertStatus, modifiedTime: DateTime, summary: Array<{ locale: String, text: String }>, routes: Array<{ id: ID, data: { id: ID, shortName: String, color: String, textColor: String } | null }> };

export type TransitAlertDetailsFragment = { id: String, startTime: DateTime, endTime: DateTime | null, status: TransitAlertStatus, modifiedTime: DateTime, description: Array<{ locale: String, text: String }>, summary: Array<{ locale: String, text: String }>, routes: Array<{ id: ID, data: { id: ID, shortName: String, color: String, textColor: String } | null }> };

export type TransitAlertsQueryVariables = Exact<{
  pagination: OffsetLimitPagination;
}>;


export type TransitAlertsQuery = { alerts: { items: Array<{ id: String, startTime: DateTime, endTime: DateTime | null, status: TransitAlertStatus, modifiedTime: DateTime, summary: Array<{ locale: String, text: String }>, routes: Array<{ id: ID, data: { id: ID, shortName: String, color: String, textColor: String } | null }> }>, paginationInfo: { total: Int | null } } };

export type GetAlertDetailsQueryVariables = Exact<{
  alertId: Scalars['String'];
}>;


export type GetAlertDetailsQuery = { alert: { id: String, startTime: DateTime, endTime: DateTime | null, status: TransitAlertStatus, modifiedTime: DateTime, description: Array<{ locale: String, text: String }>, summary: Array<{ locale: String, text: String }>, routes: Array<{ id: ID, data: { id: ID, shortName: String, color: String, textColor: String } | null }> } };

export type CreateTransitAlertMutationVariables = Exact<{
  alertInput: AlertInput;
}>;


export type CreateTransitAlertMutation = { createAlert: String };

export type UpdateAlertMutationVariables = Exact<{
  alertId: Scalars['String'];
  alertInput: AlertInput;
}>;


export type UpdateAlertMutation = { updateAlert: { id: String } };

export type DeleteAlertMutationVariables = Exact<{
  alertId: Scalars['String'];
}>;


export type DeleteAlertMutation = { deleteAlert: { id: String } };

export const RouteBoxFragmentDoc = gql`
    fragment RouteBox on Route {
  id
  shortName
  color
  textColor
}
    `;
export const FormattedStopFragmentDoc = gql`
    fragment FormattedStop on Stop {
  name
  code
}
    `;
export const BookingListFragmentDoc = gql`
    fragment BookingList on Booking {
  bookingId
  status
  userId
  user {
    name
  }
  zoneId
  route {
    ...RouteBox
  }
  createdAt
  finalizedAt
  cancelledAt
  direction
  tripKey
  passengerCount
  userCoordinate
  minTimeAtUserCoordinate
  maxTimeAtUserCoordinate
  stopId
  stop {
    ...FormattedStop
  }
  timeAtUserCoordinate
  timeAtStop
  walkingPath
}
    ${RouteBoxFragmentDoc}
${FormattedStopFragmentDoc}`;
export const InfiniteScrollFragmentDoc = gql`
    fragment InfiniteScroll on CursorPaginationInfo {
  nextCursor
}
    `;
export const DisplayedVehicleFragmentDoc = gql`
    fragment DisplayedVehicle on RealtimeVehicle {
  id
  licensePlate
  driverName
  position
}
    `;
export const OnlineVehicleFragmentDoc = gql`
    fragment OnlineVehicle on RealtimeVehicle {
  ...DisplayedVehicle
  trip {
    route {
      shortName
    }
    headsign
    directionId
  }
}
    ${DisplayedVehicleFragmentDoc}`;
export const DisplayedStopFragmentDoc = gql`
    fragment DisplayedStop on StopTime {
  stop {
    id
    code
    name
    location
  }
  stopSequence
  arrivalTime
  departureTime
}
    `;
export const TripShapePointFragmentDoc = gql`
    fragment TripShapePoint on ShapePoint {
  location
}
    `;
export const DisplayedTripFragmentDoc = gql`
    fragment DisplayedTrip on TripWithSchedule {
  tripKey
  headsign
  directionId
  route {
    shortName
  }
  actualSchedule {
    ...DisplayedStop
  }
  shape {
    ...TripShapePoint
  }
}
    ${DisplayedStopFragmentDoc}
${TripShapePointFragmentDoc}`;
export const TripDetailsFragmentDoc = gql`
    fragment TripDetails on TripWithSchedule {
  ...DisplayedTrip
  vehicle {
    ...DisplayedVehicle
  }
}
    ${DisplayedTripFragmentDoc}
${DisplayedVehicleFragmentDoc}`;
export const VehicleDetailsFragmentDoc = gql`
    fragment VehicleDetails on RealtimeVehicle {
  ...DisplayedVehicle
  trip {
    ...DisplayedTrip
  }
}
    ${DisplayedVehicleFragmentDoc}
${DisplayedTripFragmentDoc}`;
export const RecipientItemFragmentDoc = gql`
    fragment RecipientItem on Recipient {
  id
  vehicleId
  status
  receivedTime
  shownTime
  acknowledgeTime
}
    `;
export const DispatcherMessageItemFragmentDoc = gql`
    fragment DispatcherMessageItem on DispatcherMessage {
  id
  sent
  message
  sender {
    name
  }
  recipients {
    ...RecipientItem
  }
}
    ${RecipientItemFragmentDoc}`;
export const TranslatedStringFragmentDoc = gql`
    fragment TranslatedString on TranslatedString {
  locale
  text
}
    `;
export const TransitAlertSummaryFragmentDoc = gql`
    fragment TransitAlertSummary on TransitAlert {
  id
  startTime
  endTime
  status
  modifiedTime
  summary {
    ...TranslatedString
  }
  routes {
    id
    data {
      ...RouteBox
    }
  }
}
    ${TranslatedStringFragmentDoc}
${RouteBoxFragmentDoc}`;
export const TransitAlertDetailsFragmentDoc = gql`
    fragment TransitAlertDetails on TransitAlert {
  ...TransitAlertSummary
  description {
    ...TranslatedString
  }
}
    ${TransitAlertSummaryFragmentDoc}
${TranslatedStringFragmentDoc}`;
export const RoutesForCreateActivityDocument = gql`
    query RoutesForCreateActivity {
  routes {
    id
    color
    textColor
    longName
    shortName
  }
}
    `;

/**
 * __useRoutesForCreateActivityQuery__
 *
 * To run a query within a React component, call `useRoutesForCreateActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutesForCreateActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesForCreateActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutesForCreateActivityQuery(baseOptions?: Apollo.QueryHookOptions<RoutesForCreateActivityQuery, RoutesForCreateActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutesForCreateActivityQuery, RoutesForCreateActivityQueryVariables>(RoutesForCreateActivityDocument, options);
      }
export function useRoutesForCreateActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutesForCreateActivityQuery, RoutesForCreateActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutesForCreateActivityQuery, RoutesForCreateActivityQueryVariables>(RoutesForCreateActivityDocument, options);
        }
export type RoutesForCreateActivityQueryHookResult = ReturnType<typeof useRoutesForCreateActivityQuery>;
export type RoutesForCreateActivityLazyQueryHookResult = ReturnType<typeof useRoutesForCreateActivityLazyQuery>;
export type RoutesForCreateActivityQueryResult = Apollo.QueryResult<RoutesForCreateActivityQuery, RoutesForCreateActivityQueryVariables>;
export const RoutesForRouteSelectorDocument = gql`
    query RoutesForRouteSelector {
  routes {
    id
    shortName
    color
    textColor
    label
    sortOrder
  }
}
    `;

/**
 * __useRoutesForRouteSelectorQuery__
 *
 * To run a query within a React component, call `useRoutesForRouteSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutesForRouteSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesForRouteSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutesForRouteSelectorQuery(baseOptions?: Apollo.QueryHookOptions<RoutesForRouteSelectorQuery, RoutesForRouteSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutesForRouteSelectorQuery, RoutesForRouteSelectorQueryVariables>(RoutesForRouteSelectorDocument, options);
      }
export function useRoutesForRouteSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutesForRouteSelectorQuery, RoutesForRouteSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutesForRouteSelectorQuery, RoutesForRouteSelectorQueryVariables>(RoutesForRouteSelectorDocument, options);
        }
export type RoutesForRouteSelectorQueryHookResult = ReturnType<typeof useRoutesForRouteSelectorQuery>;
export type RoutesForRouteSelectorLazyQueryHookResult = ReturnType<typeof useRoutesForRouteSelectorLazyQuery>;
export type RoutesForRouteSelectorQueryResult = Apollo.QueryResult<RoutesForRouteSelectorQuery, RoutesForRouteSelectorQueryVariables>;
export const RoutesForBlocksOverviewFilterDocument = gql`
    query RoutesForBlocksOverviewFilter {
  routes {
    id
    shortName
    color
    textColor
    label
    sortOrder
  }
}
    `;

/**
 * __useRoutesForBlocksOverviewFilterQuery__
 *
 * To run a query within a React component, call `useRoutesForBlocksOverviewFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoutesForBlocksOverviewFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoutesForBlocksOverviewFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoutesForBlocksOverviewFilterQuery(baseOptions?: Apollo.QueryHookOptions<RoutesForBlocksOverviewFilterQuery, RoutesForBlocksOverviewFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoutesForBlocksOverviewFilterQuery, RoutesForBlocksOverviewFilterQueryVariables>(RoutesForBlocksOverviewFilterDocument, options);
      }
export function useRoutesForBlocksOverviewFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoutesForBlocksOverviewFilterQuery, RoutesForBlocksOverviewFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoutesForBlocksOverviewFilterQuery, RoutesForBlocksOverviewFilterQueryVariables>(RoutesForBlocksOverviewFilterDocument, options);
        }
export type RoutesForBlocksOverviewFilterQueryHookResult = ReturnType<typeof useRoutesForBlocksOverviewFilterQuery>;
export type RoutesForBlocksOverviewFilterLazyQueryHookResult = ReturnType<typeof useRoutesForBlocksOverviewFilterLazyQuery>;
export type RoutesForBlocksOverviewFilterQueryResult = Apollo.QueryResult<RoutesForBlocksOverviewFilterQuery, RoutesForBlocksOverviewFilterQueryVariables>;
export const BookingsPageDocument = gql`
    query BookingsPage {
  bookings {
    ...BookingList
  }
}
    ${BookingListFragmentDoc}`;

/**
 * __useBookingsPageQuery__
 *
 * To run a query within a React component, call `useBookingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingsPageQuery(baseOptions?: Apollo.QueryHookOptions<BookingsPageQuery, BookingsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsPageQuery, BookingsPageQueryVariables>(BookingsPageDocument, options);
      }
export function useBookingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsPageQuery, BookingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsPageQuery, BookingsPageQueryVariables>(BookingsPageDocument, options);
        }
export type BookingsPageQueryHookResult = ReturnType<typeof useBookingsPageQuery>;
export type BookingsPageLazyQueryHookResult = ReturnType<typeof useBookingsPageLazyQuery>;
export type BookingsPageQueryResult = Apollo.QueryResult<BookingsPageQuery, BookingsPageQueryVariables>;
export const OnlineVehiclesPositionQueryDocument = gql`
    query OnlineVehiclesPositionQuery {
  vehiclesOnline {
    ...OnlineVehicle
  }
}
    ${OnlineVehicleFragmentDoc}`;

/**
 * __useOnlineVehiclesPositionQueryQuery__
 *
 * To run a query within a React component, call `useOnlineVehiclesPositionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineVehiclesPositionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineVehiclesPositionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineVehiclesPositionQueryQuery(baseOptions?: Apollo.QueryHookOptions<OnlineVehiclesPositionQueryQuery, OnlineVehiclesPositionQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnlineVehiclesPositionQueryQuery, OnlineVehiclesPositionQueryQueryVariables>(OnlineVehiclesPositionQueryDocument, options);
      }
export function useOnlineVehiclesPositionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnlineVehiclesPositionQueryQuery, OnlineVehiclesPositionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnlineVehiclesPositionQueryQuery, OnlineVehiclesPositionQueryQueryVariables>(OnlineVehiclesPositionQueryDocument, options);
        }
export type OnlineVehiclesPositionQueryQueryHookResult = ReturnType<typeof useOnlineVehiclesPositionQueryQuery>;
export type OnlineVehiclesPositionQueryLazyQueryHookResult = ReturnType<typeof useOnlineVehiclesPositionQueryLazyQuery>;
export type OnlineVehiclesPositionQueryQueryResult = Apollo.QueryResult<OnlineVehiclesPositionQueryQuery, OnlineVehiclesPositionQueryQueryVariables>;
export const TripDocument = gql`
    query Trip($tripKey: TripKey!) {
  tripWithSchedule(tripKey: $tripKey) {
    ...TripDetails
  }
}
    ${TripDetailsFragmentDoc}`;

/**
 * __useTripQuery__
 *
 * To run a query within a React component, call `useTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripQuery({
 *   variables: {
 *      tripKey: // value for 'tripKey'
 *   },
 * });
 */
export function useTripQuery(baseOptions: Apollo.QueryHookOptions<TripQuery, TripQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TripQuery, TripQueryVariables>(TripDocument, options);
      }
export function useTripLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TripQuery, TripQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TripQuery, TripQueryVariables>(TripDocument, options);
        }
export type TripQueryHookResult = ReturnType<typeof useTripQuery>;
export type TripLazyQueryHookResult = ReturnType<typeof useTripLazyQuery>;
export type TripQueryResult = Apollo.QueryResult<TripQuery, TripQueryVariables>;
export const VehiclesOnlineDocument = gql`
    query VehiclesOnline {
  vehiclesOnline {
    ...DisplayedVehicle
    trip {
      tripKey
    }
  }
}
    ${DisplayedVehicleFragmentDoc}`;

/**
 * __useVehiclesOnlineQuery__
 *
 * To run a query within a React component, call `useVehiclesOnlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesOnlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesOnlineQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesOnlineQuery(baseOptions?: Apollo.QueryHookOptions<VehiclesOnlineQuery, VehiclesOnlineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehiclesOnlineQuery, VehiclesOnlineQueryVariables>(VehiclesOnlineDocument, options);
      }
export function useVehiclesOnlineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehiclesOnlineQuery, VehiclesOnlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehiclesOnlineQuery, VehiclesOnlineQueryVariables>(VehiclesOnlineDocument, options);
        }
export type VehiclesOnlineQueryHookResult = ReturnType<typeof useVehiclesOnlineQuery>;
export type VehiclesOnlineLazyQueryHookResult = ReturnType<typeof useVehiclesOnlineLazyQuery>;
export type VehiclesOnlineQueryResult = Apollo.QueryResult<VehiclesOnlineQuery, VehiclesOnlineQueryVariables>;
export const VehicleDocument = gql`
    query Vehicle($vehicleId: VehicleId!) {
  vehicle(id: $vehicleId) {
    ...VehicleDetails
  }
}
    ${VehicleDetailsFragmentDoc}`;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleQuery(baseOptions: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
      }
export function useVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, options);
        }
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleQueryResult = Apollo.QueryResult<VehicleQuery, VehicleQueryVariables>;
export const VehiclePositionForMapRedirectDocument = gql`
    query VehiclePositionForMapRedirect($vehicleId: VehicleId!) {
  vehicle(id: $vehicleId) {
    vehicleId
    latitude
    longitude
    trip {
      tripId
      tripKey
      serviceDate
    }
  }
}
    `;

/**
 * __useVehiclePositionForMapRedirectQuery__
 *
 * To run a query within a React component, call `useVehiclePositionForMapRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclePositionForMapRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclePositionForMapRedirectQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehiclePositionForMapRedirectQuery(baseOptions: Apollo.QueryHookOptions<VehiclePositionForMapRedirectQuery, VehiclePositionForMapRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehiclePositionForMapRedirectQuery, VehiclePositionForMapRedirectQueryVariables>(VehiclePositionForMapRedirectDocument, options);
      }
export function useVehiclePositionForMapRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehiclePositionForMapRedirectQuery, VehiclePositionForMapRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehiclePositionForMapRedirectQuery, VehiclePositionForMapRedirectQueryVariables>(VehiclePositionForMapRedirectDocument, options);
        }
export type VehiclePositionForMapRedirectQueryHookResult = ReturnType<typeof useVehiclePositionForMapRedirectQuery>;
export type VehiclePositionForMapRedirectLazyQueryHookResult = ReturnType<typeof useVehiclePositionForMapRedirectLazyQuery>;
export type VehiclePositionForMapRedirectQueryResult = Apollo.QueryResult<VehiclePositionForMapRedirectQuery, VehiclePositionForMapRedirectQueryVariables>;
export const DispatcherMessagesDocument = gql`
    query dispatcherMessages($cursor: String) {
  dispatcherMessages(cursor: $cursor) {
    items {
      ...DispatcherMessageItem
    }
    paginationInfo {
      ...InfiniteScroll
    }
  }
}
    ${DispatcherMessageItemFragmentDoc}
${InfiniteScrollFragmentDoc}`;

/**
 * __useDispatcherMessagesQuery__
 *
 * To run a query within a React component, call `useDispatcherMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatcherMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatcherMessagesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDispatcherMessagesQuery(baseOptions?: Apollo.QueryHookOptions<DispatcherMessagesQuery, DispatcherMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DispatcherMessagesQuery, DispatcherMessagesQueryVariables>(DispatcherMessagesDocument, options);
      }
export function useDispatcherMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DispatcherMessagesQuery, DispatcherMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DispatcherMessagesQuery, DispatcherMessagesQueryVariables>(DispatcherMessagesDocument, options);
        }
export type DispatcherMessagesQueryHookResult = ReturnType<typeof useDispatcherMessagesQuery>;
export type DispatcherMessagesLazyQueryHookResult = ReturnType<typeof useDispatcherMessagesLazyQuery>;
export type DispatcherMessagesQueryResult = Apollo.QueryResult<DispatcherMessagesQuery, DispatcherMessagesQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($message: DispatcherMessageInput!) {
  sendMessage(messageInput: $message) {
    id
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const TransitAlertsDocument = gql`
    query TransitAlerts($pagination: OffsetLimitPagination!) {
  alerts(pagination: $pagination) {
    items {
      ...TransitAlertSummary
    }
    paginationInfo {
      total
    }
  }
}
    ${TransitAlertSummaryFragmentDoc}`;

/**
 * __useTransitAlertsQuery__
 *
 * To run a query within a React component, call `useTransitAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransitAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransitAlertsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTransitAlertsQuery(baseOptions: Apollo.QueryHookOptions<TransitAlertsQuery, TransitAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransitAlertsQuery, TransitAlertsQueryVariables>(TransitAlertsDocument, options);
      }
export function useTransitAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransitAlertsQuery, TransitAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransitAlertsQuery, TransitAlertsQueryVariables>(TransitAlertsDocument, options);
        }
export type TransitAlertsQueryHookResult = ReturnType<typeof useTransitAlertsQuery>;
export type TransitAlertsLazyQueryHookResult = ReturnType<typeof useTransitAlertsLazyQuery>;
export type TransitAlertsQueryResult = Apollo.QueryResult<TransitAlertsQuery, TransitAlertsQueryVariables>;
export const GetAlertDetailsDocument = gql`
    query getAlertDetails($alertId: String!) {
  alert(alertId: $alertId) {
    ...TransitAlertDetails
  }
}
    ${TransitAlertDetailsFragmentDoc}`;

/**
 * __useGetAlertDetailsQuery__
 *
 * To run a query within a React component, call `useGetAlertDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertDetailsQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useGetAlertDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>(GetAlertDetailsDocument, options);
      }
export function useGetAlertDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>(GetAlertDetailsDocument, options);
        }
export type GetAlertDetailsQueryHookResult = ReturnType<typeof useGetAlertDetailsQuery>;
export type GetAlertDetailsLazyQueryHookResult = ReturnType<typeof useGetAlertDetailsLazyQuery>;
export type GetAlertDetailsQueryResult = Apollo.QueryResult<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>;
export const CreateTransitAlertDocument = gql`
    mutation createTransitAlert($alertInput: AlertInput!) {
  createAlert(alert: $alertInput)
}
    `;
export type CreateTransitAlertMutationFn = Apollo.MutationFunction<CreateTransitAlertMutation, CreateTransitAlertMutationVariables>;

/**
 * __useCreateTransitAlertMutation__
 *
 * To run a mutation, you first call `useCreateTransitAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransitAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransitAlertMutation, { data, loading, error }] = useCreateTransitAlertMutation({
 *   variables: {
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useCreateTransitAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransitAlertMutation, CreateTransitAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransitAlertMutation, CreateTransitAlertMutationVariables>(CreateTransitAlertDocument, options);
      }
export type CreateTransitAlertMutationHookResult = ReturnType<typeof useCreateTransitAlertMutation>;
export type CreateTransitAlertMutationResult = Apollo.MutationResult<CreateTransitAlertMutation>;
export type CreateTransitAlertMutationOptions = Apollo.BaseMutationOptions<CreateTransitAlertMutation, CreateTransitAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation updateAlert($alertId: String!, $alertInput: AlertInput!) {
  updateAlert(alertId: $alertId, alert: $alertInput) {
    id
  }
}
    `;
export type UpdateAlertMutationFn = Apollo.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, options);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = Apollo.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation deleteAlert($alertId: String!) {
  deleteAlert(alertId: $alertId) {
    id
  }
}
    `;
export type DeleteAlertMutationFn = Apollo.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, options);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = Apollo.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = Apollo.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;