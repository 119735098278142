import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface Props {
    onClose: () => void;
}

const CloseButton: React.FC<Props> = ({ onClose }) => (
    <IconButton onClick={onClose} size="large">
        <CloseIcon />
    </IconButton>
);

export default CloseButton;
