import type React from 'react';
import { useContext, useRef } from 'react';
import { MapContext } from 'react-mapbox-gl';
import type { DisplayedTripFragment, DisplayedVehicleFragment } from '../../graphql/graphql-typings';
import { FIT_BOUNDS_OPTIONS, getBoundsForTripAndVehicle } from './utils';

interface Props {
    trip: DisplayedTripFragment | null;
    vehicles: DisplayedVehicleFragment[] | null;
}

const ZoomTo: React.FC<Props> = ({ trip, vehicles }) => {
    const zoomed = useRef<boolean>(false);

    const mapContext = useContext(MapContext);

    if (!zoomed.current) {
        const bounds = getBoundsForTripAndVehicle(trip, vehicles);
        if (bounds) {
            mapContext!.fitBounds(bounds, FIT_BOUNDS_OPTIONS);
        }
        zoomed.current = true;
    }

    return null;
};

export default ZoomTo;
