import IfRoleOrUnauthorizedFeatureMessage from '@realcity/web-frame/lib/components/Auth/IfRoleOrUnauthorizedFeatureMessage';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isDrtEnabled, isInternalMapEnabled, isMessagesEnabled } from './config';
import { Roles } from './constants';
import BlocksOverviewPage from './pages/BlocksOverview';
import BookingsPage from './pages/Bookings';
import VehiclesMapPage from './pages/Map';
import MapRedirectPage from './pages/MapRedirect';
import MessagesPage from './pages/Messages';
import CreateAlertForm from './pages/TransitAlertEditor/CreateAlertForm';
import UpdateAlertForm from './pages/TransitAlertEditor/UpdateAlertForm';
import TransitAlertPage from './pages/TransitAlerts';
import VehiclesPage from './pages/Vehicles';

const Home = () => <Redirect to="/vehicles" />;

const Routes: React.FunctionComponent = () => (
    <>
        <Route path="/" exact component={Home} />
        <Route path="/vehicles" component={VehiclesPage} />
        {isMessagesEnabled() && <Route path="/messages" exact component={MessagesPage} />}
        <Route path="/blocks" exact component={BlocksOverviewPage} />
        <Route path="/map/vehicles/:vehicleId" exact component={MapRedirectPage} />
        {isDrtEnabled() && <Route path="/bookings" exact component={BookingsPage} />}
        {isInternalMapEnabled() && <Route path="/map" exact component={VehiclesMapPage} />}
        <Route
            path="/alerts/new"
            render={() => (
                <IfRoleOrUnauthorizedFeatureMessage role={Roles.AlertsWrite}>
                    <CreateAlertForm />
                </IfRoleOrUnauthorizedFeatureMessage>
            )}
        />
        <Route
            path="/alerts/edit/:alertId"
            render={({ match }) => (
                <IfRoleOrUnauthorizedFeatureMessage role={Roles.AlertsWrite}>
                    <UpdateAlertForm alertId={match.params.alertId} />
                </IfRoleOrUnauthorizedFeatureMessage>
            )}
        />
        <Route path="/alerts" exact component={TransitAlertPage} />
    </>
);

export default Routes;
