import Typography from '@mui/material/Typography';
import isNil from 'lodash/isNil';
import React from 'react';
import type {
    BlockActivity as BlockActivityInterface,
    BlockActivityKey,
    BlockKey,
    ResolvedCoreRealtimeVehicle,
} from '../../../../common/interfaces';
import { getDelayText } from '../../components/Delay';
import type { VehicleBlock } from '../../redux/state';
import BlockActivity from './BlockActivity';
import { calculateBlockDataRowHeight, calculateLeft, getBlockRowClasses, groupActivitiesToRows } from './functions';
import './styles.css';

function calculateScheduleTime(vehicle: ResolvedCoreRealtimeVehicle): Date | null {
    if (isNil(vehicle.delay) || !vehicle.delayStatus) {
        return null;
    }

    const millis = vehicle.lastRefresh + (vehicle.delayStatus === 'WAITING' ? 1 : -1) * 1000 * vehicle.delay;
    return new Date(millis);
}

export interface Props {
    block: VehicleBlock;
    active: boolean;
    highlighted: boolean;
    begin: Date;
    time: Date;
    zoom: number;
    onActivitySelected: (blockKey: BlockKey, activityKey: BlockActivityKey, open: boolean) => void;
}

export default class BlockData extends React.PureComponent<Props> {
    private readonly renderBlockActivity = (activity: BlockActivityInterface, row: number) => {
        const { block, begin, zoom, onActivitySelected } = this.props;
        const { vehicles } = block;
        const vehicle = vehicles.find(v => v.activityKey === activity.activityKey);

        return (
            <BlockActivity
                vehicleBlockKey={block.blockKey}
                activity={activity}
                vehicle={vehicle}
                begin={begin}
                zoom={zoom}
                row={row}
                onActivitySelected={onActivitySelected}
                key={activity.activityKey}
            />
        );
    };

    private readonly calculateVehicleMarkerTime = (v: ResolvedCoreRealtimeVehicle) => {
        if (v.trip) {
            return calculateScheduleTime(v);
        }

        if (v.activityKey) {
            const { time } = this.props;
            return time;
        }

        return null;
    };

    private readonly renderVehicleMarker = (v: ResolvedCoreRealtimeVehicle) => {
        const { begin, zoom } = this.props;

        const markerTime = this.calculateVehicleMarkerTime(v);
        if (!markerTime) {
            return null;
        }
        const left = calculateLeft(begin, markerTime, zoom);

        return (
            <React.Fragment key={v.vehicleId}>
                <div className="vehicle-marker vehicle-marker--bubble" style={{ left }}>
                    <Typography variant="caption" className="vehicle-marker__delay-text">
                        {getDelayText(v.delay, v.delayStatus)}
                    </Typography>
                </div>
                <div className="vehicle-marker vehicle-marker--line" style={{ left }} />
            </React.Fragment>
        );
    };

    render() {
        const { block, active, highlighted } = this.props;
        const { activities, vehicles } = block;

        const groupedActivities = groupActivitiesToRows(block.blockId, activities);

        return (
            <div
                className={getBlockRowClasses(block, active, highlighted, 'block-data-row')}
                style={calculateBlockDataRowHeight(block.blockId, groupedActivities)}
            >
                {groupedActivities.map((activityRow, index) => activityRow.map(activity => this.renderBlockActivity(activity, index)))}
                {vehicles.map(v => this.renderVehicleMarker(v))}
            </div>
        );
    }
}
