import React from 'react';
import Typography from '@mui/material/Typography';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import './styles.css';

export interface PopupProps {
    title: React.ReactNode;
    subtitle?: string;
    children?: React.ReactNode;
}

export const Popup = React.forwardRef<HTMLDivElement, PopupProps>(({ title, subtitle, children }, ref) => (
    <div className="popup" ref={ref}>
        <div className="popup__header">
            {title && <Typography variant="h5" className="popup__title">{title}</Typography>}
            {subtitle && <Typography variant="h5" className="popup__subtitle">{subtitle}</Typography>}
        </div>
        <div className="popup__content">{children}</div>
    </div>
));

export interface PopupRowProps {
    label: React.ReactNode;
}

export const PopupRow: React.FunctionComponent<PopupRowProps> = ({ label, children }) => (
    <dl className="popup__row">
        <Typography variant="body1" className="popup__label" component="dt">{label}</Typography>
        <Typography variant="body1" className="popup__value" component="dd">{children}</Typography>
    </dl>
);

export interface PopupButtonProps extends ButtonProps {
    align: 'left' | 'right';
    target?: string;
}

export const PopupButton: React.FunctionComponent<PopupButtonProps> = ({ align, ...buttonProps }) => (
    <Button {...buttonProps} className={`popup__button popup__button--${align}`} />
);
