import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    newValue: {
        fontStyle: 'italic',
    },
    oldValue: {
        fontWeight: 'normal',
    },
});

interface Props<T> {
    original: T | null;
    modified: T | null;
    component: React.ComponentType<{ value: T }>;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const OriginalAndModified = <T,>(props: Props<T>) => {
    const classes = useStyles();

    const { original, modified, component: Component } = props;
    if (original && modified && original !== modified) {
        return (
            <>
                <span className={classes.newValue}>
                    <Component value={modified} />
                </span>
                {' '}
                <span className={classes.oldValue}>
                    (
                    <Component value={original} />
                    )
                </span>
            </>
        );
    }

    return <Component value={original || modified!} />;
};

export default OriginalAndModified;
