import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import type { ServiceDate } from '../../../../common/interfaces';
import { momentToServiceDate, serviceDateToMoment } from '../../utils';

interface Props {
    serviceDate: ServiceDate;
    onChange: (serviceDate: ServiceDate) => void;
    loading: boolean;
    disabled: boolean;
}

const useStyles = makeStyles({
    dateText: {
        marginRight: 15,
    },
});

const DateControl: React.FunctionComponent<Props> = ({ serviceDate, onChange, loading, disabled }) => {
    const classes = useStyles();
    const date = serviceDateToMoment(serviceDate);
    const onPrev = () => {
        onChange(momentToServiceDate(date.add(-1, 'day')));
    };
    const onNext = () => {
        onChange(momentToServiceDate(date.add(1, 'day')));
    };

    return (
        <>
            <IconButton onClick={onPrev} disabled={disabled || loading} size="large">
                <ArrowLeftIcon />
            </IconButton>
            {!disabled && loading && (
                <>
                    <Typography className={classes.dateText} variant="body1">
                        <FormattedDate value={date.valueOf()} />
                    </Typography>
                    <CircularProgress size={20} />
                </>
            )}
            {!disabled && !loading && (
                <Typography variant="body1">
                    <FormattedDate value={date.valueOf()} />
                </Typography>
            )}
            {disabled && (
                <Typography variant="body1">
                    <FormattedMessage id="blocks-overview.toolbar.date-picker.no-route-warning" />
                </Typography>
            )}
            <IconButton onClick={onNext} disabled={disabled} size="large">
                <ArrowRightIcon />
            </IconButton>
        </>
    );
};

export default React.memo(DateControl);
