import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { AppState, Notifications } from '../../redux/state';
import { fetchNotifications as fetchNotificationsActions } from '../../redux/actions';
import LoadableRefresher from '../../components/LoadableRefresher';
import NotificationsList from './NotificationsList';

const useStyles = makeStyles({
    paper: {
        overflow: 'auto',
        zIndex: 1000,
        position: 'absolute',
        height: 'inherit',
        bottom: 0,
        left: 0,
    },
    root: {
        width: 400,
        position: 'relative',
    },
    header: {
        padding: 10,
        display: 'inline-flex',
        alignItems: 'center',
    },
});

interface Props {
    notifications: Notifications;
    fetchNotifications: typeof fetchNotificationsActions;
}

const NotificationsSidebar: React.FC<Props> = ({ notifications, fetchNotifications }) => {
    const { header, ...drawerClasses } = useStyles();

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    return (
        <Drawer variant="permanent" anchor="right" classes={drawerClasses}>
            <div className={header}>
                <Typography variant="h4">
                    <FormattedMessage id="notifications.title" />
                </Typography>
                <LoadableRefresher fetch={fetchNotifications} loadable={notifications} />
            </div>
            <NotificationsList notifications={notifications.list} />
        </Drawer>
    );
};

export default connect(({ notifications }: AppState) => ({ notifications }), { fetchNotifications: fetchNotificationsActions })(
    NotificationsSidebar,
);
