import { useQuery } from '@apollo/client';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Block, BlockId, ServiceDate } from '../../../../common/interfaces';
import BlockSelector from '../../components/BlockSelector';
import { GET_VEHICLE_BLOCKS } from '../../graphql/rest';
import type { BlockIdSelection } from './DriverBlockSelector';

interface Props {
    vehicleBlockId: BlockId;
    serviceDate: ServiceDate;
    onChange: (vehicleBlockId: BlockIdSelection) => void;
}

const VehicleBlockSelector: React.FunctionComponent<Props> = ({ vehicleBlockId, serviceDate, onChange }) => {
    const { data } = useQuery<{ vehicleBlocks?: Block[] }>(GET_VEHICLE_BLOCKS, {
        variables: {
            serviceDate,
        },
    });

    return (
        <BlockSelector
            blocks={data?.vehicleBlocks}
            blockId={vehicleBlockId}
            onChange={onChange}
            inputLabel={<FormattedMessage id="blocks-overview.block-activity-actions.block-change.vehicle-block-id" />}
        />
    );
};

export default VehicleBlockSelector;
