import React, { useEffect, useState } from 'react';
import type { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const CustomTablePaginationActions: React.FC<TablePaginationActionsProps> = (props) => {
    const { page, onPageChange, count, rowsPerPage } = props;

    const [pageIndex, setPageIndex] = useState<number | null>(page);

    useEffect(() => {
        setPageIndex(page);
    }, [page]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 0) {
            setPageIndex(null);
            return;
        }

        setPageIndex(parseInt(event.target.value, 10) - 1);
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (pageIndex !== null) {
            onPageChange(event, pageIndex);
        }
    };

    return (
        <>
            <TablePaginationActions {...props} />

            <Box sx={{ display: 'block', flexShrink: 0, marginLeft: 2 }}>
                <FormattedMessage id="table-pagination.go-to-page" />
            </Box>

            <TextField
                type="number"
                InputProps={{ inputProps: { min: 1, max: Math.ceil(count / rowsPerPage), sx: { padding: '5px' } } }}
                sx={{ flexShrink: 0, marginLeft: 1 }}
                value={pageIndex === null ? '' : pageIndex + 1}
                onChange={onChange}
            />

            <Button sx={{ marginLeft: 1, marginRight: 1 }} onClick={onClick}>
                <FormattedMessage id="table-pagination.go" />
            </Button>
        </>
    );
};

export default CustomTablePaginationActions;
