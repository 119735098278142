import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 16,
    },
});

const MapCardContent: React.FunctionComponent = ({ children }) => {
    const classes = useStyles();

    return (
        <CardContent className={classes.root}>
            {children}
        </CardContent>
    );
};

export default MapCardContent;
