import amber from '@mui/material/colors/amber';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import type { SnackbarOrigin, SnackbarProps } from '@mui/material/Snackbar';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import classNames from 'classnames';
import type { SyntheticEvent } from 'react';
import React from 'react';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    variantIcon: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const SNACKBAR_ORIGIN: SnackbarOrigin = {
    horizontal: 'left',
    vertical: 'bottom',
};

export interface CustomSnackbarProps extends SnackbarProps {
    variant: 'error' | 'info' | 'success' | 'warning';
    onClose?: (event: Event | SyntheticEvent<HTMLButtonElement>) => void;
}

const CustomSnackbar: React.FunctionComponent<CustomSnackbarProps> = (props) => {
    const classes = useStyles();
    const { children, className, onClose, variant, ...rest } = props;
    const Icon = variantIcon[variant];

    return (
        <Snackbar anchorOrigin={SNACKBAR_ORIGIN} onClose={onClose} {...rest}>
            <SnackbarContent
                className={classNames(classes[variant], className)}
                message={(
                    <span className={classes.message}>
                        <Icon className={classNames(classes.icon, classes.variantIcon)} />
                        {children}
                    </span>
                )}
                action={(
                    <div className={classes.close}>
                        <IconButton color="inherit" onClick={onClose}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    </div>
                )}
            />
        </Snackbar>
    );
};

export default CustomSnackbar;
