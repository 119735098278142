import Checkbox from '@mui/material/Checkbox';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Notification, NotificationType } from '../../../../common/interfaces';
import { isNotificationClosed } from '../../utils';
import NotificationItem from './NotificationItem';
import NotificationTypeLabel from './NotificationTypeLabel';
import { notificationContainsText } from './utils';

const NOTIFICATION_TYPES: NotificationType[] = [
    'PATH_DEVIATION',
    'DRIVER_MESSAGE',
    'MULTIPLE_VEHICLES_ON_BLOCK',
    'MESSAGE_ACKNOWLEDGED',
    'OVERLAPPING_ACTIVITIES',
    'BLOCK_VERSION_MISMATCH',
    'MISSING_VEHICLE',
    'DELAYED_TRIP_DEPARTURE',
    'TRIP_NOT_STARTED',
];

const PERIOD_MINUTES_FILTER_VALUES: number[] = [5, 15, 30, 60, 120];

interface Props {
    notifications: Notification[];
}

interface State {
    closedFilter: boolean;
    typeFilter: NotificationType[];
    modifiedInFilter: number | 'TODAY';
    textFilter: string;
}

class NotificationsList extends React.PureComponent<Props, State> {
    state: State = {
        closedFilter: false,
        typeFilter: [],
        modifiedInFilter: 'TODAY',
        textFilter: '',
    };

    private readonly onTabChange = (event: React.ChangeEvent<{}>, value: 'false' | 'true') => {
        this.setState({ closedFilter: value === 'true' });
    };

    private readonly onTypeFilterChange = (event: SelectChangeEvent<string[]>) => {
        this.setState({ typeFilter: event.target.value as string[] });
    };

    private readonly onPeriodMinutesChange = (event: SelectChangeEvent<number | 'TODAY'>) => {
        this.setState({ modifiedInFilter: event.target.value as number | 'TODAY' });
    };

    private readonly onTextFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ textFilter: event.target.value });
    };

    render() {
        const { notifications } = this.props;
        const { closedFilter, typeFilter, modifiedInFilter, textFilter } = this.state;

        const filteredNotifications = notifications.filter((notification) => {
            if (isNotificationClosed(notification) !== closedFilter) {
                return false;
            }
            if (modifiedInFilter !== 'TODAY') {
                const maxModifiedTime = Date.now() - modifiedInFilter * 60 * 1000;
                if (notification.modified !== null && Date.parse(notification.modified) < maxModifiedTime) {
                    return false;
                }
            }
            if (textFilter !== '' && !notificationContainsText(notification, textFilter)) {
                return false;
            }
            if (typeFilter.length !== 0 && !typeFilter.includes(notification.type)) {
                return false;
            }
            return true;
        });

        const notificationsToShow = _.orderBy(
            filteredNotifications,
            [n => n.acknowledged === null, n => new Date(n.created)],
            ['desc', 'desc'],
        );

        return (
            <>
                <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    value={closedFilter.toString()}
                    onChange={this.onTabChange}
                >
                    <Tab value="false" label={<FormattedMessage id="notifications.category.active" />} />
                    <Tab value="true" label={<FormattedMessage id="notifications.category.closed" />} />
                </Tabs>
                <FormControl variant="filled">
                    <InputLabel>
                        <FormattedMessage id="notifications.type" />
                    </InputLabel>
                    <Select
                        value={typeFilter}
                        multiple
                        onChange={this.onTypeFilterChange}
                        input={<FilledInput />}
                        renderValue={renderNotificationTypes}
                    >
                        {NOTIFICATION_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                                <Checkbox color="primary" checked={typeFilter.includes(type)} />
                                <NotificationTypeLabel type={type} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <InputLabel>
                        <FormattedMessage id="notifications.modified" />
                    </InputLabel>
                    <Select value={modifiedInFilter} onChange={this.onPeriodMinutesChange} input={<FilledInput />}>
                        {PERIOD_MINUTES_FILTER_VALUES.map(value => (
                            <MenuItem key={value} value={value}>
                                <FormattedMessage id="notifications.in-minutes" values={{ minutes: value }} />
                            </MenuItem>
                        ))}
                        <MenuItem value="TODAY">
                            <FormattedMessage id="notifications.today" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <InputLabel>
                        <FormattedMessage id="notifications.text" />
                    </InputLabel>
                    <FilledInput value={textFilter} onChange={this.onTextFilterChange} />
                </FormControl>

                {notificationsToShow.map(notification => (
                    <NotificationItem key={notification.id} notification={notification} />
                ))}
            </>
        );
    }
}

function renderNotificationTypes(types: NotificationType[]) {
    return types.map((type, i) => (
        <React.Fragment key={type}>
            {i > 0 && ', '}
            <NotificationTypeLabel type={type} />
        </React.Fragment>
    ));
}

export default NotificationsList;
