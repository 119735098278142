import type { DateType } from '@date-io/type';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { BlockActivity } from '../../../../common/interfaces';
import ContainedDialogActions from '../../components/ContainedDialogActions';
import DateTimePicker from '../../components/DateTimePicker';
import { CHANGE_BLOCK_ACTIVITY_TIME } from '../../graphql/rest';
import { getBeginTime, getEndTime, minMaxMomentAroundServiceDate } from '../../utils';
import SaveButton from './SaveButton';

const useStyles = makeStyles({
    datePicker: {
        margin: 5,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
});

interface Props {
    blockActivity: BlockActivity;
    activityModificationCompleted: () => void;
}

const BlockActivityTimeModificationDialog: React.FunctionComponent<Props> = ({ activityModificationCompleted, blockActivity }) => {
    const intl = useIntl();
    const classes = useStyles();

    const { activityKey, serviceDate } = blockActivity;
    const beginTime = getBeginTime(blockActivity);
    const endTime = getEndTime(blockActivity);

    const [newBeginTime, setNewBeginTime] = React.useState<DateType | null>(moment(beginTime));
    const [newEndTime, setNewEndTime] = React.useState<DateType | null>(moment(endTime));

    const blockActivityLength = newEndTime && newBeginTime ? moment(newEndTime).diff(newBeginTime, 'minutes') : 0;

    const hookOptions = {
        variables: {
            blockActivityKey: activityKey,
            input: {
                type: 'time-change',
                beginTime: newBeginTime ? newBeginTime.toISOString() : null,
                endTime: newEndTime ? newEndTime.toISOString() : null,
            },
        },
    };

    const minMaxMoment = minMaxMomentAroundServiceDate(serviceDate);

    return (
        <>
            <DialogContent className={classes.flexRow}>
                <div className={classes.flexColumn}>
                    <DateTimePicker
                        disabled
                        className={classes.datePicker}
                        value={moment(beginTime)}
                        label={<FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification.begin-time" />}
                    />
                    <DateTimePicker
                        className={classes.datePicker}
                        onChange={setNewBeginTime}
                        value={newBeginTime}
                        {...minMaxMoment}
                        label={<FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification.new-begin-time" />}
                    />
                    <TextField
                        disabled
                        className={classes.datePicker}
                        label={<FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification.length-label" />}
                        value={intl.formatMessage(
                            {
                                id: 'blocks-overview.block-activity-actions.block-activity-modification.length',
                            },
                            { length: blockActivityLength },
                        )}
                    />
                </div>
                <div className={classes.flexColumn}>
                    <DateTimePicker
                        value={moment(endTime)}
                        disabled
                        className={classes.datePicker}
                        label={<FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification.end-time" />}
                    />
                    <DateTimePicker
                        className={classes.datePicker}
                        onChange={setNewEndTime}
                        value={newEndTime}
                        {...minMaxMoment}
                        label={<FormattedMessage id="blocks-overview.block-activity-actions.block-activity-modification.new-end-time" />}
                    />
                </div>
            </DialogContent>
            <ContainedDialogActions>
                <SaveButton
                    blockActivity={blockActivity}
                    activityModificationCompleted={activityModificationCompleted}
                    graphQlQuery={CHANGE_BLOCK_ACTIVITY_TIME}
                    mutationOptions={hookOptions}
                />
            </ContainedDialogActions>
        </>
    );
};
export default BlockActivityTimeModificationDialog;
